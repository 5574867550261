import { useLocation } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import { api } from 'utils/API';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Iframe from 'react-iframe';
import base64 from 'base-64';

export default function Authenticity() {

    const location = useLocation();
    const [IsValid, setIsValid] = useState(false);

    const params = location.search;
    const memberIdParam = new URLSearchParams(params).get('token');

    // this is using in updating records 
    const handleRefreshProfile = useCallback(async(member_id) => {
        const endpoint = '/member.php';
        const payload = { action:'get_details', id:member_id };
        return await api.post(endpoint,payload).then(e=>e.data);
    },[api]);
  
    useEffect(()=>{
        if(memberIdParam){
           let memberIdPDecoded = base64.decode(memberIdParam);
            handleRefreshProfile(memberIdPDecoded).then(res=>{
                if(res?.status === 200){
                    setIsValid(true);
                } else {
                    setIsValid(false);
                }
            })
        } 
    },[handleRefreshProfile, memberIdParam]);
    
    return (
        <Box>
            {IsValid ?
                <Iframe 
                    allowFullScreen
                    position="absolute"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    src={`https://api.paaph.org/paa/webview/prc_authenticity.php?token=${memberIdParam}`}
                /> :
                <Grid item xs={12}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        height="60vh"
                    >
                        <Box my={5}>
                            <CheckCircleOutlineIcon 
                                sx={{
                                    height:'100px',
                                    width:'100px',
                                    color:'#209D50'
                                }}
                            />
                        </Box>
                        <Box color="#209D50" fontSize={30}>
                            Successfully Login as PRC.
                        </Box>
                        <Box component="p" color="#7E7E7E" fontSize={15}>
                            Please rescan the QR code from the document, to see the seal of authenticity
                        </Box>
                    </Box> 
                </Grid>
            }
        </Box>
    );
}