import { useEffect, useState, useCallback } from 'react';
import { usePostRequest } from 'helper/post.api';
import DropdownSelect from 'components/Form/Fields/Dropdown/Select';

export default function CustomDropdown({ required, ...otherProps }) { 
    
    const { loading, data, setPOST } = usePostRequest('/member.php');
    const [options, setOptions] = useState([]);

    const configDropdownSelect = {
        ...otherProps, options, required, loading,
        name:"email",
        readOnly:true,
        label:'Email address of PRC branch where you will renew your license'
    }

    // call request when the dependency triggered
    const handlePostRequest = useCallback(()=>{
        setPOST({
            action:"get_prc",
        });
    },[]);
    
    // this will trigger handlePostRequest 
    useEffect(()=>{
        handlePostRequest()
    },[handlePostRequest])

    // set the key value paire format 
    useEffect(()=>{
        const { data:emails } = data || [];
        setOptions(emails?.map(items=>({
            text: `${items.location} - ${items.email}`,
            value: items.email
        })));
    },[data]);

    if(data.hasOwnProperty('data')) {
        configDropdownSelect.readOnly = false;
    }

    if(otherProps?.readOnly){
        configDropdownSelect.readOnly = true;
    }

    return <DropdownSelect {...configDropdownSelect}/>
}