import { useLocation } from "react-router-dom";
import isBase64 from 'is-base64';
import base64 from 'base-64';

export const useParamBase64 = () => {
    
    const params = useLocation().search;

    if(params.includes('?param=')){
        const base64ID = new URLSearchParams(params).get('param')
        if(isBase64(base64ID)){
            return base64.decode(base64ID);
        }
    }

    else if(params.includes('?token=')){
        const base64ID = new URLSearchParams(params).get('token')
        if(isBase64(base64ID)){
            return base64.decode(base64ID);
        }
    } 

    return;
}