import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function FieldLabel (props) {
    const {
        text, required, fontSize=14, color='#7E7E7E'
    } = props;

    return (
        <Typography 
            textAlign="left"
            component={'div'}
            style={{
                color,
                fontSize,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden"
            }}
        >
            {text}{required && <Box 
                mx={1}
                component="span"
                color="#d32f2f"
            >*</Box>}
        </Typography>
    );
}