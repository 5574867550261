import { useEffect, useState, memo, useCallback } from 'react';
import { ValidationSchema } from './utils/validation';
import { useAuth } from 'hooks/useAuth';
import { api } from 'utils/API';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import BorderColorIcon from '@mui/icons-material/BorderColor';

import AgricuristCheckbox from 'components/Form/AgricuristCheckBox';
import ImageUploader from 'components/Form/FileUploader/Image';
import CSVUploader from 'components/Form/FileUploader/CSV';
import FormikForm from 'components/Form/Formik';
import TextField from 'components/Form/Fields/TextField';
import FieldLabel from 'components/Form/Fields/FieldLabel';
import DatePicker from 'components/Form/Fields/DatePicker';
import Button from 'components/Form/Button';
import PrcEmail from 'components/Form/PrcEmail';

export default memo(function PersonalInformationForm() {

    const { auth, handleRefreshProfile } = useAuth();
    const [isEdit, setEdit] = useState(false);
    const [nonPro, setNonPro] = useState(false);
    const [form, setForm] = useState({
        action:'update_prc',
        check:2,
        photo:'',
        prcno:'',
        datereg:null,
        dateexp:null,
        cv:'',
        email:''
    });
    
    const handleCheckIfHasPRC = has_prc => {
        return parseInt(has_prc) === 1
        ? 1 : parseInt(has_prc) === 0
        ? 0 : 2
    }

    const handleCheckIfPro = has_prc => {
        return parseInt(has_prc) === 1 
        ? false : parseInt(has_prc) === 0 
        ? true : false
    }
    
    const setFormValues = useCallback((result) => {
        setForm(form=>({ 
            ...form, 
            id: result?.id,
            prcno: result?.prc_id,
            email:result?.prc_email,
            datereg:result?.prc_reg,
            dateexp:result?.prc_exp,
            photo:result?.prc_photo,
            check: handleCheckIfHasPRC(result?.has_prc)
        }));

        setNonPro(handleCheckIfPro(result?.has_prc));
    },[setForm, form]);

    useEffect(()=>setFormValues(auth),[auth]);

    const handleUpdate = async (values) => {
        const endpoint = '/profile_menu.php';
        const response = await api.post(endpoint, values).then(e=>e.data);
       
        if(response?.status === 200) {
            await setEdit(false);
            await handleRefreshProfile();
            await setFormValues(auth);
        } 
    }

    return (
        <FormikForm 
            initialValues={{...form}}
            enableReinitialize={true}
            validationSchema={ValidationSchema}
            onSubmit={values=>{
                handleUpdate(values)
            }}
        >
            <Grid container direction="row" justify="center" align="center" spacing={2}>
                {!isEdit && <Grid item xs={12} align="end">
                   <Box
                        component="span" 
                        textAlign="center" 
                        color="#209D50"
                        sx={{ cursor:'pointer' }} 
                        onClick={e=>{
                            setEdit(true);
                        }}
                    >
                        Update
                        <BorderColorIcon sx={{fontSize:18}}/>
                   </Box>
                </Grid>}
                <Grid item container xs={12}>
                    <Grid item>
                        <AgricuristCheckbox
                            disabled={!isEdit}
                            handleChange={e=>setNonPro(
                                e === 1 ? false : true
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box my={1}>
                            <FieldLabel text="Upload a copy of your PRC ID"/>
                        </Box>
                        <ImageUploader required disabled={!isEdit || nonPro} name="photo" type="PRC"/>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        required
                        name="prcno"
                        disabled={nonPro}
                        readOnly={!isEdit}
                        label="PRC Registration Number"
                        placeholder="Input PRC Number"
                    />
                </Grid>
                <Grid item xs={4}>
                    <DatePicker
                        required
                        disableFuture
                        disabled={nonPro}
                        readOnly={!isEdit}
                        label="Date of License Registration"
                        name="datereg"
                    />
                </Grid>
                <Grid item xs={4}>
                    <DatePicker
                        required
                        // disablePast
                        disabled={nonPro}
                        readOnly={!isEdit}
                        label="Date of License Expiration"
                        name="dateexp"
                    />
                </Grid>
                <Grid item xs={8}>
                    <PrcEmail readOnly={!isEdit} disabled={nonPro}/>
                </Grid>
                <Grid item xs={12}>
                    <Box my={1}>
                        <FieldLabel text="Upload a curriculum vitae "/>
                    </Box>
                    <Box
                        fontSize={14}
                        color="#209D50"
                        textAlign="justify"
                    >
                        By uploading your curriculum vitae, 
                        you will be informed of possible job opportunities, 
                        and invited to awards nominations, special seminars, 
                        workshops/discussion groups and the like.
                    </Box>
                    <Box my={1}>
                        <CSVUploader required disabled={!isEdit} name="cv" type="PRC"/>
                    </Box>
                </Grid>
                {isEdit && <Grid item xs={12} align="end">
                    <Box position="relative" top={50}>
                        <Button 
                            width={175}
                            variant="contained" 
                        >
                            Save
                        </Button>
                    </Box>
                </Grid>}
            </Grid>
        </FormikForm>
    );
});