import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useParamBase64 } from 'hooks/useParamBase64';
import { usePostRequest } from 'helper/post.api';

import { Divider } from '@mui/material';
import { IconButton } from '@mui/material';
import { useMediaQuery } from '@mui/material';

import Base64 from 'base-64';
import Box from '@mui/material/Box';
import Button from 'components/Form/Button';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import CardStepLayout from "components/CardStepLayout";

export default function PaymentForm(props) {

    // Payment Type:
    // 0 - Registration
    // 1 - Renewal
    const { type = 0 } = props;

    const MEMBER_SHIP_FEE = type == 0 ? 500 : 0;
    const IS_REGISTRATION = type == 0 ? true : false;

    const SUBHEADER = type == 0 
    ? "Step 5 of 5" 
    : "Step 6 of 6";

    const PROGRESS_SAVED_ROUTE = type == 0
    ? '/registration/progress/save'
    : '/membership/renewal/progress/save';

    const SHIPPING_FEE = 0;
    const ANNUAL_DUE = 1500;
    const amount = MEMBER_SHIP_FEE + ANNUAL_DUE + SHIPPING_FEE;

    const mobile = useMediaQuery('(max-width:800px)');
    const cardInvoice = useMediaQuery('(max-width:1200px)');

    const navigate = useNavigate();
    const paraId = useParamBase64();

    const { data, setPOST } = usePostRequest('/payment.php');
    const [form] = useState({
        action:"get_fees",
        transaction:"New Membership",
    });
    
    // this submit payload with POST request from hooks
    // this will get the fees details 
    useEffect(()=>{ setPOST(form); },[form]);

    const formatNumber = (amount) => {
        return amount?.toLocaleString("en-PH", { 
            style:"currency", 
            currency:"PHP" 
        });
    }
   
    const paymentGateWay = () => {
        const URL = process.env.REACT_APP_PAYMENT_URL;
        const getParams = {
            amount,
            param:paraId,
            transaction:'New Membership',
            trx:type, // Payment Type
            shipping:SHIPPING_FEE
        };

        const param = Base64.encode(getParams?.param);
        const totalAmount = Base64.encode(getParams.amount);
        const transaction = Base64.encode(getParams?.transaction);
        const trx = Base64.encode(getParams?.trx); // trx = 0 as registration
        const shipping = Base64.encode(getParams?.shipping);

        window.open(
            `${URL}?param=${param}&amount=${totalAmount}&transaction=${transaction}&trx=${trx}&shipping=${shipping}`,
            "Popup",
            "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30"
        )
    }

    return (
        <Box 
            component="container"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            backgroundColor="#F8F8F8"
            minHeight="100vh"
        >   
            <IconButton
                onClick={e=>navigate(-1)}
                sx={{
                    position: 'absolute',
                    left: 100,
                    top: 40
                }}
            >
                <ArrowCircleLeftIcon sx={{ color:'primary.main' }}/>
            </IconButton>
            <Box 
                component="main"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent={mobile ? '' : 'space-evenly'}
                width={mobile ? "100%" : "70%"}
                paddingTop={mobile ? 0 : 5}
            >   
                <CardStepLayout
                    subheader={SUBHEADER}
                    header="Membership Dues"
                    subtitle={
                        <Box component="span" ma={2}>
                            To securely settle your PAA membership dues online,
                            please kindly click the “Pay Now” button below.
                            <span style={{ color:"#209D50" }}>
                                &nbsp;
                                You will receive an email containing your official 
                                receipt for the successful dues settlement.
                            </span>
                            <Box my={3} color="#757575">Pay now?</Box>
                        </Box>
                    }
                >
                    <Box
                        gap={4}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        backgroundColor="#F7FFF5"
                        padding={mobile ? 4 : 10}
                        style={cardInvoice ? {} : { width:500 }}
                    >
                        <Box id="title">MEMBERSHIP INVOICE</Box>
                        {IS_REGISTRATION &&
                        <Box 
                            display="flex" 
                            justifyContent="space-between" 
                            flexWrap="wrap"
                            gap={4}
                        >
                            <Box>Membership Fee</Box>
                            <Box>{formatNumber(parseInt(data?.membership))}</Box>
                        </Box>}
                        <Box
                            gap={4}
                            display="flex" 
                            flexWrap="wrap"
                            justifyContent="space-between" 
                        >
                            <Box>3-year Annual Dues</Box>
                            <Box>{formatNumber(parseInt(data?.annual))}</Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Divider color="black"/>
                        </Box>
                        <Box 
                            color="red"
                            display="flex"
                            justifyContent="space-between"
                            borderBottom="1px solid black"
                        />
                        <Box 
                            display="flex"
                            flexWrap="wrap"
                            justifyContent="space-between"
                            gap={2}
                        >
                            <Box 
                                textAlign="justify" 
                                width={cardInvoice ? "100%":"50%"}
                            >
                                <Box>
                                    Amount Due<br/>
                                </Box>
                                <Box color="red" fontSize={14}>
                                    *Convenience fee not yet included
                                </Box>
                                <Box color="red" fontSize={14}>
                                    **Convenience fee = Maintenance fee + Merchant Discount Rate (MDR) + Technology fee
                                </Box>
                            </Box>
                            <Box fontSize={25}>
                                {formatNumber(amount)}
                            </Box>
                        </Box>
                    </Box>
                </CardStepLayout>
                <Box 
                    width="100%"
                    display= "flex"
                    alignItems= "center"
                    justifyContent="space-around"
                    margin="25px 25px"
                    id="btn-contianer"
                >
                    <Button 
                        variant="contained"
                        width={mobile ? 165 : 165}
                        bgColor={"#707070"}
                        onClick={() => navigate(PROGRESS_SAVED_ROUTE)}
                    >
                        Maybe Later
                    </Button>
                    <Button
                        variant="contained" 
                        width={mobile ? 165 : 165}
                        onClick={() => paymentGateWay()}
                    >
                        Pay Now
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}