import { useState, useEffect, Fragment } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function ResponsiveDialog({ openDialog, handleDialogClose }) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = useState(openDialog);
    const handleClose = () => {
        setOpen(false);
        if(handleDialogClose instanceof Function){
            handleDialogClose();
        }
    };

    useEffect(()=>{
        setOpen(openDialog);
    },[openDialog]);

    return (
        <Fragment>
            <Dialog
                open={open}
                maxWidth="md"
                fullScreen={fullScreen}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                   Data Privacy Agreement
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    <Box mb={3}>
                        Updated at 2022-01-26
                    </Box>
                    <Box mb={3}>
                        The Philippine Association of Agriculturists, Inc.. is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by Philippine Association of Agriculturists, Inc..
                        This Privacy Policy applies to our website, the www.paaph.org,  and its associated subdomains (collectively, our "Service"). By accessing or using our Service, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy and our Terms of Service
                    </Box>
                    <Box mb={3} component="h3">
                        Definitions and key terms
                    </Box>
                    <Box mb={3}>
                        For this Privacy Policy:
                    </Box>
                    <Box component="ul">
                        <Box component="li">
                            <Box component="strong">
                                Cookie:&nbsp;
                            </Box>
                            small amount of data generated by a website and saved by your web browser.
                            It is used to identify your browser, provide analytics,
                            remember information about you such as your language preference or login information.
                        </Box>
                        <Box component="li">
                            <Box component="strong">
                                Organization:&nbsp;
                            </Box>
                            when this policy mentions "Organization," it refers to Philippine Association of Agriculturists, Inc.,
                            with office at 3rd Floor, DOST-PCMRRD Innovation and Technology Center, Jamboree Road,
                            Brgy. Timugan, Los Baños, Laguna that is responsible for your information under this Privacy Policy.
                        </Box>
                        <Box component="li">
                            <Box component="strong">
                                Country:&nbsp;
                            </Box>
                            where Philippine Association of Agriculturists,
                            Inc. or the owners/founders of Philippine Association of Agriculturists,
                            Inc. are based, in this case in the Philippines.
                        </Box>
                        <Box component="li">
                            <Box component="strong">
                                Member:&nbsp;
                            </Box>
                            refers to the organization or person that signs up with the Philippine Association of Agriculturists, Inc. 
                        </Box>
                        <Box component="li">
                            <Box component="strong">
                                Device:&nbsp;
                            </Box>
                            any internet connected device such as a phone, tablet,
                            computer or any other device that can be used to visit Philippine Association of Agriculturists, Inc.
                            website and use its services.
                        </Box>
                        <Box component="li">
                            <Box component="strong">
                                IP Address:&nbsp;
                            </Box>
                            Every device connected to the Internet is assigned a number known as an Internet protocol (IP) address.
                            These numbers are usually assigned in geographic blocks.
                            An IP address can often be used to identify the location from which a device is connecting to the Internet.
                        </Box>
                        <Box component="li">
                            <Box component="strong">
                                Personnel:&nbsp;
                            </Box>
                            refers to those individuals who are employed by Philippine Association of Agriculturists, Inc.
                            or are under contract to perform a service on behalf of one of the parties.
                        </Box>
                        <Box component="li">
                            <Box component="strong">
                                Personal Data:&nbsp;
                            </Box>
                            any information that directly, indirectly, or in connection with other information
                            - including a personal identification number
                            - allows for the identification 
                        </Box>
                        <Box component="li">
                            <Box component="strong">
                                Service:&nbsp;
                            </Box>
                            refers to the service provided by Philippine Association of Agriculturists, Inc. on this platform.
                        </Box>
                        <Box component="li">
                            <Box component="strong">
                                Website:&nbsp;
                            </Box>
                            The Philippine Association of Agriculturists, Inc.'s site, which can be accessed via this URL: www.paaph.org.
                        </Box>
                        <Box component="li">
                            <Box component="strong">
                                You:&nbsp;
                            </Box>
                            a person or entity that is registered with Philippine Association of Agriculturists, Inc. to use its Services.
                        </Box>
                    </Box>
                    <Box mb={3} component="h3">
                        What Information Do We Collect?
                    </Box>
                    <Box component="p">
                        We collect information from you when you visit our service, register, place an order, subscribe to our newsletter, respond to a survey or fill out a form.
                        These may include the Name I Username, Phone Numbers, Email Addresses, Mailing Addresses, Job Titles, Debit/credit card numbers, date of birth,
                        passwords and other information that are deemed important for PAA to be able to efficiently deliver its services to its members.
                    </Box>
                    <Box component="p">
                        We also collect information from mobile devices for a better user experience, although these features are completely optional:
                    </Box>
                    <Box mb={3} component="h3">
                        How Do We Use The Information We Collect?
                    </Box>
                    <Box component="p">
                        Any information that we collect from you may be used in one of the following ways:
                    </Box>
                    <Box component="ul">
                        <Box component="li">
                            To personalize your experience (your information helps us to better respond to your individual needs)
                        </Box>
                        <Box component="li">
                            To improve our service (we continually strive to improve our service offerings based on the information and feedback we receive from you)
                        </Box>
                        <Box component="li">
                            To improve service to our members (your information helps us to more effectively respond to your member service requests and  support needs)
                        </Box>
                        <Box component="li">
                            To process transactions
                        </Box>
                        <Box component="li">
                            To administer a contest, promotion, survey or other site features
                        </Box>
                        <Box component="li">
                            To send periodic emails
                        </Box>
                    </Box>
                    <Box mb={3} component="h3">
                        When do we use customer information from third parties?
                    </Box>
                    <Box mb={3} component="p">
                        We receive some information from the third parties when you contact us.
                        For example, when you submit your email address to us to show interest in becoming our member, we receive information from a third party that provides automated fraud detection services to   us. We also occasionally collect information that is made publicly available on social media websites.
                        You can control how much of your information social media websites make public by visiting these websites and changing your privacy settings.
                    </Box>
                    <Box mb={3} component="h3">
                        Do we share the information we collect with third parties?
                    </Box>
                    <Box mb={2} component="p">
                        We may share both selected personal and non-personal information with our PAA regional chapters where the member is affiliated and with prior consent from the member with third parties. 
                    </Box>
                    <Box mb={2} component="p">
                        We may engage trusted third-party service providers to perform functions and provide services to us,
                        such as hosting and maintaining our servers and our service, database storage and management, e-mail management, storage marketing,
                        credit card processing, customer service and fulfilling orders for products and services you may purchase through our platform.
                        We will likely share your personal information, and possibly some non-personal information, with these third parties to enable them to perform these services for us and for you.
                    </Box>
                    <Box mb={2} component="p">
                        We may share portions of our log file data, including IP addresses,
                        for analytics purposes with third parties such as web analytics partners, application developers, and ad networks.
                        If your IP address is shared, it may be used to estimate general location and other technographics such as connection speed,
                        whether you have visited the service in a shared location, and type of device used to visit the service. They may aggregate information about our advertising and what you see on the service and then provide auditing, research and reporting for us and our advertisers.
                    </Box>
                    <Box mb={2} component="p">
                        We may also disclose personal and non-personal information about you to government or law enforcement officials or private parties as we,
                        in our sole discretion, believe necessary or appropriate in order to respond to claims, legal process (including subpoenas),
                        to protect our rights and interests or those of a third party, the safety of the public or any person, to prevent or stop any illegal,
                        unethical, or legally actionable activity, or to otherwise comply with applicable court orders, laws, rules and regulations.
                    </Box>
                    <Box mb={2} component="h3">
                        Where and when is information collected from customers and end users?
                    </Box>
                    <Box mb={2} component="p">
                        We will collect personal information that you submit to us.
                        We may also receive personal information about you from third parties as described above.
                    </Box>
                    <Box mb={2} component="h3">
                        How Do We Use Your Email Address?
                    </Box>
                    <Box mb={2} component="p">
                        By submitting your email address on this website, you agree to receive emails from us. 
                        You can cancel your participation in any of these email lists at any time by clicking on the opt-out link or other unsubscribe option that is included in the respective email. We only send emails to people who have authorized us to contact them,
                        either directly, or through a third party. We do not send unsolicited commercial emails.
                        because we hate spam as much as you do.
                    </Box>
                    <Box mb={2} component="h3">
                        Could my information be transferred to other countries?
                    </Box>
                    <Box mb={2} component="p">
                        We are incorporated in the Philippines. Information collected via our website,
                        through direct interactions with you, or from use of our help services may be transferred from time to time to our offices or personnel,
                        or to third parties, located throughout the world, and may be viewed and hosted anywhere in the world,
                        including countries that may not have laws of general applicability regulating the use and transfer of such data.
                        To the fullest extent allowed by applicable law,
                        by using any of the above, you voluntarily consent to the trans­ border transfer and hosting of such information.
                    </Box>
                    <Box mb={2} component="h3">
                        Is the information collected through our service secure?
                    </Box>
                    <Box mb={2} component="p">
                        We take precautions to protect the security of your information.
                        We have physical, electronic, and managerial procedures to help safeguard,
                        prevent unauthorized access, maintain data security, and correctly use your information.
                        However, neither people nor security systems are foolproof, including encryption systems.
                        In addition, people can commit intentional crimes, make mistakes or fail to follow policies.
                        Therefore, while we use reasonable efforts to protect your personal information, we cannot guarantee its absolute security.
                        If applicable law imposes any non-disclaimable duty to protect your personal information,
                        you agree that intentional misconduct will be the standards used to measure our compliance with that duty.
                    </Box>
                    <Box mb={2} component="h3">
                        Can I update or correct my information?
                    </Box>
                    <Box mb={2} component="p">
                        The rights you have to request updates or corrections to the information we collect depend on your relationship with us.
                        Personnel may update or correct their information as detailed in our internal organization employment policies.
                        Members have the right to request the restriction of certain uses and disclosures of personally identifiable information as follows.
                        You can contact us in order to (1) update or correct your personally identifiable information,
                        (2) change your preferences with respect to communications and other information you receive from us,
                        or (3) delete the personally identifiable information maintained about you on our systems (subject to the following paragraph),
                        by cancelling your account. Such updates, corrections, changes and deletions will have no effect on other information that we maintain,
                        or information that we have provided to third parties in accordance with this Privacy Policy prior to such update, correction, change or deletion.
                        To protect your privacy and security, we may take reasonable steps (such as requesting a unique password) to verify your identity before granting you profile access or making corrections.
                        You are responsible for maintaining the secrecy of your unique password and account information at all times.
                        You should be aware that it is not technologically possible to remove each and every record of the information you have provided to us from our system.
                        The need to back up our systems to protect information from inadvertent loss means that a copy of your information may exist in a non-erasable form that will be difficult or impossible for us to locate.
                        Promptly after receiving your request, all personal information stored in databases we actively use, and other readily searchable media will be updated, corrected, changed or deleted, as appropriate, as soon as and to the extent reasonably and technically practicable.
                        If you are an end user and wish to update, delete, or receive any information we have about you, you may do so by contacting the organization of which you are a customer.
                    </Box>
                    <Box mb={2} component="h3">
                        Personnel
                    </Box>
                    <Box mb={2} component="p">
                        If you are one of our workers or applicants, we collect information you voluntarily provide to us.
                        We use the information collected for Human Resources purposes in order to administer benefits to workers and screen applicants.
                        You may contact us in order to (1) update or correct your information,
                        (2) change your preferences with respect to communications and other information you receive from us,
                        or (3) receive a record of the information we have relating to you.
                        Such updates, corrections, changes and deletions will have no effect on other information that we maintain,
                        or information that we have provided to third parties in accordance with this Privacy Policy prior to such update, correction, change or deletion.
                    </Box>
                    <Box mb={2} component="h3">
                        How Long Do We Keep Your Information?
                    </Box>
                    <Box mb={2} component="p">
                        We keep your information only so long as we need it to provide service to you and fulfill the purposes described in this policy.
                        This is also the case for anyone that we share your information with and who carries out services on our behalf.
                        When we no longer need to use your information and there is no need for us to keep it to comply with our legal or regulatory obligations,
                        we'll either remove it from our systems or depersonalize it so that we can't identify you.
                    </Box>
                    <Box mb={2} component="h3">
                        How Do We Protect Your Information?
                    </Box>
                    <Box mb={2} component="p">
                        We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information.
                        We offer the use of a secure server.
                        All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our 
                        Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential.
                        After a transaction, your private information (credit cards, social security numbers, financials, etc.) is never kept on file.
                        We cannot, however, ensure or warrant the absolute security of any information you transmit to us or guarantee that your information on the 
                        Service may not be accessed, disclosed, altered, or destroyed by a breach of any of our physical, technical, or managerial safeguards.
                    </Box>
                    <Box mb={2} component="h3">
                        Governing Law
                    </Box>
                    <Box mb={2} component="p">
                        The laws of Philippines, excluding its conflicts of law rules, shall govern this Agreement and your use of our service.
                        Your use of our service may also be subject to other local, state, national, or international laws.
                    </Box>
                    <Box mb={2} component="h3">
                        Your Consent
                    </Box>
                    <Box mb={2} component="p">
                        By using our service, registering an account, or making a purchase, you consent to this Privacy Policy.
                    </Box>
                    <Box mb={2} component="h3">
                        Links to Other Websites
                    </Box>
                    <Box mb={2} component="p">
                        This Privacy Policy applies only to the Services.
                        The Services may contain links to other websites not operated or controlled by PAA. 
                        We are not responsible for the content, accuracy or opinions expressed in such websites,
                        and such websites are not investigated,
                        monitored or checked for accuracy or completeness by us.
                        Please remember that when you use a link to go from the Services to another website,
                        our Privacy Policy is no longer in effect.
                        Your browsing and interaction on any other website,
                        including those that have a link on our platform,
                        is subject to that website's own rules and policies.
                        Such third parties may use their own cookies or other methods to collect information about you.
                    </Box>
                    <Box mb={2} component="h3">
                        Cookies
                    </Box>
                    <Box mb={2} component="p">
                        We use "Cookies" to identify the areas of our website that you have visited.
                        A Cookie is a small piece of data stored on your computer or mobile device by your web browser.
                        We use Cookies to personalize the Content that you see on our website. Most web browsers can be set to disable the use of Cookies.
                        However, if you disable Cookies, you may not be able to access functionality on our website correctly or at all.
                        We never place Personally Identifiable Information in Cookies.
                    </Box>
                    <Box mb={2} component="h3">
                        Advertising
                    </Box>
                    <Box mb={2} component="p">
                        Advertising keeps us and many of the websites and services you use free of charge.
                        We work hard to make sure that ads are safe, unobtrusive, and as relevant as possible.
                    </Box>
                    <Box mb={2} component="h3">
                        Cookies for Advertising
                    </Box>
                    <Box mb={2} component="p">
                        Cookies help to make advertising more effective.
                        Without cookies, it's really hard for an advertiser to reach its audience,
                        or to know how many ads were shown and how many clicks they received.
                    </Box>
                    <Box mb={2} component="h3">
                        Remarketing Services
                    </Box>
                    <Box mb={2} component="p">
                        We use remarketing services.
                        What Is Remarketing? In digital marketing, remarketing (or retargeting)
                        is the practice of serving ads across the internet to people who have already visited your website.
                        It allows your organization to seem like they're "following" people around the internet by serving
                        ads on the websites and platforms they use most.
                    </Box>
                    <Box mb={2} component="h3">
                        Payment Details
                    </Box>
                    <Box mb={2} component="p">
                        In respect to any credit card or other payment processing details you have provided us,
                        we commit that this confidential information will be stored in the most secure manner possible
                    </Box>
                    <Box mb={2} component="h3">
                        Changes To Our Privacy Policy
                    </Box>
                    <Box mb={2} component="p">
                        If we decide to change our privacy policy,
                        we will post those changes on this page,
                        and/or update the Privacy Policy modification date below.
                    </Box>
                    <Box mb={2} component="h3">
                        Third-Party Services
                    </Box>
                    <Box mb={2} component="p">
                        We may display, include or make available third-party content (including data, information, 
                        applications and other products services) or provide links to third-party websites or services ("Third- Party Services").
                    </Box>
                    <Box mb={2} component="p">
                        You acknowledge and agree that we shall not be responsible for any Third-Party Services,
                        including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof.
                        We do not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services.
                    </Box>
                    <Box mb={2} component="p">
                        Third-Party Services and links thereto are provided solely as a convenience to you and you access
                        and use them entirely at your own risk and subject to such third parties' terms and conditions.
                    </Box>
                    
                    <Box mb={2} component="h3">
                        Tracking Technologies
                    </Box>
                    <Box component="ul">
                        <Box component="li">
                            Google Maps APls
                        </Box>
                        <Box mb={2} component="p">
                            Google Maps API is a robust tool that can be used to create a custom map,
                            a searchable map, check-in functions, display live data synching with location, plan routes,
                            or create a mashup just to name a few.
                        </Box>
                        <Box mb={2} component="p">
                            Google Maps API may collect information from You and from Your Device for security purposes.
                        </Box>
                        <Box mb={2} component="p">
                            Google Maps API collects information that is held in accordance with its Privacy Policy.
                        </Box>
                        <Box mb={2} component="p">
                            Google Maps API collects information that is held in accordance with its Privacy Policy.
                        </Box>
                        <Box component="li">
                            Cookies
                        </Box>
                        <Box mb={2} component="p">
                            We use Cookies to enhance the performance and functionality of our service but are non-essential to their use. However, without these cookies, certain functionality like videos may become unavailable or you would be required to enter your login details every time you visit our service as we would not be able to remember that you had logged in previously.
                        </Box>
                    </Box>
                    <Box mb={2} component="h3">
                        What is personal data?
                    </Box>
                    <Box mb={2} component="p">
                        Any data that relates to an identifiable or identified individual.
                        GDPR covers a broad spectrum of information that could be used on its own,
                        or in combination with other pieces of information, to identify a person.
                        Personal data extends beyond a person's name or email address.
                        Some examples include financial information, political opinions, genetic data, biometric data, IP addresses,
                        physical address, sexual orientation, and ethnicity.
                        The Data Protection Principles include requirements such as:
                    </Box>
                    <Box component="ul">
                        <Box component="li">
                            Personal data collected must be processed in a fair, legal,
                            and transparent way and should only be used in a way that a person would reasonably expect.
                        </Box>
                        <Box component="li">
                            Personal data should only be collected to fulfil a specific purpose and it should only be used for that purpose.
                            Organizations must specify why they need the personal data when they collect it.
                        </Box>
                        <Box component="li">
                            Personal data should be held no longer than necessary to fulfil its purpose.
                        </Box>
                        <Box component="li">
                            People covered by the GDPR have the right to access their own personal data.
                            They can also request a copy of their data, and that their data be updated,
                            deleted, restricted, or moved to another organization.
                        </Box>
                    </Box>
                    <Box mb={2} component="h3">
                        Why is GDPR important?
                    </Box>
                    <Box mb={2} component="p">
                        GDPR adds some new requirements regarding how companies should protect individuals'
                        personal data that they collect and process.
                        It also raises the stakes for compliance by increasing enforcement and imposing greater fines for breach.
                        Beyond these facts it's simply the right thing to do.
                        At Help Scout we strongly believe that your data privacy is very important and we already have solid security and privacy practices in place that go beyond the requirements of this new regulation.
                    </Box>
                    <Box mb={2} component="h3">
                        Individual Data Subject's Rights - Data Access, Portability and Deletion
                    </Box>
                    <Box mb={2} component="p">
                        We are committed to helping our customers meet the data subject rights requirements of GDPR.
                        We process or store all personal data in fully vetted, DPA compliant vendors.
                        We do store all conversation and personal data for up to 6 years unless your account is deleted.
                        In which case, we dispose of all data in accordance with our Terms of Service and Privacy Policy,
                        but we will not hold it longer than 60 days.
                    </Box>

                    <Box mb={2} component="h3">
                        Contact Us
                    </Box>
                    <Box mb={2} component="p">
                        Don't hesitate to contact us if you have any questions.
                    </Box>
                    <Box component="ul">
                        <Box component="li">
                            Via Email: support@paaph.org
                        </Box>
                        <Box component="li">
                            Via Phone Number: +63(49) 531 0802
                        </Box>
                        <Box component="li">
                            Via this Link: www.paaph.org
                        </Box>
                        <Box component="li">
                            Via this Address: 3rd Floor, DOST-PCAARRD Innovation and Technology Center, Jamboree Road,
                            Brgy. Timugan, Los Banos, Laguna
                        </Box>
                    </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}