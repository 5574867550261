import { useEffect, useState } from 'react';
import DropdownSelect from 'components/Form/Fields/Dropdown/Select';
import regions from './regions.json';

export default function CustomDropdown({ name, required, ...otherProps }){ 

    const [options, setOptions] = useState([]);

    const configDropdownSelect = {
        ...otherProps, 
        required,
        options,
        name,
        label:'Region'
    }

    useEffect(()=>{
        setOptions(regions?.map(items=>({ 
            text: items.region, 
            value: items.region
        })));
    },[regions]);
    
    return <DropdownSelect {...configDropdownSelect}/>;
}