import { useLocation } from "react-router-dom";

export const useUrlParams = keyname => {
    const params = useLocation().search;

    if(params.includes(keyname)){
        return new URLSearchParams(params).get(keyname);
    } 

    return;
}