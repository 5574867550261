import { useStyles } from './styles';
import LinearProgress from '@mui/material/LinearProgress';

const CustomLinearProgress = (props) => {

    const classes = useStyles();

    if(props?.loading){
        return (<LinearProgress className={classes.root}/>);
    }
    
    return <></>
}

export default CustomLinearProgress;
