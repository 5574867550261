import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStyles } from './utils/style';
import { useSnackBarMessage, STATUS } from "hooks/useSnackbar";
import { api } from "utils/API";

import Box from '@mui/material/Box';
import Button from 'components/Form/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormikForm from 'components/Form/Formik';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import Base64 from 'base-64';

import logo from 'assets/images/logo.png';
import PasswordField from 'components/Form/Fields/PasswordField';
import Textfield from 'components/Form/Fields/TextField';
import ValidationSchema from "./utils/validation";

const STEP_ROUTES = {
    0: '/registration/form/step-four',
    1: '/registration/form/step-four',
    2: '/registration/form/step-three',
    3: '/registration/form/step-two',
    4: '/registration/form/step-one',
}

export default function PrcLogin() {
    const navigate = useNavigate();
    const classes = useStyles();

    const { setSnackBarMessage } = useSnackBarMessage();
    const [form] = useState({
        action:"login",
        email:"",
        password:""
    });

    // Step 2 : Redirect based on the condition 
    // if reg_step is 0 then the member is paid 
    // redirect to main page, otherwise this will redirect to 
    // step form of registrations
    const handleRedirect = ({ reg_step, id, is_active, exp_date,  paid }) => {
        const encodedId = Base64.encode(id);

        if(parseInt(reg_step) === 0 && paid === true){ 
            const encodedId = Base64.encode(id);
            const token = Base64.encode(JSON.stringify({ id, encodedId, is_active, exp_date }));
            localStorage.setItem('token',token);
            window.location.reload();
        } else {
            navigate({
                pathname:STEP_ROUTES[reg_step],
                search:`?param=${encodedId}`
            });
        }
    }

    // Step 1 : Login first and get the response
    const handleLogin = async (values) => {
        const endpoint = '/account.php';
        const payload = { action:'login', ...values };
        const response = await api.post(endpoint,payload).then(e=>e.data);
      
        try {
            if(response?.status === 200)
                await handleRedirect(response);
            else if (response?.status !== 200) 
                throw response?.message;
        } catch(error){
            setSnackBarMessage(error, STATUS.ERROR);
        }
    }

    return (
        <Fragment>
            <Grid container className={classes.container}>
                <Card className={classes.card}>
                    <CardContent>
                        <FormikForm 
                            initialValues={{...form}}
                            validationSchema={ValidationSchema} 
                            enableReinitialize={true}
                            onSubmit={value=>{
                                handleLogin(value)
                            }}
                        >
                            <Grid container className={classes.cardContainer}>
                                <Grid item xs={12} align="center">
                                    <img
                                        src={logo}
                                        height="60vw"
                                        alt="company-logo"
                                        style={{
                                            paddingTop: 50,
                                            paddingBottom: 40,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h3" component="div">
                                        <Box 
                                            fontSize={35} 
                                            fontWeight={700}
                                            sx={{ color:'text.secondary' }}
                                        >
                                            Welcome back!
                                        </Box>
                                    </Typography>
                                    <Typography variant="h6" gutterBottom component="div">
                                        <Box fontSize={18} sx={{ color:'#7E7E7E', fontWeight:700 }}>
                                            Login to continue
                                        </Box>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ py:1 }}>
                                    <Textfield
                                        label="Email Address"
                                        name="email"
                                        placeholder="Ex:  juandelacruz@email.com"
                                        icon={<EmailIcon sx={{ color:'primary.main', fontSize:15 }}/>}
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{ py:1 }}>
                                    <PasswordField
                                        label="Password"
                                        name="password"
                                        placeholder="Ex: Juan2345"
                                    />
                                </Grid>
                                <Grid item container align="space-between" xs={12} sx={{ py:1 }}>
                                    <Grid item xs={12} md={6}>
                                        <Box my={1}>
                                            <Button fullWidth variant="contained">
                                                Login
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </FormikForm>
                    </CardContent>
                </Card>
            </Grid>
        </Fragment> 
    );
}