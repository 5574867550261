import { Fragment, useEffect } from "react";
import { useFormikContext } from "formik";
import { useDropzone } from "react-dropzone";
import { getBase64, getFileExtension } from './utils';
import FormHelperText from '@mui/material/FormHelperText';

import AttachmentIcon from '@mui/icons-material/Attachment';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

const ValidationMessage = ({message}) =>{
    return message ? 
    <FormHelperText  id="component-error-text">
        <Box color="#d32f2f">{message}</Box>
    </FormHelperText>
    : <></>
}

export default function ImageUploader ({ name, disabled, title = 'Upload Image', type = "AVATAR", ...otherProps }){
    
    const {
        acceptedFiles, getRootProps, getInputProps 
    } = useDropzone({ 
        accept: `
            image/jpeg,
            image/jpg,
            image/png,
            application/pdf,
            application/msword,
            application/vnd.openxmlformats-officedocument.wordprocessingml.document
        `, 
        disabled
    });

    const formik = useFormikContext();
    const configInput = { ...getInputProps() }
    const configProps = { name, ...otherProps }

    useEffect(()=>{
        if(acceptedFiles.length > 0){
            let file = acceptedFiles[0];
            const { setFieldValue, errors } = formik;

            getBase64(file).then(base64=>{
                configProps.value = base64;
                if(formik && name && !configProps?.onChange){
                    setFieldValue(name, base64);
                } else 
                
                if(configProps?.onChange){
                    configProps?.onChange({
                        base64,
                        contentType:file.type,
                        extension:getFileExtension(file.name)
                    });
                }
            });

            if(errors[name]) configProps.error = true;
        }
    },[acceptedFiles]);

    if(formik && name && !configProps?.onChange){
        const { errors, values } = formik;

        if(errors[name]) configProps.error = true;
        if(values[name]){
            // when the value is object with key value pair of base64
            // else, the value is a link of image 
            if(values[name]?.hasOwnProperty("base64")){
                configProps.value = values[name]?.base64;
            } else {
                configProps.value = values[name];
            }
        }
    }

    if(otherProps?.error) configProps.error = true;

    return (
        <Fragment>
            <input {...configInput}/>
            <Grid display="flex" justify="center">
                <Grid display="flex" flexDirection="column" justifyContent="center">
                    <Box {...getRootProps({ className: 'dropzone' })}>
                        <Button disableElevation disabled={disabled} variant="contained" sx={{ 
                            borderRadius: 0,
                            textTransform:'none',
                            fontSize:16,
                            height:35,
                            width:'auto',
                            borderColor:'red'
                        }}>
                            <Box display="flex" justifyContent="space-between" gap={1}>
                                <AttachmentIcon sx={{ transform: 'rotate(135deg)' }}/>
                                {
                                    acceptedFiles[0]?.name || 'Attach File'
                                }
                            </Box>
                        </Button>
                    </Box>
                </Grid>
            </Grid>
           { !disabled && <ValidationMessage message={formik?.errors[name]}/> }
        </Fragment>
    );
}