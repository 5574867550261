import { useEffect, useState, memo, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { useAuth } from 'hooks/useAuth';
import { api } from 'utils/API';

import Box from '@mui/material/Box';
import CardPreview from "components/CardPreview";

const useStyles = makeStyles((theme)=>({
    main:{
        width:"70%",
        overflow:"auto",
        padding:"30px"
    },
    heading:{
        position:'relative',
        color:"#5F5F5F",
        fontSize:28
    },
    newsContainer:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
    },
    newsTitle:{
        width: 500,
        marginTop:10,
        fontSize:35,
        fontWeight:600,
        textAlign:'start',
    }
}))

export default memo(function SummaryProfile(){

    const classes = useStyles();  
    const { getData } = useAuth();
    const [news, getNews] = useState([]);

    useEffect(()=>{
        const handleFetch = async () => {
            const URL = '/homepage.php';
            const { id } = getData();
            const payload = { action:'get_news', id };
            const res = await api.post(URL, payload).then(e=>e.data);
            await getNews(res?.result);
        }
        handleFetch();
    },[]);

    const DisplayCards = useMemo(() => news?.map((data, index) => (
        <Box
            my={2}
            id="news"
            key={`news-${index}`}
            className={classes.newsContainer}
        >
            <CardPreview {...data}/>
        </Box>
    )),[news]);

    return (
        <Box 
            component="main" 
            className={classes.main}
        >
            <Box component="section" className={classes.heading}>
                News and Upcoming Events
            </Box>
            <Box component="section">
                {DisplayCards}
            </Box>
        </Box>
    );
});