import { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import Grid from '@mui/material/Grid';
import Degree from 'components/Form/Degree';
import TextField from 'components/Form/Fields/TextField';
import FieldSpecialization from 'components/Form/FieldSpecialization';

import FieldOfSpecialization from 'components/Form/FieldOfSpecialization';

import EmploymentCheckbox from 'components/Form/EmploymentCheckbox';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import DatePicker from 'components/Form/Fields/DatePicker';
import Box from '@mui/material/Box';
import FieldLabel from 'components/Form/Fields/FieldLabel';

export default function EmploymentComponent({ isReadOnly=false, TYPE = 'PROFILE' }) {

    const StepTwoRenewal =  {
        tel:'',
        mobile:'',
        email:'',
        fax:'',
        address:'',
        grad:'',
    }

    const Profile = {
        ofctelno:'',
        ofcmobile:'',
        ofcemail:'',
        ofcfax:'',
        ofcaddress:'',
        graduate:'',
    }

    const addObj = TYPE === 'PROFILE' ? Profile : TYPE === 'STEPTWO' ? StepTwoRenewal : TYPE;
    const [isDisabled, setDisable] = useState(false);
    const { values, setValues } = useFormikContext();

    useEffect(()=>{

        setDisable(false);

        if(values['employcheck'] != 1) {
            setDisable(true);
            if(TYPE === 'PROFILE'){
                setValues({
                    ...values,
                    employcheck:values['employcheck'],
                    org:'',
                    pos:'',
                    ofctelno:'',
                    ofcmobile:'',
                    ofcemail:'',
                    ofcfax:'',
                    ofcaddress:'',
                });
            }
            if(TYPE === 'STEPTWO'){
                setValues({
                    ...values,
                    employcheck:values['employcheck'],
                    org:'',
                    pos:'',
                    tel:'',
                    mobile:'',
                    email:'',
                    fax:'',
                    address:'',
                });
            }
        }
    },[values['employcheck']]);

    // Names based on used wether is a profile or membership renewal step 2
    const address = TYPE === 'PROFILE' ? 'ofcaddress' : 'address'
    const tel = TYPE === 'PROFILE' ? 'ofctelno' : 'tel'
    const mobile = TYPE === 'PROFILE' ? 'ofcmobile' : 'mobile'
    const fax = TYPE === 'PROFILE' ? 'ofcfax' : 'fax'
    const email = TYPE === 'PROFILE' ? 'ofcemail' : 'email'
    const gradDate = TYPE === 'PROFILE' ? 'graduate' : 'grad'

    return (
        <Grid container spacing={2}>
            <Grid item xs={10}>
                <EmploymentCheckbox disabled={isReadOnly}/>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="org"
                    label="Organization / Institution"
                    placeholder="Input Organization / Institution Here"
                    readOnly={isReadOnly}
                    disabled={isDisabled}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <TextField
                    name="pos"
                    label="Position / Designation"
                    placeholder="Input Designation"
                    readOnly={isReadOnly}
                    disabled={isDisabled}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <TextField
                    name={tel}
                    label="Telephone Number"
                    placeholder="0212345678"
                    icon={<PhoneIphoneIcon 
                        sx={{ color:'primary.main', fontSize:15 }}
                    />}
                    readOnly={isReadOnly}
                    disabled={isDisabled}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <TextField
                    name={mobile}
                    label="Mobile Number"
                    placeholder="09123456789"
                    icon={<PhoneIphoneIcon 
                        sx={{ color:'primary.main', fontSize:15 }}
                    />}
                    readOnly={isReadOnly}
                    disabled={isDisabled}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <TextField
                    name={email}
                    label="Email Address"
                    placeholder="1ahrnndz@email.com"
                    icon={<EmailIcon 
                        sx={{ color:'primary.main', fontSize:15 }}
                    />}
                    readOnly={isReadOnly}
                    disabled={isDisabled}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <TextField
                    name={fax}
                    label="Fax Number (if any)"
                    placeholder="09123456789"
                    icon={<EmailIcon 
                        sx={{ color:'primary.main', fontSize:15 }}
                    />}
                    readOnly={isReadOnly}
                    disabled={isDisabled}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name={address}
                    label="Office Address"
                    placeholder="Brgy. City/Municipality, Province, Region"
                    icon={<EmailIcon 
                        sx={{ color:'primary.main', fontSize:15 }}
                    />}
                    readOnly={isReadOnly}
                    disabled={isDisabled}
                />
            </Grid>
            <Grid item xs={12}>
                <Box my={1}>
                    <FieldLabel text="Highest Educational Attainment" required/>
                </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Degree readOnly={isReadOnly}/>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
                <FieldOfSpecialization isReadOnly={isReadOnly}/>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="school"
                    label="School / College / University"
                    placeholder="Input Name of School / College / University"
                    readOnly={isReadOnly}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <DatePicker
                    name={gradDate}
                    views={['year']}
                    format="YYYY"
                    label="Year Graduated"
                    readOnly={isReadOnly}
                /> 
            </Grid>
        </Grid>
    )
}