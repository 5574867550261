import React, { Fragment } from 'react';
import { useFormikContext } from 'formik';
import { useStyles, helperTextStyles } from './style';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ErrorIcon from '@mui/icons-material/Error';

import ClearButton from '../FieldClearButton';
import FieldLabel from '../FieldLabel';
import clsx from 'clsx';
 
export default function CustomTextField({ 
    name, 
    label, 
    icon, 
    required, 
    disabled,
    readOnly,
    error,
    value,
    ...otherProps 
}) {
    const classes = useStyles();
    const helperTextClasses = helperTextStyles();
    const formik = useFormikContext();
    const { setFieldValue, errors, values } = formik;

    let configFieldLabel = {
        text:label,
        required
    }

    let configTextField = {
        ...otherProps,
        disabled,
        readOnly:true,
        size:"small",
        variant:"outlined",
        fullWidth:true,
        FormHelperTextProps:{ classes: helperTextClasses },
        value:values[name] ?? null,
        onChange:e=>{
            setFieldValue(name, e.target.value);
        },
        className:clsx({
            [classes.root]:!errors[name] && !disabled,
            [classes.error]:errors[name] && !disabled,
            [classes.disabled]:disabled
        }),
        InputProps:{
            readOnly,
            endAdornment:(values[name] && !disabled && !readOnly) 
            ? <ClearButton handleClick={e=>setFieldValue(name,'')}/>
            : (errors[name] && !disabled) && <ErrorIcon 
                style={{ fontSize: 16, color:'#d32f2f' }}
            />,
            startAdornment:(
                <InputAdornment position="start">
                    {icon}
                </InputAdornment>
            )
        }
    }
 
    // when there's an error on errors obj
    if(errors[name] && !disabled){
        configFieldLabel.error = true;
        configTextField.error = true;
        configTextField.helperText = errors[name];
    }

    return (
        <>
            <FieldLabel {...configFieldLabel}/>
            <TextField readOnly {...configTextField}/>
        </>
    );
}