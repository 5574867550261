import { useState, useEffect, useCallback } from 'react';
import { ValidationSchema } from './utils/validation';
import { makeStyles } from '@mui/styles';
import { useAuth } from 'hooks/useAuth';
import { api } from 'utils/API';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import BorderColorIcon from '@mui/icons-material/BorderColor';

import AffliatedRegionalChapter from 'components/Form/AffiliatedRegionalChapter';
import ImageUploader from 'components/Form/FileUploader/Image';
import FormikForm from 'components/Form/Formik';
import Button from 'components/Form/Button';

const useStyles = makeStyles((theme)=>({
    textLink:{
        color:theme.palette.primary.main,
        fontWeight:700,
    }
}));

export default function AffiliatedChapter() {

    const classes = useStyles();
    const { auth, handleRefreshProfile } = useAuth();
    const [isEdit, setEdit] = useState(false);
    const [form, setForm] = useState({
        action:'update_chapter',
        photo:'',
        chapter:''
    });

    const setFormValues = useCallback((result) => {
        setForm(form=>({
            ...form, 
            id: result?.id,
            oldChapter: result?.chapter,
            chapter: result?.chapter,
            photo: result?.photo,
            fname: result?.fname,
            lname: result?.lname,
            email: result?.email,
        }));
    },[setForm, form]);

    useEffect(()=>setFormValues(auth), [auth]);

    const handleUpdate = async (values) => {
        const endpoint = '/profile_menu.php';
        const response = await api.post(endpoint, values).then(e=>e.data);

        if(response?.status === 200) {
            await handleRefreshProfile();
            await setEdit(false);
            await setFormValues(values);
        } 
    }

    return (
        <FormikForm 
            initialValues={{...form}}
            validationSchema={ValidationSchema} 
            enableReinitialize={true}
            onSubmit={values=>{
                handleUpdate(values)
            }}
        >
            <Grid container direction="row" justify="center" align="center" spacing={2}>
                {!isEdit && <Grid item xs={12} align="end">
                    <Box 
                        component="span" 
                        textAlign="center" 
                        color="#209D50" 
                        sx={{ cursor:'pointer' }} 
                        onClick={e=>{
                            setEdit(true);
                        }}
                    >
                        Update
                        <BorderColorIcon sx={{fontSize:18}}/>
                    </Box>
                </Grid>}
                <Grid 
                    container 
                    display="flex" 
                    alignItems="center"
                    justify="center"
                >
                    <Grid item xs={12}>
                        <Box
                            fontSize={14}
                            py={3}
                            color="#757575"
                            textAlign="justify"
                        >
                            Please select the region you would like to be part of based on the region of your workplace or home address.
                            The regional chapter officers may contact and inform you about the region's activities. 
                            They can also provide assistance to you on matters related to PAA.
                        </Box>
                    </Grid>
                    <Grid xs={6}>
                        <AffliatedRegionalChapter
                            disabled
                        />
                    </Grid>
                    <Grid item container xs={12}>
                        <Box pt={5}>
                            <span>Upload a&nbsp;</span>
                            <span className={classes.textLink}>
                                1 x 1photo with white background
                            </span>
                        </Box>
                        <Grid item xs={12}>
                            <Box pt={5}>
                                <ImageUploader disabled={!isEdit} name="photo"/>
                            </Box>
                        </Grid>
                    </Grid>
                    {isEdit && <Grid item xs={12} align="end">
                        <Box position="relative" top={50}>
                            <Button
                                width={175}
                                variant="contained"
                            >
                                Save
                            </Button>
                        </Box>
                    </Grid>}
                </Grid>
            </Grid>
        </FormikForm>
    );
}