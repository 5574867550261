import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root:{
        fontSize: 16,
        color:'#d32f2f',
        right: 27,
        height: 20,
        zIndex: 1
    }
});

export { useStyles };