import { useState, useEffect } from "react";
import { useGetFormValues } from "hooks/useGetFormValues";
import AffiliatedRegionalChapterForm from "components/Form/AffiliatedRegionalChapterForm";

export default function StepThreeForm() {

    const { result:data } = useGetFormValues();
    const [form, setForm] = useState({
        action:'step2',
        photo:'',
        chapter:''
    });
    
    useEffect(()=>{
        setForm(form=>({ 
            ...form, 
            id:data?.id,
            chapter:data?.chapter,
            photo:data?.photo
        }));
    },[data]);

    const configProps = {
        initialValues:form,
        stepNext:"step-three"
    }

    return <AffiliatedRegionalChapterForm
        {...configProps}
    />;
}