import * as Yup from "yup";

export const ValidationSchema = Yup.object().shape({

    photo: Yup.string().nullable(),

    email:Yup.string().when('employcheck',{
        is:1,
        then:Yup.string()
        .required("Personal Email Address is required")
        .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,'Email Address must be valid!')
        .nullable(),
        otherwise:Yup.string().nullable(),
    }).nullable(),

    prcno: Yup.string().when('check',{
        is:value => value === 1 || value === 2,
        then:Yup.string()
        .matches(/^\d{1,15}$/,'PRC Number must be valid!')
        .required('PRC Number is required!')
        .nullable(),
        otherwise:Yup.string().nullable(),
    }).nullable(),

    datereg: Yup.string().when('check',{
        is:value => value === 1 || value === 2,
        then:Yup.string().required('Date of License Expiration is required!').nullable(),
        otherwise:Yup.string().nullable(),
    }),

    dateexp: Yup.string().when('check',{
        is:value => value === 1 || value === 2,
        then:Yup.string().required('Date of License Registration is required!').nullable(),
        otherwise:Yup.string().nullable(),
    }),

    cv: Yup.string().nullable(),
});