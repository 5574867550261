import { useState, useEffect } from "react";
import { useAuth } from "hooks/useAuth";
import { ValidationSchema } from "./utils/validation";

import CardStepLayout from "components/CardStepLayout";
import EmploymentProfile from "components/Form/EmploymentProfile";
import FormPage from "components/Form/FormikFormPage";
import Box from "@mui/material/Box";

export default function StepTwoForm(props) {

    const { auth } = useAuth();
    const [form, setForm] = useState({
        action:"step2",
    });

    useEffect(()=>{
        const result = auth;
        const employmentDetails = {
            id:result?.id,
            employcheck:parseInt(result?.is_employed),
            pos:result?.position,
            civil:result?.civil_status,
            org:result?.agency,
            email:result?.office_email,
            address:result?.office_add,
            fax:result?.office_fax,
            tel:result?.office_number,
            mobile:result?.office_mobile,
            degree:result?.degree,
            fos:result?.FOS,
            school:result?.university_name,
            grad:result?.grad_date
        }
            
        setForm(form=>({ ...form, ...employmentDetails }));
    },[auth]);

    return (
        <FormPage
            type={1} // renewal type
            formValues={form}
            validationSchema={ValidationSchema}
            step="step-three" // next step 
        >
            <CardStepLayout
                subheader="Step 2 of 6"
                header="Membership Renewal"
                subtitle={
                    <Box component="span">
                        Please fill in the details below. Fields with asterisk
                        (<span style={{ color:"red" }}>*</span>) are mandatory.
                    </Box>
                }
            >
                <Box component="main" xs={12} pb={12} px={"10%"}>
                    <EmploymentProfile TYPE="STEPTWO"/>
                </Box>
            </CardStepLayout>
        </FormPage>
    );
}