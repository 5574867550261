import { useFormikContext } from 'formik';
import { useEffect, useState, useCallback } from 'react';
import { usePostRequest } from 'helper/post.api';
import Autocomplete from 'components/Form/Fields/AutoComplete';

export default function CustomDropdown({ label, required, ...otherProps }) {

    const { loading, data, setPOST } = usePostRequest('/specialization.php');
    const [options, setOptions] = useState([]);

    const configDropdownSelect = {
        ...otherProps,
        options,
        loading,
        disabled:true,
        isRequired:required,
        name:"fos",
        label:"Field Of Specialization"
    }

    // call request when the dependency triggered
    const handlePostRequest = useCallback(()=>{
        setPOST({ action:"get" });
    },[]);

    // this will trigger handlePostRequest 
    useEffect(()=>handlePostRequest(),[handlePostRequest])

    // set the key value paire format 
    useEffect(()=>{
        const { data:fos } = data || [];
        setOptions(fos?.map(items=>({
            label: items.fields,
            value: items.fields
        })));
    },[data]);

    if(data.hasOwnProperty('data')) {
        configDropdownSelect.disabled = false;
    }

    if(otherProps?.disabled){
        configDropdownSelect.disabled = true;
    }

    return <Autocomplete {...configDropdownSelect}/>
}