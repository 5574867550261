import { useFormikContext } from 'formik';
import TextField from 'components/Form/Fields/TextField';
import moment from 'moment';
import 'moment-duration-format';

import { useEffect } from 'react';

export default function Age({ ...otherProps }){

    const { values, setFieldValue, setErrors } = useFormikContext();

    function calculateAge(dob) {
        let diff = moment(dob).diff(moment(), 'milliseconds');
        let duration = moment.duration(diff);
        return duration.format().replace("-","");
    }

    // problem is early age accepts like days, a month, year ago
    useEffect(()=>{
        if(values['bdate']){
            const ageText = calculateAge(values['bdate']);
            const isUnderYear = /year/.test(ageText);

            if(ageText) {

                // text to array ['24 years', '2 months', '2 days'
                let _ageText = ageText.split(",");

                // get the first index array ex. '24 years'
                let extTextAge = _ageText[0];

                // remove non numeric
                let yearOnly = extTextAge.replace(/\D/g,''); 

                // if the format is mm:ss/day(s)/month(s)/
                if(!isUnderYear){
                    yearOnly = 0;
                }

                setFieldValue('age', parseInt(yearOnly));
            }
        }
    },[values['bdate']]);

    const configBirthdate = {
        ...otherProps,
        required:true,
        readOnly:true,
        value:values['age'],
        name:"age",
        label:"Age",
        placeholder:'Age',
    }

    return <TextField {...configBirthdate}/>
}