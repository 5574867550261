import { makeStyles } from "@mui/styles";

import Box from "@mui/material/Box";
import BriefProfile from "./BriefProfile";
import NewsFeed from "./NewsFeed";

const useStyles = makeStyles({
    root: {
        display: "flex",
        overflow: "hidden",
        height: "calc(100vh - 64px)" // this prevent to have vertical scroll for the whole panel
    },
    list: {
        width: "40%",
        borderRight: "1px solid #ccc",
        overflowY: "auto",
        minHeight: "calc(100vh - 64px)" // gives vertical scroll freely for the list 
    },
    view: {
        width: "60%",
        overflowY: "auto",
        display: "flex",
        justifyContent: "center",
        paddingTop: "30px",
        paddingBottom: "30px",
    },
});

export default function SummaryProfile(){
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.list}>
                <BriefProfile/> 
            </Box>
            <NewsFeed/>
        </Box>
    );
}