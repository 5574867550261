import * as Yup from "yup";

export const ValidationSchema = Yup.object().shape({
    fname:Yup.string()
    .matches(/^(?=.{1,50}$)[a-zÀ-ž.]+(?:['-_\.\s][a-zÀ-ž]+)*$/i,'First Name must be minimum of 2 letters and contain only letters')
    .required('First Name is required!'),

    mname:Yup.string().when('hasMiddleName',{
        is:true,
        then:Yup.string().nullable(),
        otherwise:Yup.string()
        .matches(/^[A-Za-zÀ-ž\s]*$/gi,'Middle Initial must be valid')
        .required('Middle Initial is required!'),
    }),

    lname:Yup.string()
    .matches(/^(?=.{1,50}$)[a-zÀ-ž.]+(?:['-_\.\s][a-zÀ-ž]+)*$/i,'Surname must be minimum of 2 letters and contain only letters')
    .required('Surname is required!'),

    suffix:Yup.string()
    .matches(/^[A-Za-z]{0,4}$/gi,'Suffix must be valid!')
    .nullable(),
})