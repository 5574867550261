import { Navigate, Outlet, useLocation } from "react-router-dom";
import isBase64 from 'is-base64';

export default function ParamRouteGuard() {

    const params = useLocation().search;

    // check if theres parameter in the url
    if(params.includes('?param=')){
        const base64ID = new URLSearchParams(params).get('param');

        if(isBase64(base64ID)) return <Outlet/>

        return <Navigate to="login"/>
    }

    return <Navigate to="login"/>
}