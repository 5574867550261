import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

// this is used in main page and membership page
export default function RequirePrcAuth(){
    const { isPrcAuth } = useAuth();
    const location = useLocation();

    if(!isPrcAuth()){
        return <Navigate replace to='/prc/authentication' state={{ from: location }}/>;
    }
    
    return <Outlet/>;
};