import { useState, useEffect } from 'react';
import { usePostRequest } from 'helper/post.api';
import { useParamBase64 } from 'hooks/useParamBase64';
import { useFormStepNavigation } from 'hooks/useFormStepNavigation';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { IconButton } from '@mui/material';

import Box from '@mui/material/Box';
import Button from 'components/Form/Button';
import FormikForm from 'components/Form/Formik';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

export default function Form(props) {
    const { 
        step,
        children,
        formValues, 
        validationSchema,  
        backWardButton = true,
        type = 0
    } = props;

    const SAVE_TYPES = {
        STEPS: 'STEPS',
        PARTIAL: 'PARTIAL',
    }

    const POST_ROUTE = type === 0 
    ? "/new_member.php"
    : "/renewal.php";

    const navigate = useNavigate();
    const paramID = useParamBase64();
    const mobile = useMediaQuery('(max-width:800px)');

    const { navigateStep, navigateSave } = useFormStepNavigation(type);
    const { data, errors, setPOST } = usePostRequest(POST_ROUTE);
    const { status } = data; 

    const [saveType, setSaveType] = useState(SAVE_TYPES.STEPS);
    const [formState, setFormState] = useState({});
    const [form, setForm] = useState({
        id:paramID
    });
    
    useEffect(()=>{
        setForm({
            ...formValues,
        });
    },[formValues])

    // this submit value with POST request from hooks
    const handleSubmit = (values, savetype) => {
        setPOST(values);
        setSaveType(savetype);
        setFormState(values);
    }

    // this will proceed to verification route
    useEffect(()=>{
        if(status === 200) {
            if(saveType === SAVE_TYPES.PARTIAL){
                navigateSave();
            } else if(saveType === SAVE_TYPES.STEPS){
                navigateStep(step, {[step]:formState });
            }
        }
    },[status, saveType])
    
    return ( 
        <Box 
            component="main"
            display="flex"
            justifyContent="center"
            backgroundColor="#F8F8F8"
            minHeight="100vh"
        >
            <FormikForm
                initialValues={{...form}}
                validationSchema={validationSchema}
                enableReinitialize={true}
                setErrors={errors}
                onSubmit={values=>{
                    handleSubmit(values, SAVE_TYPES.STEPS);
                }}
            >
                {backWardButton === true &&
                <IconButton
                    sx={{
                        position: 'absolute',
                        left: 100,
                        top: 40
                    }} 
                    onClick={e=>navigate(-1)}
                >
                    <ArrowCircleLeftIcon sx={{ color:'primary.main' }}/>
                </IconButton>}

                <Box 
                    component="content"
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    width={mobile ? "100%" : "70%"}
                    paddingTop={mobile ? 0 : 5}
                >
                    {children}
                    <Box
                        width="100%"
                        display= "flex"
                        alignItems= "center"
                        justifyContent="space-around"
                        margin="25px 25px"
                        id="btn-contianer"
                    >
                        <Button
                            width={175}
                            variant="contained"
                            submitFormTypes={SAVE_TYPES.PARTIAL}
                            handleClick={val=>handleSubmit(val, SAVE_TYPES.PARTIAL)} // this return form values 
                        >
                            Save
                        </Button>
                        <Button 
                            width={175}
                            variant="contained"
                            submitFormTypes={SAVE_TYPES.STEPS}
                        >
                            Next
                        </Button>
                    </Box>
                </Box>
            </FormikForm>
        </Box>
    );
}