import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { useFormikContext } from 'formik';

export default  function CustomCheckBox({ name, label, labelColor, handleChange, value, ...otherProps }){
    
    const formik = useFormikContext();

    let configCheckBox = {
        ...otherProps,
        name,
        color:"primary",
        size:"small"
    }

    const [checkValue, setCheckValue] = useState(formik?.values[name]||value);

    useEffect(()=>{
        setCheckValue(value)
    },[value]);
    
    if(formik && name){
        const { setFieldValue, values } = formik;

        configCheckBox = {
            ...configCheckBox,
            value: checkValue,
            checked:checkValue,
            onChange:e=>{
                setFieldValue(name, e.target.checked);
                setCheckValue(e.target.checked);
                if(handleChange instanceof Function){
                    handleChange(e.target.checked);
                }
            },
        }
    }

    return (
        <Box display="flex" alignItems="center">
            <FormControlLabel
                control={
                    <Checkbox {...configCheckBox}/>
                }
                label={
                    <Typography variant="body2" color={labelColor || "textSecondary" }>
                        {label}
                    </Typography>
                }
            />
        </Box>
    );
}