import { useState, useEffect } from 'react';
import { ValidationSchema } from './utils/validation';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormPage from 'components/Form/FormikFormPage';
import AgricuristCheckbox from 'components/Form/AgricuristCheckBox';
import ImageUploader from 'components/Form/FileUploader/Image';
import CSVUploader from 'components/Form/FileUploader/CSV';
import TextField from 'components/Form/Fields/TextField';
import FieldLabel from 'components/Form/Fields/FieldLabel';
import DatePicker from 'components/Form/Fields/DatePicker';
import PrcEmail from 'components/Form/PrcEmail';
import CardStepLayout from "components/CardStepLayout";

export default function ProfessionalDetailsForm(props) {

    // Payment Type:
    // 0 - Registration
    // 1 - Renewal
    const { hasProState, type = 0, stepNext, initialValues } = props;
    const [nonPro, setNonPro] = useState(hasProState);

    const SUBHEADER = type === 0 
    ? "Step 3 of 5" 
    : "Step 4 of 6";

    const HEADER = type === 0 
    ? "Professional Details" 
    : "Membership Renewal";
    
    const hasPrcId = !!initialValues?.prcno ? 1 : 0;

    const handleCheckIfHasPRC = has_prc => {
        return parseInt(has_prc) === 1
        ? 1 : parseInt(has_prc) === 0
        ? 0 : 2
    }

    const handleCheckIfPro = has_prc => {
        return parseInt(has_prc) === 1 
        ? false : parseInt(has_prc) === 0 
        ? true : false
    }
    
    useEffect(()=>{
        setNonPro(handleCheckIfPro(hasPrcId));
    },[initialValues]);

    return (
        <FormPage 
            formValues={{
                ...initialValues,
                check: handleCheckIfHasPRC(hasPrcId)
            }}
            validationSchema={ValidationSchema}
            step={stepNext}
            type={type}
        >   
            <CardStepLayout
                subheader={SUBHEADER}
                header={HEADER}
                subtitleAlignment="justify"
                subtitle={
                    <Box component="span">
                        Please fill in the details below. Fields with asterisk
                        (<span style={{ color:"red" }}>*</span>) are mandatory.
                    </Box>
                }
            >
                <Grid 
                    container
                    display="flex"
                    justify="center"
                    alignItems="center"
                    sx={{ pb:12, px:'15%' }}
                >
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item container xs={12}>
                                <Grid item>
                                    <AgricuristCheckbox
                                        handleChange={e=>setNonPro(e === 1 ? false : true)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box my={1}>
                                        <FieldLabel text="Upload a copy of your PRC ID"/>
                                    </Box>
                                    <ImageUploader 
                                        required
                                        disabled={nonPro}
                                        name="photo"
                                        type="PRC"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <TextField
                                    required
                                    name="prcno"
                                    disabled={nonPro}
                                    label="PRC Registration Number"
                                    placeholder="Input PRC Number"
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <DatePicker
                                    required
                                    disabled={nonPro}
                                    name="datereg"
                                    label="Date of License Registration"
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <DatePicker
                                    required
                                    disabled={nonPro}
                                    name="dateexp"
                                    label="Date of License Expiration"
                                />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <PrcEmail disabled={nonPro}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Box my={1}>
                                    <FieldLabel text="Upload a curriculum vitae "/>
                                </Box>
                                <Box
                                    fontSize={14}
                                    color="#209D50"
                                    textAlign="justify"
                                >
                                    By uploading your curriculum vitae, 
                                    you will be informed of possible job opportunities, 
                                    and invited to awards nominations, special seminars, 
                                    workshops/discussion groups and the like.
                                </Box>
                                <Box my={1}>
                                    <CSVUploader required name="cv" type="PRC"/>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardStepLayout>
        </FormPage>
    );
}