import { Outlet, Navigate, useLocation } from "react-router-dom";

export default function EventRegistrationGuard({ urlParams, paramType }) {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const paramNames =  Array.from(searchParams.keys());
  
    const paramsExist = urlParams.every(function(val){
        return paramNames.includes(val);
    });

    const hasIncludes = location.search?.length > 0 && paramsExist;

    if(!hasIncludes) return <Navigate to="/notifications"/>;
    
    const paramTypes = paramNames.every(function(param){
        return typeof +param === paramType;
    });
    
    const eventTypeId = +searchParams.get("event_type_id");
    const validEventId = eventTypeId === 1 || eventTypeId === 0;

    return (paramTypes && validEventId) ? <Outlet/> : <Navigate to="/notifications"/>;
}