import * as Yup from "yup";

const NAME_PATTERN = /^(?=.{1,50}$)[a-zÀ-ž.]+(?:['-_\.\s][a-zÀ-ž]+)*$/i;
const EMAIL_PATTERN = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const ValidationSchema = Yup.object().shape({
    fname:Yup.string()
    .required("First Name is required")
    .matches(NAME_PATTERN,'First Name must be minimum of 2 letters and contain only letters')
    .nullable(),

    mname:Yup.string().when('hasMiddleName',{
        is:true,
        then:Yup.string().nullable(),
        otherwise:Yup.string()
        .matches(NAME_PATTERN,'Middle Initial must be valid')
        .nullable(),
    }).nullable(),

    lname:Yup.string()
    .required("Last Name is required")
    .matches(NAME_PATTERN,'Last Name must be minimum of 2 letters and contain only letters')
    .nullable(),

    suffix:Yup.string()
    .matches(/^[A-Za-z]{0,4}$/gi,'Suffix must be valid!'),

    email:Yup.string()
    .required("Email Address is required")
    .matches(EMAIL_PATTERN,'Email Address must be valid!'),

    password:Yup.string().matches().required("Password required!"),
});