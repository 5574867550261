import { useSnackbar } from 'notistack';

export const STATUS = {
    SUCCESS:'success',
    ERROR:'error'
}

export const useSnackBarMessage = () => {

    const { enqueueSnackbar } = useSnackbar();

     const setSnackBarMessage = (message, status) => {
        if(message){
            enqueueSnackbar(message,{
                preventDuplicate: true,    
                variant: status, 
            });
        }
    }

    return { 
        setSnackBarMessage
    }
};
