import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { IconButton } from "@mui/material";

import Box from "@mui/material/Box";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

import logo from "assets/images/logo.png";
import vectorBg from "assets/images/vector-bg.png";
import regVector from "assets/vectors/vector-reg.png";
import fgPassVector from "assets/vectors/vector-fg-pass.png"

export default function CardLayoutForm({ children, leftImageType }) {

    const navigate  = useNavigate();
    const mobile = useMediaQuery('(max-width:1000px)');
    const smLaptop = useMediaQuery('(max-width:1300px)');
    const LEFT_IMAGES = {
        1: regVector,
        2: fgPassVector
    }
    
    let cardHeightMobile = "75vh";
    if(mobile) cardHeightMobile = "inherit";

    const contentStyleMobile = mobile ? {
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    } : {}

    return (
        <Fragment>
            <Box
                minHeight="100vh"
                display="flex" 
                flexDirection="row"
                alignItems="center"
                justifyContent="center" 
                backgroundColor="gray"
                style={{
                    backgroundImage:`url(${vectorBg})`,
                    backgroundSize:'cover',
                    backgroundRepeat:'no-repeat'
                }}
            >   
                {!smLaptop && <IconButton 
                    sx={{
                        position: 'absolute',
                        left: 70,
                        top: mobile ? 0 : 40
                    }} 
                    onClick={e=>navigate(-1)}
                >
                    <ArrowCircleLeftIcon 
                        sx={{ color:'primary.main' }}
                    />
                </IconButton>}
                <Box
                    px={2}
                    py={mobile ? 0 : 2}
                    width="1000px"
                    minHeight={cardHeightMobile}
                    backgroundColor="#FFF"
                    overflow={mobile ? "none" : "hidden"}
                    borderRadius={2}
                    boxShadow={!mobile?`0px 2px 12px rgba(184, 184, 184, 0.24),
                    0px 2px 8px rgba(184, 184, 184, 0.24),
                    0px 2px 4px rgba(184, 184, 184, 0.24)`:"none"}
                >
                    <Box
                        component="main"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        height="inherit"
                    >
                        {!mobile &&
                        <Box 
                            flex={1} 
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-around"
                            alignItems="center"
                        >
                            <Box component="section" alignSelf="start">
                                <img 
                                    alt="company-logo"
                                    style={{ height: "8vh" }}
                                    src={logo}
                                />
                            </Box>
                            <Box component="section">
                                <img
                                    alt="vector"
                                    style={{ height: "50vh" }}
                                    src={LEFT_IMAGES[leftImageType]}
                                />
                            </Box>
                        </Box>}
                        <Box
                            mx={3}
                            component="section"
                            width={mobile ? "100%" : "50%"}
                            style={contentStyleMobile}
                        >
                            {mobile && 
                            <Box my={5} display="flex" justifyContent="center">
                                <img src={logo} height="45vw" alt="company-logo"/>
                            </Box>}
                            <Box
                                id="content" 
                                overflow="auto"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                minHeight={!mobile? "75vh" : "initial"}
                            >
                                {children}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Fragment>
    );
}