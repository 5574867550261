import { useEffect, useState } from 'react';
import DropdownSelect from 'components/Form/Fields/Dropdown/Select';

export default function CustomDropdown({ name, required, ...otherProps }){ 
    
    const [options, setOptions] = useState([
        { text: "Current Home Address", value:'0' },
        { text: "Permanent Home Address", value:'1' },
        { text: "Others", value:'2' },
    ]);

    const configDropdownSelect = {
        ...otherProps,
        name,
        options,
        required,
        label:'Mailing Address'
    }

    useEffect(()=>{
        setOptions(options?.map(items=>({
            text: items.text,
            value: items.value
        })));
    },[]);
    
    return <DropdownSelect {...configDropdownSelect}/>;
}