import * as Yup from "yup";

const TEXT_PATTERN = /^(?=.{1,200}$)[a-zÀ-ž.]+(?:['-_\.\s][a-zÀ-ž\.]+)*$/i;

export const ValidationSchema = Yup.object().shape({
    fname:Yup.string()
    .required('First Name is required!')
    .matches(TEXT_PATTERN,"First name and Suffix(optional)"),
    
    mname:Yup.string()
    .matches(TEXT_PATTERN,"Middle name must be valid")
    .nullable(),

    lname:Yup.string()
    .required('Last Name is required!')
    .matches(TEXT_PATTERN,"Last name must be valid"),

    prcno: Yup.string()
    .matches(/^\d{1,15}$/,'PRC ID Number must be valid!')
    .nullable(),

    email:Yup.string()
    .required("Personal Email Address is required!")
    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,'Email Address must be valid!'),

    contact: Yup.string()
    .matches(/^\d{8,11}$/,'Phone Number must be valid!')
    .required('Phone Number is required!')
    .nullable(),

    company_name:Yup.string()
    .matches(TEXT_PATTERN, "Company Name must be valid!")
    .nullable(),

    profession:Yup.string()
    .matches(TEXT_PATTERN, "Profession must be valid!")
    .nullable(),
});