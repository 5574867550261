import { useEffect, useState, useCallback } from 'react';
import { usePostRequest } from 'helper/post.api';
import { useFormikContext } from 'formik';
import TextField from 'components/Form/Fields/TextField';

export default function CustomDropdown({ name, prerequisitefieldnames, label, required, ...otherProps }) {

    const { values, setFieldValue } = useFormikContext();
    const { loading, data, setPOST } = usePostRequest('/locations.php', true);

    const { region, province, barangay,  municipality } = prerequisitefieldnames;

    const configTextField = {
        ...otherProps, name, label, required, loading,
        label:'Zip Code'
    }

    // call request when the dependency triggered
    const handlePostRequest = useCallback(()=>{
        if(values[barangay]){
            setPOST({
                action:"get_zip",
                province:values[province],
                region:values[region],
                municipality:values[municipality],
                barangay:values[barangay],
            });
        }
    },[values[barangay]]);
    
    // this will trigger handlePostRequest 
    useEffect(()=>{
        handlePostRequest();
    },[handlePostRequest])

    // set the key value paire format 
    useEffect(()=>{
        const { status, zip } = data || [];
        if(status === 200) setFieldValue(name, zip);
    },[data]);

    return <TextField {...configTextField}/>
}