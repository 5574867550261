import { useState, useEffect, Suspense, lazy } from 'react';
import { ValidationSchema } from './utils/validation';
import { useAuth } from 'hooks/useAuth';
import { api } from 'utils/API';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import FormikForm from 'components/Form/Formik';
import Button from 'components/Form/Button';
import PermanentAddress from 'components/Form/Address/PermanentAddress';
import FieldLabel from 'components/Form/Fields/FieldLabel';
import DatePicker from 'components/Form/Fields/DatePicker';
import HomeAddress from 'components/Form/Address/HomeAddress';
import MailingAddress from 'components/Form/Address/MailingAddress';
import TextField from 'components/Form/Fields/TextField';
import CivilStatus from 'components/Form/CivilStatus';
import Sex from 'components/Form/Sex';
import Age from 'components/Form/Age';
import Employment from 'components/Form/EmploymentProfile';
import moment from 'moment';

const PermanentAddress1 = lazy(()=>import('components/Form/Address/HomeAddress'));

export default function PersonalInformationForm(props) {

    const { auth, handleRefreshProfile } = useAuth();
    const [isEdit, setEdit] = useState(false);
    const [form, setForm] = useState({
        action:'update_profile',
        nationality:"Filipino",
        hasPermanentAddress:false
    });
    
    useEffect(()=>{
        const result = auth;

        const personalDetails = {
            id: result?.id,
            fname:result?.fname,
            mname:result?.mname,
            lname:result?.lname,
            suffix: result?.suffix,
            bdate:result?.dob,
            age:result?.age,
            gender:result?.gender,
            nationality:result?.nationality,
            civil:result?.civil_status,
        }

        const currAddress = {
            currentaddress:result?.address,
            curRegion:result?.region,
            curProvince:result?.province,
            curMunicipality:result?.city,
            curBarangay:result?.barangay,
        }

        const perAddress = {
            permanentaddress:result?.res_address,
            perRegion:result?.res_region,
            perProvince:result?.res_province,
            perMunicipality:result?.res_city,
            perBarangay:result?.res_barangay,
        }

        const contactDetails = {
            mailing:result?.mail_option,
            email:result?.email,
            viber:result?.viber,
            mobile:result?.phone,
            tel:result?.landline,
        }

        const employmentDetails = {
            employcheck:parseInt(result?.is_employed),
            pos:result?.position,
            org:result?.agency,
            ofcemail:result?.office_email,
            ofcaddress:result?.office_add,
            ofcfax:result?.office_fax,
            ofctel:result?.office_number,
            ofcmobile:result?.office_mobile,
            degree:result?.degree,
            fos:result?.FOS,
            school:result?.university_name,
            graduate:result?.grad_date
        }

        setForm(form=>({
            ...form,
            ...personalDetails,
            ...employmentDetails,
            ...currAddress,
            ...perAddress,
            ...contactDetails,
        }));
    },[auth]);
    
    const handleUpdate = async (values) => {
        const endpoint = '/profile_menu.php';
        const response = await api.post(endpoint, values).then(e=>e.data);

        if(response?.status === 200) {
            await handleRefreshProfile();
            await setEdit(false);
        } 
    }
       
        // return  (
        // <FormikForm 
        //     initialValues={{...form}}
        //     validationSchema={ValidationSchema} 
        //     enableReinitialize={true}
        //     onSubmit={values=>handleUpdate(values)}
        // >
        // <Suspense fallback="loading">
        //     <PermanentAddress1
        //         label="Current Home Address"
        //         fieldNames={{
        //             address:"currentaddress",
        //             region:"curRegion",
        //             province:"curProvince",
        //             municipality:"curMunicipality",
        //             barangay:"curBarangay"
        //         }}
        //         readOnly={!isEdit}
        //     />
        // </Suspense>
        // <Suspense fallback="loading">
        //     <PermanentAddress1
        //         label="Current Home Address"
        //         fieldNames={{
        //             address:"currentaddress",
        //             region:"curRegion",
        //             province:"curProvince",
        //             municipality:"curMunicipality",
        //             barangay:"curBarangay"
        //         }}
        //         readOnly={!isEdit}
        //     />
        // </Suspense>
        // </FormikForm>
        // );
    return (
        <FormikForm 
            initialValues={{...form}}
            validationSchema={ValidationSchema} 
            enableReinitialize={true}
            onSubmit={values=>handleUpdate(values)}
        >
            <Grid
                xs={12}
                container
                direction="row"
                justify="center"
                align="center"
                spacing={2}
            >
                {!isEdit && <Grid item xs={12} align="end">
                    <Box 
                        component="span" 
                        textAlign="center" 
                        color="#209D50" 
                        sx={{ cursor:'pointer' }} 
                        onClick={e=>{
                            setEdit(true);
                        }}
                    >
                        Update
                        <BorderColorIcon sx={{fontSize:18}}/>
                    </Box>
                </Grid>}
                <Grid item xs={6}>
                    <TextField
                        required
                        ucFirst
                        name="fname"
                        label="First Name"
                        placeholder="Input First Name"
                        readOnly={!isEdit}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        ucFirst
                        name="lname"
                        label="Surname"
                        placeholder="Input Surname"
                        readOnly={!isEdit}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        required
                        ucFirst
                        name="mname"
                        label="Middle Name"
                        placeholder="Input Fist Name"
                        readOnly={!isEdit}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        required
                        name="suffix"
                        label="Suffix"
                        placeholder="--"
                        readOnly={!isEdit}
                    />
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={4}>
                    <DatePicker
                        label="Birth date"
                        name="bdate"
                        maxDate={moment()}
                        readOnly={!isEdit}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Age/>
                </Grid>
                <Grid item xs={4}>
                    <Sex
                        required
                        placeholder="Select"
                        readOnly={!isEdit}
                    />
                </Grid>
                <Grid item xs={4}>
                    <CivilStatus
                        required
                        name="civil"
                        label="Civil Status"
                        placeholder="Select"
                        readOnly={!isEdit}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        required
                        name="nationality"
                        label="Nationality"
                        placeholder="Input Nationality"
                        readOnly={!isEdit}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        required
                        name="email"
                        label="Personal Email Address"
                        placeholder="Ex: juandelacruz@email.com"
                        icon={<EmailIcon sx={{ color:'primary.main', fontSize:15 }}/>}
                        readOnly={!isEdit}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        required
                        name="tel"
                        label="Telephone Number"
                        placeholder="0212345678"
                        icon={<PhoneIphoneIcon 
                            sx={{ color:'primary.main', fontSize:15 }}
                        />}
                        readOnly={!isEdit}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        required
                        name="mobile"
                        label="Mobile Number"
                        placeholder="09123456789"
                        icon={<PhoneIphoneIcon 
                            sx={{ color:'primary.main', fontSize:15 }}
                        />}
                        readOnly={!isEdit}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        required
                        name="viber"
                        label="Viber Number"
                        placeholder="09123456789"
                        icon={<PhoneIphoneIcon 
                            sx={{ color:'primary.main', fontSize:15 }}
                        />}
                        readOnly={!isEdit}
                    />
                </Grid>
                <Grid item xs={12}>
                    {/* <HomeAddress 
                        label="Current Home Address"
                        fieldNames={{
                            address:"currentaddress",
                            region:"curRegion",
                            province:"curProvince",
                            municipality:"curMunicipality",
                            barangay:"curBarangay"
                        }}
                        readOnly={!isEdit}
                    /> */}
                      <Suspense fallback="loading">
                        <PermanentAddress1
                            label="Current Home Address"
                            fieldNames={{
                                address:"currentaddress",
                                region:"curRegion",
                                province:"curProvince",
                                municipality:"curMunicipality",
                                barangay:"curBarangay"
                            }}
                            readOnly={!isEdit}
                        />
                    </Suspense>
                </Grid>
                <Grid item xs={12}>
                    <Box my={1}>
                        <FieldLabel text="Permanent Address" required/>
                    </Box>
                    <Box my={1} ml={2}>
                        <PermanentAddress
                            label="Click this box if same with Current Home Address"
                            readOnly={!isEdit}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {/* <HomeAddress 
                        readOnly={!isEdit}
                        label="Permanent Home Address"
                        fieldNames={{
                            address:"permanentaddress",
                            region:"perRegion",
                            province:"perProvince",
                            municipality:"perMunicipality",
                            barangay:"perBarangay"
                        }}
                    /> */}
                    <Suspense fallback="loading">
                        <PermanentAddress1
                            label="Current Home Address"
                            fieldNames={{
                                address:"currentaddress",
                                region:"curRegion",
                                province:"curProvince",
                                municipality:"curMunicipality",
                                barangay:"curBarangay"
                            }}
                            readOnly={!isEdit}
                        />
                    </Suspense>
                </Grid>
                <Grid item xs={12} container spacing={2} direction="row">
                    <Grid item xs={4}>
                        <MailingAddress
                            required
                            name="mailing"
                            label="Mailing Address"
                            placeholder="Select"
                            readOnly={!isEdit}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Employment isReadOnly={!isEdit}/>
                </Grid>
                {isEdit && <Grid item xs={12} align="end">
                    <Box position="relative" top={50}>
                        <Button 
                            width={175}
                            variant="contained" 
                        >
                            Save
                        </Button>
                    </Box>
                </Grid>}
            </Grid>
        </FormikForm>
    );
}