import { ReactComponent as Award } from 'assets/svg/award-fill.svg';
import { ReactComponent as IdCard } from 'assets/svg/id-card.svg';
import { useAuth } from 'hooks/useAuth';
import { useState } from 'react';

import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ReceiptIcon from '@mui/icons-material/Receipt';

const VIEW_URL = process.env.REACT_APP_VIEW_DOCUMENT_URL;

const documents = [
	{
		image: <IdCard sx={{ fontSize: 35, color: 'primary.main' }} />,
		title: 'MEMBERSHIP ID',
		description:
			'A unique identification code assigned by PAA to each Member and it is non-transferable. This is a digital ID for easy access with downloadable features for printing.',
		endpoint: 'membership_id.php'
	},
	{
		image: <CardMembershipIcon sx={{ fontSize: 35, color: 'primary.main' }} />,
		title: 'MEMBERSHIP CERIFICATE',
		description:
			'An official document determined by the PAA Board which represents all interests to which Members are entitled. Certificate is issued once registered to PAA.',
		endpoint: 'membership_certificate.php'
	},
	{
		image: <Award sx={{ fontSize: 35, color: 'primary.main' }} />,
		title: 'CERTIFICATE OF GOOD STANDING',
		description:
			'COGS is issued to Active Members, meaning all annual dues are settled until the latest year.  Validity of COGS is based on the 3-year payment of annual dues. This is a requisite in renewing the PRC License in Agriculture.',
		endpoint: 'cog_certificate.php'
	},
	{
		image: <ReceiptIcon sx={{ fontSize: 35, color: 'primary.main' }} />,
		title: 'CONFIRMATION OF PAYMENT',
		description:
			'A document that provides evidence that a transaction of payment for registration to PAA has taken place.',
		endpoint: 'receipt.php'
	},
	{
		image: <CardMembershipIcon sx={{ fontSize: 35, color: 'primary.main' }} />,
		title: 'EVENT DOCUMENTS',
		description:
			'Documents issued by PAA for attendance and participation during events.',
		endpoint: 'coa_certificate.php',
		isCustom: true
	}
];

export const useDocument = () => {
	const target = 'Popup';
	const features = 'scrollbars=1, width=2500, height=1500';

	const { getData } = useAuth();
	const { id } = getData();
	const [eventObj, setEventObj] = useState({});

	const handleEventDocuments = (endpoint) => {
		const url = new URL(`${VIEW_URL}/${endpoint}`);
		const urlParams = url.searchParams;

		urlParams.append('member_id', id);
		urlParams.append('event_id', eventObj?.id);

		window.open(url.toString(), target, features);
	};

	const handleDocuments = (endpoint) => {
		const url = new URL(`${VIEW_URL}/${endpoint}?id=${id}`);
		window.open(url.toString(), target, features);
	};

	const viewDocument = (e) => {
		const isPaid = +eventObj?.is_paid;
		const newEndpoint =
			isPaid === 1 ? 'cpd_certificate.php' : 'coa_certificate.php';

		handleEventDocuments(newEndpoint);
	};

	/**
	 *  [true] - it will disable the View ID button
	 *  [false] - it will enable the View ID button
	 */
	const hasViewIdAccess = (event) => {
		const {
			event_idbadge,
			is_ended,
			is_paid,
			pay_ref_no,
			public_amt,
			private_amt
		} = event;

		if (Object.keys(event).length === 0) {
			return true;
		}

		if (is_paid !== 1 && !pay_ref_no) {
			return true;
		}

		if (!public_amt && !private_amt) {
			return is_ended ? true : false;
		}

		if (event_idbadge === 1) {
			return is_ended ? true : false;
		}

		if (event_idbadge === 0) {
			return true;
		}
	};

	const hasCopView = (event) => {
		const { is_paid, is_ended } = event;
		const hasNoEvent = Object.keys(event).length === 0;
		const hasNotPaid = is_paid === 0;

		if (hasNoEvent) {
			return true;
		}

		if (hasNotPaid || !is_ended) {
			return true;
		}

		return false;
	};

	return {
		eventObj,
		documents,
		setEventObj,
		hasCopView,
		viewDocument,
		handleDocuments,
		hasViewIdAccess,
		handleEventDocuments
	};
};
