import SignUpForm from './components/SignUpForm';
import Verification from './components/Verification';
import StepOneForm from './components/StepOneForm';
import StepTwoForm from './components/StepTwoForm';
import StepThreeForm from './components/StepThreeForm';
import StepFiveForm from './components/StepFiveForm';
import ProgressSave from './components/ProgressSave';
import SuccessRegistrationPage from './components/SuccessRegistrationPage';

import NoParams from './components/NoParamsGuard';
import OnlyUnauth from 'components/OnlyUnauth';

const RegistrationRoutes = [
    {
        element:<OnlyUnauth/>,
        children:[
            {
                path:'sign-up',
                element:<SignUpForm/>
            },
            {
                path:'sign-up/verification',
                element:<Verification/>
            },
            {   
                element:<NoParams/>, // when no active params in the URI
                children:[
                    {
                        path:'/registration/form/step-one',
                        element:<StepOneForm/>
                    },
                    {
                        path:'/registration/form/step-two',
                        element:<StepTwoForm/>
                    },
                    {
                        path:'/registration/form/step-three',
                        element:<StepThreeForm/>
                    },
                    {
                        path:'/registration/form/step-five',
                        element:<StepFiveForm/>
                    },
                ]
            },
            {
                path:'/registration/progress/save',
                element:<ProgressSave/>
            },
            {
                path:'/registration/success',
                element:<SuccessRegistrationPage/>
            },
        ]
    }
];

export default RegistrationRoutes;