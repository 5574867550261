import { NotificationProvider } from "./context";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

import NotificationList from "./components/NoticationList";
import NotificationCard from "./components/NotificationCard";

const useStyles = makeStyles({
    root: {
        display: "flex",
        overflow: "hidden",
        height: "calc(100vh - 64px)" // this prevent to have vertical scroll for the whole panel
    },
    list: {
        width: "40%",
        borderRight: "1px solid #ccc",
        overflowY: "auto",
        minHeight: "calc(100vh - 64px)" // gives vertical scroll freely for the list 
    },
    view: {
        width: "70%",
        overflowY: "auto",
        display: "flex",
        justifyContent: "center",
        paddingTop: "40px",
    },
});

export default function Notifications() {
    const classes = useStyles();

    return (
        <NotificationProvider>
            <Box className={classes.root}>
                <Box className={classes.list}>
                    <NotificationList/>
                </Box>
                <NotificationCard/>
            </Box>
        </NotificationProvider>
    );
}