import { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import FieldLabel from 'components/Form/Fields/FieldLabel';

const CustomCheckbox = ({ label, labelColor, ...otherProps }) => {

    let configCheckBox = {
        ...otherProps,
        color:"primary",
        size:"small"
    }

    return (
        <Box display="flex" alignItems="center">
            <FormControlLabel
                control={<Checkbox {...configCheckBox}/>}
                label={
                    <Typography 
                        variant="body2" 
                        color={labelColor||"textSecondary"}
                    >
                        {label}
                    </Typography>
                }
            />
        </Box>
    );
}

export default function Agriculturist({ handleChange, handleDisableFields, ...props }){

    const { setFieldValue, values } = useFormikContext();
    const [state, setState] = useState({
        yes:false,
        no:false,
        retired:false,
    });

    const handleAnswer = (e) => {
        if(e.target.name === 'yes') {
            setState({ yes:true, no:false, retired:false });
        } else if(e.target.name === 'no') {
            setState({ yes:false, no:true, retired:false });
        } else if(e.target.name === 'retired') {
            setState({ yes:false, no:false, retired:true });
        } else {
            setState({ yes:true, no:false, retired:false });
        }

        setFieldValue('employcheck',parseInt(e.target.value));
        
        if(handleChange instanceof Function){
            handleChange(parseInt(e.target.value));
        }

        if(handleDisableFields instanceof Function){
            handleDisableFields(parseInt(e.target.value) === 1 ? false : true);
        }
    }

    useEffect(()=>{
        const empcheck = values['employcheck'];

        if(empcheck == 0) {
            setState({ yes:false, no:true, retired:false });
        }
        else if(empcheck == 1) {
            setState({ yes:true, no:false, retired:false });
        }
        else if(empcheck == 2) {
            setState({ yes:false, no:false, retired:true });
        }

    },[values['employcheck']]);

    return (
        <Grid container xs={12}> 
            <Grid item xs={12}>
                <FieldLabel
                    required
                    text="Current Employment"
                />
                <FieldLabel
                    text="Are you a currently employed?"
                    color="#7E7E7E"
                />
            </Grid>
            <Grid item xs={2}>
                <CustomCheckbox 
                    label="Yes" 
                    name="yes" 
                    value={1} 
                    checked={state.yes} 
                    onChange={e=>handleAnswer(e)}
                    {...props}
                />
            </Grid>
            <Grid item xs={2}>
                <CustomCheckbox 
                    label="No" 
                    name="no" 
                    value={0} 
                    checked={state.no}
                    onChange={e=>handleAnswer(e)}
                    {...props}
                />
            </Grid>
            <Grid item xs={2}>
                <CustomCheckbox 
                    label="Retired" 
                    name="retired" 
                    value={2} 
                    checked={state.retired}
                    onChange={e=>handleAnswer(e)}
                    {...props}
                />
            </Grid>
        </Grid>
    );
}