import { useNavigate } from "react-router-dom";
import { useCheckPRCRoute } from "hooks/useCheckPRCRoute";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import CardFormLayout from "components/CardLayoutForm";
import Button from "components/Form/Button";

export default function RegistrationPage(props) {

    const MEMBERS_LOGIN_ROUTE = '/login';
    const PRC_LOGIN_ROUTE = '/prc/login';

    const navigate = useNavigate();
    const { isPRCRoute } = useCheckPRCRoute();

    const handleGoLoginPage = () => {
        if(isPRCRoute){
            navigate(PRC_LOGIN_ROUTE);
        } else {
            navigate(MEMBERS_LOGIN_ROUTE);
        }
    }
    
    return (
        <CardFormLayout leftImageType={1}>
            <Box
                component="section"
                gap="20px"
                width="90%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Typography variant="h3" component="div">
                    <Box 
                        fontSize={30} 
                        fontWeight={700}
                        textAlign="center"
                        sx={{ color:'#757575' }}
                    >
                        Password Reset
                    </Box>
                </Typography>
                <Box my={3} textAlign="center">
                    <CheckCircleIcon sx={{ fontSize:60, color:'#209D50' }}/>
                </Box>
                <Box width="80%" textAlign="justify">
                    <span style={{ color:'#209D50' }}>
                        Password reset success!&nbsp;
                    </span> 
                    <span style={{ color:'#757575' }}>
                        You may now login using your new password. 
                        Click the button below to login.
                    </span> 
                </Box>
                <Box 
                    my={4}
                    display="flex"
                    justifyContent="center"
                >
                    <Button
                        width={198}
                        variant="contained"
                        onClick={() => handleGoLoginPage()}
                    >
                        Login
                    </Button>
                </Box>
            </Box>
        </CardFormLayout>
    );
}