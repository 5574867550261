import { useState, useEffect } from "react";
import { ValidationSchema } from './utils/validation';
import { useParamBase64 } from 'hooks/useParamBase64';
import { usePostRequest } from 'helper/post.api';
import { useNavigate } from "react-router-dom";
import { useCheckPRCRoute } from "hooks/useCheckPRCRoute";

import Box from '@mui/material/Box';
import Button from 'components/Form/Button';

import FormikForm from 'components/Form/Formik';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import PasswordField from 'components/Form/Fields/PasswordField';
import CardFormLayout from "components/CardLayoutForm";

export default function RegistrationPage(props) {
    
    const MEMBERS_RS_ROUTE = "/account/reset-password/success";
    const PRC_RS_ROUTE = "/prc/account/reset-password/success";

    const navigate  = useNavigate();
    const id = useParamBase64();

    const { isPRCRoute } = useCheckPRCRoute();
    const { data, setPOST } = usePostRequest('/account.php');
    const { status } = data; 

    const [form] = useState({
        action:"change_password",
        password:"",
        confirmPassword:"",
        id
    });

    // this submit value with POST request from hooks
    const handleSubmit = value => {
        const { password, confirmPassword } = value;

        if(isPRCRoute){
            setPOST({
                action:'prc_change_password',
                confirmPassword,
                password,
                id
            });
        } else {
            setPOST(value);
        }
    }

    // this will proceed to verification route
    useEffect(()=>{
        if(status === 200) {
            if(isPRCRoute){
                navigate(PRC_RS_ROUTE);
            } else {
                navigate(MEMBERS_RS_ROUTE);
            }
        }
    },[status]);

    return (
        <CardFormLayout leftImageType={2}>
            <FormikForm 
                initialValues={{...form}}
                validationSchema={ValidationSchema}
                enableReinitialize={true}
                onSubmit={values=>handleSubmit(values)}
            >   
                <Grid container>
                    <Grid item my={2} xs={12}>
                        <Typography variant="h3" component="div">
                            <Box 
                                fontSize={30} 
                                fontWeight={700}
                                textAlign="center"
                                sx={{ color:'#757575' }}
                            >
                                Password Reset
                            </Box>
                        </Typography>
                    </Grid>
                    <Grid item my={2} xs={12}>
                        <PasswordField
                            required
                            name="password"
                            label="Enter New Password"
                            placeholder="Ex: Juan2345"
                        />
                    </Grid>
                    <Grid item my={2} xs={12}>
                        <PasswordField
                            required
                            name="confirmPassword"
                            label="Re-enter New Password"
                            placeholder="Ex: Juan2345"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            pt={5}
                            display={'flex'}
                            justifyContent={'center'}
                        >
                            <Button 
                                width={198}
                                variant="contained"
                            >
                                Save
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </FormikForm>
        </CardFormLayout>
    );
}