import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useCheckPRCRoute } from "hooks/useCheckPRCRoute";
import { useMediaQuery } from "@mui/material";

import Box from "@mui/material/Box";
import Button from "components/Form/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent"

import Typography from "@mui/material/Typography";
import SendIcon from "@mui/icons-material/Send";
import logo from "../../../assets/images/logo.png";
import vectorBg from "../../../assets/images/vector-bg.png";

export default function RegistrationPage(props) {

    const MEMBERS_LOGIN_ROUTE = '/login';
    const PRC_LOGIN_ROUTE = '/prc/login';

    const navigate = useNavigate();
    const mobile = useMediaQuery('(max-width:768px)');
    const heroImg = useMediaQuery('(max-width:1000px)');
    const smLaptop = useMediaQuery('(max-width:1300px)');
    const { isPRCRoute } = useCheckPRCRoute();
    
    let cardWidth = smLaptop ?  "80%" : "60%";

    if(mobile){
        cardWidth = mobile ? "100%" : smLaptop;
    }

    if(heroImg){
        cardWidth = heroImg ? "100%" : smLaptop;
    }

    const handleGoLoginPage = () => {
        if(isPRCRoute){
            navigate(PRC_LOGIN_ROUTE);
        } else {
            navigate(MEMBERS_LOGIN_ROUTE);
        }
    }

    return (
        <Fragment>
             <Box
                height="100vh"
                display="flex" 
                flexDirection="row" 
                justifyContent="center" 
                alignItems="center"
                backgroundColor="gray"
                style={{
                    backgroundImage:`url(${vectorBg})`,
                    backgroundSize:'cover',
                    backgroundRepeat:'no-repeat'
                }}
            >     
                <Card sx={{ width:cardWidth }}>
                    <CardContent>
                        <Box 
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            height={heroImg? "100vh" : 625}
                        >
                            <Box>
                                <img
                                    src={logo}
                                    height="60vw"
                                    alt="company-logo"
                                />
                            </Box>
                            <Box>
                                <Typography variant="h3" component="div">
                                    <Box
                                        pt={5}
                                        fontSize={22}
                                        fontWeight={700}
                                        sx={{ color:'primary.main' }}
                                    >
                                        Almost there!
                                    </Box>
                                </Typography>
                            </Box>
                            <Box my={5}>
                                <SendIcon 
                                    sx={{ 
                                        transform: "rotate(317deg)", 
                                        color:'primary.main', 
                                        fontSize:40 
                                    }}
                                />
                            </Box>
                            <Box
                                width={305}
                                fontSize={18}
                                color={'#757575'}
                                textAlign="center"
                                mx={2}
                            >
                               We have sent you an email. Please check your email for the password reset link.
                            </Box>
                            <Box 
                                pt={5}
                                display={'flex'}
                                justifyContent={'center'}
                            >
                                <Button 
                                    width={198}
                                    variant="contained" 
                                    onClick={() =>handleGoLoginPage()}
                                >
                                    Finish
                                </Button>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Fragment>
    );
}