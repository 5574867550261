import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    appBar:{ 
        backgroundColor:'#FFF', 
        color:'#000',
    },
    toolBar:{
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor:'#FFF',
    }
}));