import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import isBase64 from 'is-base64';

export default function ParamRouteGuard({ route }) {

    const location = useLocation();
    const params = useLocation().search;
    const { checkIfIsInActive, checkIfIsDeclined } = useAuth();

    // when the member is not active yet
    // he or she may not able to bypass form 
    if(!checkIfIsInActive && !checkIfIsDeclined){ 
        // check if theres parameter in the url
        if(params.includes('?param=')){
            const base64ID = new URLSearchParams(params).get('param');

            if(isBase64(base64ID)){
                return <Outlet/>
            }

            return <Navigate
                replace
                to={route} 
                state={{ from: location }}
            />
        } else if(params.includes('?token=')){
            const base64ID = new URLSearchParams(params).get('token');

            if(isBase64(base64ID)){
                return <Outlet/>
            }

            return <Navigate
                replace
                to={route} 
                state={{ from: location }}
            />
        }
    }
  
    return <Navigate
        replace
        to={route} 
        state={{ from: location }}
    />
}