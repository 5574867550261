import { useState, useEffect } from "react";
import { useAuth } from "hooks/useAuth";
import ProfessionalDetailsForm from "components/Form/ProfessionalDetailsForm";

export default function StepFourForm(props) {

    const { auth:data } = useAuth();
    const [form, setForm] = useState({
        action:'step4',
        check:2,
        photo:'',
        prcno:'',
        datereg:null,
        dateexp:null,
        email:'',
        cv:'',
    });

    useEffect(()=>{
        setForm(form=>({
            ...form, 
            id:data?.id,
            prcno:data?.prc_id,
            email:data?.prc_email,
            datereg:data?.prc_reg||null,
            dateexp:data?.prc_exp||null,
            photo:data?.prc_photo,
        }));
    },[data]);

    const configProps = {
        type:1,
        initialValues:form,
        stepNext:"step-six"
    }

    return <ProfessionalDetailsForm {...configProps}/>;
}