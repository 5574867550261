import { Navigate } from "react-router-dom";

import Notification from "./Notification";
import ActivityLog from "./ActivityLog";

import Bulletin from "./Bulletin";
import Profile from "./Profile";
import Membership from "./Membership";
import Documents from "./Documents";
import EventRegistration from "./EventRegistration";

import Layout from "components/Layout";
import StepOneForm from "./Membership/components/StepOneForm";
import StepTwoForm from "./Membership/components/StepTwoForm";
import StepThreeForm from "./Membership/components/StepThreeForm";
import StepFourForm from "./Membership/components/StepFourForm";
import StepSixForm from "./Membership/components/StepSixForm";
import ProgressSave from "./Membership/components/ProgressSave";
import SuccessRenewalPage from "./Membership/components/SuccessRenewalPage";

import RequireAuth from "components/RequireAuth";
import ParamRouteGuard from "components/Router/ParamRouteGuard";
import RouteGuard from "components/Router/MembersRouteGuard";
import DeclinedGuard from "components/Router/DeclinedGuard";
import EventRegistrationGuard from "components/Router/EventRegistrationGuard";

const EVENT_REGISTRATION = {   
    path:'registration-form',

    // this determine if the eventId params is not correct
    element:<EventRegistrationGuard 
        urlParams={["event_id", "event_type_id"]}
        paramType="number"
    />,

    children:[
        {
            index:true,
            element:<EventRegistration/>,
        }
    ]
}

const Routes = [
    {   
        element:<RequireAuth/>, // this identify if the member is authenticated or not 
        children:[
            {   
                element:<Layout/>, // when no active params in the URI
                children:[
                    {   
                        path:"/",
                        // when the membership or active or not expired
                        element:<RouteGuard/>,
                        children:[
                            {
                                index:true,
                                element:<Navigate to="/main"/>
                            },
                            // when the user is not authenticated and forced to enter the route / 
                            // thus, will redirect to main. But the main will reject due to not authenticated
                            // this will force to navigate /login route
                            {
                                path:'/main',
                                element:<Bulletin/>
                            },
                            {
                                path:'/profile',
                                element:<Profile/>
                            },
                            {
                                path:'/documents',
                                element:<Documents/>
                            },
                        ],
                    },
                    {
                        path:'/',
                        element:<Navigate to="/membership"/>
                    },
                    {
                        path:'/membership',
                        element:<Membership/>,
                    },
                    {   
                        path:'notifications',
                        element:<DeclinedGuard/>,
                        children:[
                            {
                                index:true,
                                element:<Notification/>,
                            },
                            {
                                path:'activity-logs',
                                element:<ActivityLog/>
                            },
                        ]
                    },
                ],
            }
        ]
    },
    {   
        path:'membership/renewal',
        element:<RequireAuth/>,
        children:[
            {   
                path:'form',
                // this redirect to membership route when params is not present or incorrect
                element:<ParamRouteGuard route="/membership"/>,
                children:[
                    {
                        path:'step-one',
                        element:<StepOneForm/>
                    },
                    {
                        path:'step-two',
                        element:<StepTwoForm/>
                    },
                    {
                        path:'step-three',
                        element:<StepThreeForm/>
                    },
                    {
                        path:'step-four',
                        element:<StepFourForm/>
                    },
                    {
                        path:'step-six',
                        element:<StepSixForm/>
                    },
                ],
            },
            {
                path:'progress/save', 
                element:<ProgressSave/>
            },
            {
                path:'success',
                element:<SuccessRenewalPage/>
            },
        ]
    },
    {   
        path:'notifications',
        children:[
            EVENT_REGISTRATION
        ]
    },
];

export default Routes;