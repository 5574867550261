
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "hooks/useAuth";

export default function RouteGuard() {
    const location = useLocation();
    const { isAuth, checkIfIsDeclined } = useAuth();
    const isDeclined = isAuth() && checkIfIsDeclined;
    
    if(isDeclined) {
        return (
            <Navigate
                replace
                to={"/membership"}
                state={{ from: location }}
            />
        );
    }

    return <Outlet/>;
}
