import React, { useState } from "react";
import { IconButton } from "@mui/material";
import { makeStyles } from '@mui/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const useStyles = makeStyles((theme)=>({
    hoverShow: {
        position:'relative',
        '&.MuiIconButton-root': { 
            width:16,
            height:16,
        }
    },
    showIcon: {
        fontSize: 16
    }
}));

const FieldClearButton = ({ handleClick, ...otherProps }) => {

    const classes = useStyles();

    const [showPassword, setShowPassword] = useState(false);

    const handleOnClick = () => {
        if(handleClick instanceof Function){
            handleClick();
        }
        setShowPassword(!showPassword)
    }

    const configFieldShowButton = {
        ...otherProps,
        className:classes.hoverShow,
        onClick:()=>handleOnClick(),
        size:"small",
    }
    
    return (
        <IconButton {...configFieldShowButton}>
            {showPassword
              ? <VisibilityOffIcon color='primary' style={{ fontSize: 16 }} />
              : <VisibilityIcon style={{ fontSize: 16 }} />
              }
        </IconButton>
    );
}

export default FieldClearButton;