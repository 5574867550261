import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme)=> ({
    root:{
        '& .MuiSelect-icon':{
            color:theme.palette.primary.main,
        },
        'MuiPaper-root':{
            left: 667,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius:0,
            '& fieldset': {
                borderRadius:0,
            },
            '&.Mui-focused fieldset': {
                borderColor:theme.palette.primary.main,
            },
        },
    },
    disabled:{
        '& .MuiOutlinedInput-root': {
            backgroundColor:'#E0E0E0',
            '& fieldset': {
                borderColor:'#E0E0E0',
            },
            '&.Mui-focused fieldset': {
                border: "none",
                borderColor: '#E0E0E0',
                backgroundColor:'rgb(252 220 19 / 20%)',
                transition:'0.2s ease-in-out',
            },
        },
    },
    error:{
        '& .MuiOutlinedInput-root': {
            backgroundColor:'#ffeeeb',
            '& fieldset': {
                borderColor: 'red',
            }, 
            '&:hover fieldset': {
                borderColor: '#d32f2f',
            },
            '&:focused ': {
                background:'#ffeeeb',
            },
        },
    },
}));

export { useStyles };