import { useNavigate } from 'react-router-dom';
import { useNotification } from '../hook';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ReadIcon from '@mui/icons-material/LabelImportantRounded';
import UnReadIcon from '@mui/icons-material/LabelImportantTwoTone';
import Link from '@mui/material/Link';
import moment from 'moment';

const BASE_URL = process.env.REACT_APP_ATTACHMENT_BASE_URL;
const REG_FORM_URL = '/notifications/registration-form';
const ICON_CONFIG = {
    position:'relative', bottom:4, left:5
}

const handleDateTimeFormat = (date, time) => {
    const datetime = `${date} ${time}`;
    return moment(datetime).format('ddd, MMM DD, YYYY H:SS A');
}

const handleDateFormat = (datetime) => {
    return moment(datetime).format('MM/DD/YYYY HH:SS');
}

export default function NotificationCard(){
    const navigate = useNavigate();

    const { notif, notifList, isAllChecked } = useNotification();

    const handleNavigation = reglink => {
        const searchParams = new URL(reglink).search;

        navigate(`${REG_FORM_URL}${searchParams}`);
    }

    return (
        <Box padding="30px" width="70%" overflow={"scroll"}>
            {notifList.length > 0 ? 
                <Card style={{ height:'fit-content', maxWidth: 'calc(100% - 15px)' }}>
                    <CardHeader
                        avatar={<>{
                            isAllChecked
                            ? <ReadIcon sx={{ color:'#FFC24D', ...ICON_CONFIG }}/>
                            : <UnReadIcon sx={{ color:'#c3c3c3', ...ICON_CONFIG }}/>}
                            </>
                        }
                        action={
                        <Typography variant="caption">
                            {handleDateFormat(notif?.trx_datetime)}
                        </Typography>}
                        title={
                            <Typography sx={{ color:"primary.main", fontWeight:'bold' }}>
                                {notif?.e_title}
                            </Typography>
                        }
                    />
                    <Divider/>
                    <CardContent sx={{ bgcolor: '#F6FFF3', px:6 }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography sx={{ color:"primary.main", fontWeight:'bold' }}>
                                    {notif?.e_stitle}
                                </Typography> 
                            </Grid>
                            <Grid item xs={12}>
                                <Typography 
                                    component="span"
                                    variant="caption"
                                    sx={{ color: '#7E7E7E' }}
                                >
                                    {handleDateTimeFormat(notif?.e_startdate, notif?.e_starttime)}
                                </Typography>
                                <Typography component="span"> - </Typography>
                                <Typography 
                                    component="span"
                                    variant="caption"
                                    sx={{ color: '#7E7E7E' }}
                                >
                                    {handleDateTimeFormat(notif?.e_enddate, notif?.e_endtime)}
                                </Typography> 
                            </Grid>
                            <Grid item xs={12} sx={{ my:3 }}>
                                <Typography variant="body2" sx={{ color: '#7E7E7E' }}>
                                    <Box lineHeight={2}>{notif?.e_desc}</Box>
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#7E7E7E' }}>
                                    {notif?.e_speaker}
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#7E7E7E' }}>
                                    {notif?.e_location}
                                </Typography>
                                <br/>
                                {notif?.e_reglink &&
                                <Box variant="body2" sx={{ color: '#7E7E7E' }}>
                                    <p>Register Here :</p>
                                    <Link 
                                        component="button" 
                                        variant="body1"
                                        textAlign="left"
                                        style={{ wordBreak:'break-all' }}
                                        onClick={() => handleNavigation(notif?.e_reglink)}
                                    >
                                        {notif?.e_reglink}
                                    </Link>

                                    {notif?.image_attachment && <img 
                                        src={`${BASE_URL}/${notif?.image_attachment}`}
                                        style={{width: 'clamp(75%, 10vw, 2rem)'}}
                                    />}
                                </Box>}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                : <></>
            }
        </Box>
    );
}