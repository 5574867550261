import { useEffect } from 'react';
import { usePostRequest } from 'helper/post.api';
import { useParamBase64 } from 'hooks/useParamBase64';

export const useGetFormValues = () => {

    const paramID = useParamBase64();
    const { data, setPOST } = usePostRequest("/member.php", true);

    useEffect(()=>{
        setPOST({
            action:'get_details',
            id:paramID
        });
    },[]);

    return { result:data?.result, paramID };
}