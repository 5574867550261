import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePostRequest } from 'helper/post.api';
import { useAuth } from 'hooks/useAuth';
import { useGetEventId } from 'hooks/useGetEventId';
import { useSnackBarMessage, STATUS } from 'hooks/useSnackbar';
import { useUrlParams } from 'hooks/useUrlParams';
import { useMediaQuery } from '@mui/material';
import { api } from 'utils/API';

const handleFetch = async (endpoint, payload) => {
    try {
        const response = await api.post(endpoint, payload);
        return response.data;
    } catch(err) {
        console.error(err);
    }
}

const paymentGateWay = (url) => {
    window.open(
        url,
        "Popup",
        "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30"
    );
};

export const useEventRegistration = () => {
    const navigate = useNavigate();
    const eventId = useGetEventId();

    const smLaptop = useMediaQuery('(max-width:1300px)');
    const heroImg = useMediaQuery('(max-width:1000px)');
    const mobile = useMediaQuery('(max-width:768px)');
    const eventTypeId = useUrlParams("event_type_id");
   
    const { data:notification, setPOST:findNotication, initial } = usePostRequest("/notifications.php");
    const { data, setPOST } = usePostRequest("/event_registration.php");
    const { setSnackBarMessage } = useSnackBarMessage();
    const { auth, isAuth, checkIfExpired } = useAuth();

    const [errorObj, setErrorObj] = useState({});
    const [displayForm, setDisplayForm] = useState(false);
    const [hasFetched, setHasFetched] = useState(false);
    const [hasFetchedCapacity, setHasFetchedCapacity] = useState(false);

    const [eventTitle, setEventTitle] = useState("")
    const [form, setForm] = useState({
        action:"register",
        check:"",
        event_id:"",
        member_id:"",
        fname:"",
        mname:"",
        lname:"",
        profession:"",
        prc_id:"",
        prc_exp:"",
        contact:"",
        email:"",
        company_name:"",
        chapter:"",
        event_type_id: +eventTypeId,
    });
    
    let cardWidth = smLaptop ?  "80%" : "60%";

    if(mobile){
        cardWidth = mobile ? "100%" : smLaptop;
    }

    if(heroImg){
        cardWidth = heroImg ? "100%" : smLaptop;
    }

    // fetch to get the event record which based on the event id 
    useEffect(()=>{
        findNotication({ 
            "action":"find_event",
            "id":eventId
        });
    },[eventId]);

    // setting the title of the form 
    // checks the event wether it's a public or private
    useEffect(()=>{
        const { result } = notification;
        const isRegistrationEnded = result?.is_ended;
        const isPrivate = +eventTypeId === 1 && !isAuth(); // 1 - private  |  0 - public
        const isExpiredMembership = +eventTypeId === 1 && checkIfExpired;
        
        if(!result && !initial) {
            setDisplayForm(true);
            setErrorObj({
                error: true,
                message:'Sorry, the event does not exist!'
            });
        }

        if(result) {
            // set the event registration title
            setEventTitle(result?.e_title);

            // set the event type as offline or online
            setForm(form=>({
                ...form,
                event_reg_type:result?.e_typeid
            }));

            // private requires auth
            if(isExpiredMembership || isPrivate){
                navigate("/");
            }
    
            if(isRegistrationEnded){
                setErrorObj({
                    error: true,
                    message:'Sorry! Unfortunately the event registration is already closed!'
                })
            }

            setDisplayForm(true);
        }
    }, [notification, initial]);

    // reinitialize form with the user record
    useEffect(()=>{
        const result = auth;
        const {
            fname,
            mname,
            lname,
            email,
            chapter,
            prc_id,
            prc_exp,
            FOS:designation,
            agency:affiliation,
            phone:contact,
        } = result;
     
        setForm(prevForm=>({
            ...prevForm,
            fname,
            mname,
            lname,
            designation,
            affiliation,
            prc_id,
            prc_exp,
            email,
            contact,
            chapter,
            event_id:eventId,
            member_id:result?.id,
        }));
    },[auth, eventId]);

    // reinitialize form with the event registration record
    // if there's record found then it will incorporate to form state
    useEffect(()=>{
        if(auth && auth.hasOwnProperty("email") && !hasFetched){
            const expiredMember = isAuth() && !checkIfExpired;
            const nonExpiredMember = isAuth() && checkIfExpired;

            if(expiredMember || nonExpiredMember){
                (async() => {
                    const data = await handleFetch(
                        "/event_registration_record.php", {
                        member_id: auth?.id,
                        event_id: eventId,
                        email: auth?.email,
                        event_type_id: +eventTypeId
                    });

                    setHasFetched(true);

                    if(data.length > 0){
                        const {
                            email,
                            fname,
                            mname,
                            lname,
                            chapter,
                            designation,
                            affiliation,
                            prc_id:prc_id,
                            contact_no: contact,
                            prc_exp:prc_exp,
                        } = data[0];
                        
                        setForm(prevForm=>({
                            ...prevForm,
                            fname: fname ? fname : prevForm.fname,
                            mname: mname ? mname : prevForm.mname,
                            lname: lname ? lname : prevForm.lname,
                            chapter: chapter ? chapter : prevForm.chapter,
                            designation: designation ? designation : prevForm.designation,
                            affiliation: affiliation ? affiliation : prevForm.affiliation,
                            prc_id: prc_id ? prc_id : prevForm.prc_id,
                            prc_exp: prc_exp ? prc_exp : prevForm.prc_exp,
                            email: email ? email : prevForm.email,
                            contact: contact ? contact : prevForm.contact,
                        }));
                    }
                })();
            }
        }
    },[auth]);
    
    // check if the capacity is_full : 1 or 0 
    useEffect(()=>{
        if(!hasFetchedCapacity){
            (async() => {
                const data = await handleFetch(
                    "/EventCapacity.php", {
                    event_id: eventId,
                });

                setHasFetchedCapacity(true);

                if(data){
                    const { is_full } = data?.data;

                    if(is_full === 1) {
                        setDisplayForm(true);
                        setErrorObj({
                            error: true,
                            message:'Sorry, the event egistration is reached the capacity limit!'
                        });
                    }
                }
            })();
        }
    },[]);

    // this will proceed to verification route
    useEffect(()=>{
        const { status, is_free, url, is_paid } = data;
        if(status === 200 && is_free === 0 && !is_paid){
            paymentGateWay(url);
        } else if(status === 200 && is_free === 0 && is_paid) {
            navigate('/notifications'); 
        } else if(status === 200 && is_free === 1) {
            navigate('/notifications'); 
        }
    },[data]);

    // if the registration
    useEffect(()=>{
        const { status, message } = data;
        if(status === 200) {
            setSnackBarMessage(message, STATUS.SUCCESS);
        }
    },[data]);

    return {
        form,
        mobile,
        isAuth,
        setPOST,
        errorObj,
        cardWidth,
        eventTitle,
        displayForm
    }
}