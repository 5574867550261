import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root:{
        display:'flex',
        height: '100%',
        alignItems:'center',
        justifyContent:'flex-end',
        position: 'absolute',
        right: 30,
        zIndex: 999,
    }
});

export { useStyles };