import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	heading: {
		fontSize: '1.5rem',
		color: '#009900',
		textAlign: 'center'
	},
	doneBtn: {
		position: 'relative',
		top: '2rem',
		display: 'flex',
		justifyContent: 'center',
		alignSelf: 'center'
	},
	memberInfo: {
		position: 'relative',
		top: 20
	}
}));
