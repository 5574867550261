import { memo, useEffect, useState } from 'react';
import { CardActionArea } from '@mui/material';
import { api2 } from 'utils/API';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import CardImagePlaceholder from 'assets/images/card-placeholder.jpg';

const checkImageExtension = (filename = null) => {
	if (filename === null) {
		return;
	}

	const validImageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp']; // Add more extensions as needed
	const extension = filename.split('.').pop().toLowerCase();

	if (validImageExtensions.includes(extension)) {
		// File is a valid image file
		// Display it using the <img> tag:
		return <img src={filename} alt="Image" />;
	} else {
		// File is not a valid image file
		// Do something else, such as display an error message
		return <p>File is not a valid image file</p>;
	}
};

export default memo(function CardPreview({
	title,
	subject,
	link,
	attachment_link
}) {
	const [image] = useState(CardImagePlaceholder);

	return (
		<Card sx={{ maxWidth: '100%' }}>
			<CardActionArea href={link} target="_blank">
				<CardMedia
					alt="link-preview-img"
					component="img"
					image={image}
					height="300"
				/>
				<CardContent>
					<Typography gutterBottom variant="h6" component="div">
						{title}
					</Typography>
					<Typography variant="body2" color="text.secondary">
						{subject}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
});
