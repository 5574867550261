import axios from 'axios';

const attendanceAuth = localStorage.getItem('aat');

export const api = axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,
	responseType: 'json',
	xsrfHeaderName: 'X-XSRF-TOKEN',
	xsrfCookieName: 'XSRF-TOKEN',
	headers: { 'Content-Type': 'application/json', Authorization: attendanceAuth }
});
