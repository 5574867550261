import LoginForm from './Login/index';
import OnlyUnauth from 'components/OnlyUnauth';

const LoginFormRoute = [
    {
        element:<OnlyUnauth/>,
        children:[
            {
                path:'prc',
                children:[
                    {
                        path:'authentication',
                        element:<LoginForm/>
                    }
                ]
            }
        ]
    },
];

export default LoginFormRoute;