import { useState, useEffect } from "react";
import { useGetFormValues } from "hooks/useGetFormValues";
import PersonalInformationForm from "components/Form/PersonalInformationForm";

export default function StepOneForm(props) {
    
    const { result:data, paramID } = useGetFormValues();
    const [form, setForm] = useState({
        action:"step1",
        id:paramID,
        bday:null,
        age:'',
        nationality:'Filipino',
        currentaddress:'',
        curRegion:'',
        curProvince:'',
        curMunicipality:'',
        curBarangay:'',
        permanentaddress:'',
        perRegion:'',
        perProvince:'',
        perMunicipality:'',
        perBarangay:'',
        hasPermanentAddress:false
    });

    useEffect(()=>{
        const personalDetails = {
            id:data?.id,
            bdate:data?.dob||null,
            age:data?.age,
            gender:data?.gender,
            civil:data?.civil_status,
            nationality:'Filipino'
        }

        const currAddress = {
            currentaddress:data?.address,
            curRegion:data?.region,
            curProvince:data?.province,
            curMunicipality:data?.city,
            curBarangay:data?.barangay,
        }

        const perAddress = {
            permanentaddress:data?.res_address ?? "",
            perRegion:data?.res_region ?? "",
            perProvince:data?.res_province ?? "",
            perMunicipality:data?.res_city ?? "",
            perBarangay:data?.res_barangay ?? "",
        }

        const contactDetails = {
            mailing:data?.mail_option,
            gender:data?.gender,
            viber:data?.viber,
            mobile:data?.phone,
            tel:data?.landline,
        }

        setForm(form=>({ 
            ...form,
            ...personalDetails,
            ...currAddress,
            ...perAddress,
            ...contactDetails
        }));
    },[data]);

    const configProps = {
        initialValues:form,
        stepNext:"step-two"
    }

    return <PersonalInformationForm {...configProps}/>;
}