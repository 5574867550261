import { createContext, useState, useEffect } from 'react';
import { useAuth } from 'hooks/useAuth';
import { api } from 'utils/API';

export const NotificationContext = createContext();
export const NotificationProvider = ({ children }) => {

    const URL = '/notifications.php';
    const [notifList, setNotifList] = useState([]);
    const [notif, setNotif] = useState({});
    const { getData } = useAuth();

    useEffect(()=>{
        const handleLoadNotifList = async () => {
            const payload = { action:'get_events', id:getData()?.id };
            const res = await api.post(URL, payload).then(d=>d.data);
            
            if(res?.status === 200)
                await setNotifList([...res?.result]);
                await setNotif(res?.result[0]);
            if(res?.status === 400 || res?.status === 404)
                await setNotifList([]);
        }

        handleLoadNotifList();
    },[]);

    const handleSetNotif = (index) => {
        if(notifList[index]){
            setNotif(notifList[index]);
        }
    }
    
    const handleDeleteNotif = async (id) => {
        if(id && notifList?.length > 0){
            const payload = { action:'remove_events', event_id:id, member_id:getData()?.id };
            const res = await api.post(URL, payload).then(d=>d.data);
           
            if(res?.status === 200)
                await setNotifList(notifList.filter(e=>e.id !== id));
            if(res?.status === 400 || res?.status === 404)
                await setNotifList([]);
        }
    }
    
    const handleReadAllNotif = async () => {
        if(notifList?.length > 0){
            const payload = {
                event_id:0,
                action:'read_events',
                member_id:getData()?.id
            }

            const res = await api.post(URL, payload).then(d=>d.data);
            if(res?.status === 200)
               await setNotifList(notifList.map(items=>({ ...items, is_read: 1 }))); // API
               
            if(res?.status === 400 || res?.status === 404)
                await setNotifList([]);
        }
    }

    const handleReadNotif = async (id) => {
        if(id && notifList?.length > 0){
            const payload = {
                event_id:id,
                action:'read_events',
                member_id:getData()?.id
            }

            const res = await api.post(URL, payload).then(d=>d.data);

            if(res?.status === 200)
                await setNotifList(notifList?.map(items=>
                    items.id===id && items.is_read == 1 
                    ? { ...items, is_read: 0 }
                    : items.id===id && items.is_read == 0 
                    ? { ...items, is_read: 1 } 
                    : items 
                ));
            if(res?.status === 400 || res?.status === 404)
                await setNotifList([]);
        }
    }

    const handleIsAllChecked = () => {
        const listLength = notifList?.length;
        const filteredList = notifList?.filter(items=>parseInt(items.is_read) === 1);
        const notEqualToZero = listLength !== 0 && filteredList?.length !== 0;
        const hasSameLength = listLength === filteredList?.length;

        return hasSameLength && notEqualToZero;
    }

    const isAllChecked = handleIsAllChecked();

    const values = {
        notif,
        notifList, 
        isAllChecked,
        setNotif, 
        handleSetNotif, 
        handleDeleteNotif,
        handleReadNotif,
        handleReadAllNotif
    }

    return (
        <NotificationContext.Provider value={{ ...values }}>
            {children}
        </NotificationContext.Provider>
    )
}