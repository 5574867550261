import { useLocation } from "react-router-dom";

export const useGetEventId = () => {
    
    const params = useLocation().search;

    if(params.includes('?event_id=')){
        return new URLSearchParams(params).get('event_id');
    } 

    return;
}