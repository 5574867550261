import { useState, useEffect } from 'react';
import { useGetFormValues } from 'hooks/useGetFormValues';
import ProfessionalDetailsForm from 'components/Form/ProfessionalDetailsForm';

export default function StepThreeRegistrationForm(props) {

    const { result:data } = useGetFormValues();
    const [form, setForm] = useState({
        action:'step3',
        check:2,
        photo:'',
        prcno:'',
        datereg:null,
        dateexp:null,
        email:'',
        cv:'',
    });

    useEffect(()=>{
        setForm(form=>({ 
            ...form, 
            id: data?.id,
            prcno: data?.prc_id,
            email:data?.prc_email,
            datereg:data?.prc_reg||null,
            dateexp:data?.prc_exp||null,
            photo:data?.prc_photo,
        }));
    },[data]);
    
    const configProps = {
        initialValues:form,
        stepNext:"step-five"
    }

    return <ProfessionalDetailsForm {...configProps}/>;
}