import { useEffect, useCallback, Fragment } from "react";
import { useFormikContext } from "formik";
import { Formik } from "formik";

const FormikWithSetErrors = ({ children, setErrors, getValues, getErrors })  => {

    const { setErrors:setBackendErrors, values, errors } = useFormikContext();

    const memoizedSetBackendErrors = useCallback(()=>{
       setBackendErrors(setErrors);
    },[setErrors, setBackendErrors]);

    useEffect(()=>{
        if(setErrors) memoizedSetBackendErrors();
    },[memoizedSetBackendErrors, setErrors]);

    // To get values from Formik Component
    const memoizedGetValue = useCallback(()=>{
        if(getValues instanceof Function){
            getValues(values)
        }
    },[getValues,values]);

    useEffect(()=>{
        memoizedGetValue();
    },[values]);

    // To get errors from Formik Component
    const memoizedGetError = useCallback(()=>{
        if(getErrors instanceof Function){
            getErrors(errors)
        }
    },[getErrors,errors]);

    useEffect(()=>{
        memoizedGetError();
    },[errors]);

    return <Fragment>{children}</Fragment>;
}

const FormikForm = ({ children, ...otherProps }) => {
    
    return (
        <Formik {...otherProps}>
            <FormikWithSetErrors {...otherProps}>
                {children}
            </FormikWithSetErrors>
        </Formik> 
    );
}

export default FormikForm;