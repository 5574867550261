import { Fragment, useCallback, useState } from 'react';
import { useFormikContext } from 'formik';
import { useStyles } from './utils/style.js';
import clsx from 'clsx';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FieldLabel from 'components/Form/Fields/FieldLabel';

export default function AutoComplete(props) {
    const { 
        name = 'fos', 
        label, options, isReadOnly,
        isRequired, handleChange,
        disabled, ...otherProps
    } = props;

    const classes = useStyles();
    const formik = useFormikContext();

    const { setFieldValue, errors, values } = formik;
    const handleOnChange = useCallback(e=> {
        if(name && formik){
            setFieldValue(name, e.target.value);
        }
    },[name, formik, handleChange]);

    let configFieldLabel = {
        text:label,
        required:isRequired
    }

    let configTextField = {
        ...otherProps,
        name,
        options,
        freeSolo:true,
        disableClearable:true,
        required:isRequired,
        readOnly:isReadOnly,
        size:"small",
        variant:"outlined",
        id:"auto-complete-field",
        value:values[name] || '',
        onBlur:handleOnChange,
        renderInput:(params) => (
            <TextField
                {...params}
                fullWidth={true}
                hiddenLabel={true}
                InputProps={{
                    ...params.InputProps,
                    type: 'search',
                }}
                className={clsx({
                    [classes.root]:!errors[name] && !disabled,
                    [classes.error]:errors[name] && !disabled,
                    [classes.disabled]:disabled
                })}
            />
        )
    }
 
    // when there's an error on errors obj
    if(errors[name] && !disabled){
        configFieldLabel.error = true;
        configTextField.error = true;
        configTextField.helperText = errors[name];
    }

    return (
        <Fragment>
            <FieldLabel {...configFieldLabel}/>
            <Autocomplete {...configTextField}/>
        </Fragment>
    );
}
