import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

const OnlyUnauth = () => {
    const { isPrcAuth } = useAuth();
    const location = useLocation();

    if(!isPrcAuth()){
        return <Outlet/>;
    } else {
        // when the member is authenticated
        // this will redirect to route "/main"
        // else, this will redirect to route "/login"
        return <Navigate to='/prc/authenticity' state={{ from: location }} replace/>
    }
};

export default OnlyUnauth;
