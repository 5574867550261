import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme)=>({
    root: {
        backgroundColor: 'rgb(210 210 210)!important',
        "& .MuiLinearProgress-background": {
            backgroundColor: "#fff",
        },
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

export { useStyles };