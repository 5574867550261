import React, { Fragment, useState } from 'react';
import { Box, InputAdornment, TextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { useStyles, helperTextStyles } from './style';

import ErrorIcon from '@mui/icons-material/Error';
import LockIcon from '@mui/icons-material/Lock';

import PasswordMeter from './components/PasswordMeter';
import ClearButton from '../FieldClearButton';
import ShowButton from '../FieldShowButton';
import FieldLabel from '../FieldLabel';
import clsx from 'clsx';

export default function CustomPasswordField ({ 
    name, 
    label, 
    icon,
    type,
    required, 
    disabled,
    readOnly,
    ...otherProps 
}) {

    const classes = useStyles();
    const helperTextClasses = helperTextStyles();
    const formik = useFormikContext();

    const [showPassword, setShowPassword] = useState(false);
    const [fieldValue, setFieldValue] = useState(otherProps.value);
    const [field, meta] = useField(name);

    const handleChange = (value) => {
        if(otherProps.hasOwnProperty('onChange')){
            otherProps?.onChange(value);
        }

        setFieldValue(value);
    }

    const handleIconAdornment = (hasValue = false, hasError = false) => {
        let endAdornment = null;

        let startAdornment = (
            <InputAdornment position="start">
                <LockIcon sx={{ color:'primary.main', fontSize:15 }}/>
            </InputAdornment>
        )

        if(hasValue){
            endAdornment = <InputAdornment position='end'>
                <Box px={1}>
                    <ClearButton handleClick={(e) => handleChange('')} />
                </Box>
                <Box py={1}>
                    <ShowButton handleClick={(e) => setShowPassword(!showPassword)} />
                </Box>
            </InputAdornment>
        } else if (hasError){
            endAdornment = <InputAdornment position='end'>
                <ErrorIcon style={{ fontSize: 16, color: '#d32f2f' }} />
            </InputAdornment>
        }

        return { startAdornment, endAdornment };
    }

    let configTextField = {
        ...field,
        ...otherProps,
        fullWidth: true,
        hiddenLabel: true,
        value: fieldValue,
        type: showPassword ? 'text' : 'password',
        size: 'small',
        variant: 'outlined',
        autoComplete: 'new-password',
        FormHelperTextProps: { classes: helperTextClasses },
        onChange:e=>handleChange(e.target.value),

        className:clsx({
            [classes.root]:!otherProps?.error && !otherProps?.disabled,
            [classes.error]:otherProps?.error,
            [classes.disabled]:otherProps?.disabled
        }),

        InputProps: {
            startAdornment:<InputAdornment position="start">
                <LockIcon sx={{ color:'primary.main', fontSize:15 }}/>
            </InputAdornment>,
            endAdornment:(fieldValue?.length > 0) && !otherProps?.disabled && !readOnly
            ? <InputAdornment position='end'>
                <Box px={1}>
                    <ClearButton handleClick={(e) => handleChange('')} />
                </Box>
                <Box py={1}>
                    <ShowButton handleClick={(e) => setShowPassword(!showPassword)} />
                </Box>
            </InputAdornment>
            : otherProps?.errors && <InputAdornment position='end'>
                <ErrorIcon style={{ fontSize: 16, color: '#d32f2f' }} />
            </InputAdornment>,
        }
    };
    
    let configFieldLabel = {
        text:label,
        required
    }

    // Formik validation 
    // only if some params are available
    if(formik && name && !otherProps?.onChange){
        const { setFieldValue, errors, values } = formik;

        configTextField = {
            ...configTextField,
            value:values[name],
            onChange:e=>setFieldValue(name, e.target.value),
            className:clsx({
                [classes.root]:!errors[name] && !disabled,
                [classes.error]:errors[name],
                [classes.disabled]:disabled
            }),
            InputProps: {
                startAdornment:<InputAdornment position="start">
                    <LockIcon sx={{ color:'primary.main', fontSize:15 }}/>
                </InputAdornment>,
                endAdornment:values[name] && !disabled && !readOnly
                ? <InputAdornment position='end'>
                    <Box px={1}>
                        <ClearButton handleClick={(e) => setFieldValue(name,'')} />
                    </Box>
                    <Box py={1}>
                        <ShowButton handleClick={(e) => setShowPassword(!showPassword)} />
                    </Box>
                </InputAdornment>
                : errors[name] && <InputAdornment position='end'>
                    <ErrorIcon style={{ fontSize: 16, color: '#d32f2f' }} />
                </InputAdornment>,
            }
        }   

        // when there's an error on errors obj
        if(errors[name]){
            configFieldLabel.error = true;
            configTextField.error = true;
            configTextField.helperText = errors[name];
        }
    }

    return (
        <Fragment>
            <FieldLabel text={label} error={configTextField.error} required={required} />
            <TextField {...configTextField} />
            {type === 'REGISTRATION' && <PasswordMeter value={field.value} />}
        </Fragment>
    );
};