import Checkbox from '@mui/material/Checkbox';

export default function CustomCheckboxes({ handleChange, ...props }) {
    const config = {
        ...props,
        size:"small",
        sx:{ '& .MuiSvgIcon-root': { fontSize: 15 } },
    }

    return <Checkbox {...config}/>;
}
