import * as Yup from "yup";

export const ValidationSchema = Yup.object().shape({
 
    // EMPLOYMENT DETAILS 
    org:Yup.string().when('employcheck',{
        is:1,
        then:Yup.string().required('Organization/Institution is required!'),
        otherwise:Yup.string(),
    }).nullable(),

    pos:Yup.string().when('employcheck',{
        is:1,
        then:Yup.string().required('Position / Designation is required!'),
        otherwise:Yup.string().nullable(),
    }).nullable(),

    address:Yup.string().when('employcheck',{
        is:1,
        then:Yup.string().required('Office Address is required!').nullable(),
        otherwise:Yup.string().nullable(),
    }).nullable(),

    tel:Yup.string().when('employcheck',{
        is:1,
        then:Yup.string().matches(/^\d{8,10}$/,'Telephone Number must be valid!').nullable(),
        otherwise:Yup.string().nullable(),
    }).nullable(),
    
    mobile:Yup.string().when('employcheck',{
        is:1,
        then:Yup.string()
        .matches(/^(09)\d{9}$/,'Mobile Number must be valid!')
        .required('Mobile Number is required!'),
        otherwise:Yup.string().nullable(),
    }).nullable(),

    email:Yup.string().when('employcheck',{
        is:1,
        then:Yup.string()
        .required("Email Address is required")
        .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,'Email Address must be valid!')
        .nullable(),
        otherwise:Yup.string().nullable(),
    }).nullable(),

    fax:Yup.string().when('employcheck',{
        is:1,
        then:Yup.string()
        .matches(/^\d{8,11}/,'Fax Number must be valid!')
        .nullable(),
    }).nullable(),

    degree:Yup.string().when('employcheck',{
        is:1,
        then:Yup.string()
        .required('Degree is required!').nullable(),
        otherwise:Yup.string().nullable(),
    }).nullable(),

    fos:Yup.string().when('employcheck',{
        is:1,
        then:Yup.string().required('Field of Specialization is required!').nullable(),
        otherwise:Yup.string().nullable(),
    }).nullable(),

    school:Yup.string().when('employcheck',{
        is:1,
        then:Yup.string()
        .required('School / College / University is required!')
        .nullable(),
    }).nullable(),

    grad:Yup.string().when('employcheck',{
        is:1,
        then:Yup.string()
        .required('Year Graduated is required!')
        .nullable(),
    }).nullable(),
});