import React, { Fragment, useEffect, useState, memo, useMemo, useCallback } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { CustomAppBar } from './Header';
import { Outlet, useNavigate } from 'react-router-dom';
import { useStyles } from './styles/drawer';

import { ReactComponent as Profile } from '../../assets/svg/Profile.svg';
import { ReactComponent as Notification } from '../../assets/svg/Notification.svg';
import { ReactComponent as Membership } from '../../assets/svg/Membership.svg';
import { ReactComponent as Documents } from '../../assets/svg/Documents.svg';
import { ReactComponent as Logout } from '../../assets/svg/Logout.svg';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Typography from '@mui/material/Typography';
import Avatar from "components/Avatar";
import moment from 'moment';
import { useAuth } from 'hooks/useAuth';
import clsx from 'clsx'

const ListItemCollapse = memo(({ icon, path, children, disabled, closingDrawer }) => {

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    /*
    * This able to navigate other route by calling this method
    */
    const handleNavigate = (path) => navigate(path);

    /*
    * This will close the submenu when the drawer get close
    */
    const handleSubMenuState = (e) => {
        e.stopPropagation();
        setOpen(!open);
    }

    /*
    * This will close the submenu when the drawer get close
    */
    useEffect(()=>{
        if(!closingDrawer) setOpen(false);
    },[closingDrawer]);

    return (
        <Fragment>
            <ListItem 
                button
                disabled={disabled}
                onClick={e=>handleNavigate(path)}
            >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary="Notifications"/>
                <Box onClick={e=>handleSubMenuState(e)}>
                    {open ? <ExpandLess/> : <ExpandMore/>}
                </Box>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {children?.map((items, index)=>(
                        <ListItemButton key={`li-${index}`} onClick={e=>handleNavigate(items?.path)}>
                            <ListItemIcon>
                                {items?.icon}
                            </ListItemIcon>
                            <ListItemText primary={items?.text}/>
                        </ListItemButton>
                    ))}
                </List>
            </Collapse>
        </Fragment>
    );
});

export default memo(function PermanentDrawerLeft() {

    const classes  = useStyles();
    const navigate = useNavigate();
    
    const { auth, setLogout, checkIfExpired, checkIfIsInActive, checkIfIsDeclined } = useAuth();
    const [open, setOpen] = React.useState(true);
    const disabledProfile = !checkIfExpired && !checkIfIsInActive && !checkIfIsDeclined;

    const handleDisableItems = useCallback((items) => {
        if (checkIfIsInActive){
            return items?.restricted;
        }

        if (checkIfExpired){
            return items?.expired;
        }

        if(checkIfIsDeclined){
            return items?.declined;
        }

        return false;
    },[checkIfIsInActive, checkIfExpired, checkIfIsDeclined]);

    const navLink = [
        { 
            text:"Profile",
            icon:<Profile/>,
            path:"/profile", 
            restricted:false,
            expired:true,
            declined:true
        },
        {
            text:"Notifications",
            icon:<Notification/>,
            path:"/notifications",
            children:[
                {
                    text:"Activity Log",
                    path:"/notifications/activity-logs",
                },
            ],
            restricted:false,
            expired:false,
            declined:true
        },
        { 
            text:"Membership",
            icon:<Membership/>,
            path:"/membership",
            restricted:false,
            expired:false,
            declined:true
        },
        { 
            text:"Documents",
            icon:<Documents/>,
            path:"/documents",
            restricted:true,
            expired:true,
            declined:true
        }
    ];

    const handleNavigate = (path) => {
        navigate(path);
    }

    return (
        <Box sx={{ display:'flex', xs:'none',  md: 'block' }}>
            <CssBaseline/>
            <CustomAppBar/>
            <Drawer
                variant="permanent"
                anchor="left"
                className={clsx({
                    [classes.drawerOpen]:open,
                    [classes.drawerClose]:!open
                })}
            >
                <Toolbar/>
                <Divider/>
                <Box className={classes.drawerContainer}>
                    <Box className={clsx({
                        [classes.brandLogoOpen]:open,
                        [classes.brandLogoClose]:!open,
                    })}>
                        <Typography 
                            variant="body2" 
                            component="span" 
                            color="#7E7E7E"
                        >   
                            {moment().format('dddd')},&nbsp;
                        </Typography>
                        <Typography 
                            variant="body2" 
                            component="span" 
                            color="primary"
                        >   
                            {moment().format('MMMM DD, YYYY')}
                        </Typography>
                    </Box>
                    <Box
                        disabled={disabledProfile}
                        sx={{ cursor: (disabledProfile) && 'pointer' }}
                        onClick={()=>{
                            if(disabledProfile) handleNavigate('/main')
                        }}
                    >
                        <Avatar width={65} height={65} logo={auth?.photo}/>
                    </Box>
                    <Box className={clsx({
                        [classes.brandLogoOpen]:open,
                        [classes.brandLogoClose]:!open,
                    })}>
                        <Typography 
                            variant="body1"
                            component="span" 
                            color="#7E7E7E"
                        >   
                            Hi,&nbsp;
                        </Typography>
                        <Typography 
                            variant="button" 
                            component="span" 
                            color="primary"
                        >   
                            {auth?.fname}
                        </Typography>
                    </Box>
                </Box>
                <Box 
                    id="container"
                    height="100vh"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    backgroundColor="#38BE6C"
                    color="#FFF"
                    px={2}
                >
                    <List disabled={checkIfIsDeclined}>
                        {navLink.map((items, index) => {
                            if(items?.children) {
                                return (
                                    <Box
                                        py={1}
                                        key={`nav-btn-${index}`}
                                        borderBottom="1px solid #58CC85"
                                    >
                                        <ListItemCollapse
                                            path={items?.path}
                                            icon={items?.icon}
                                            children={items?.children}
                                            closingDrawer={open}
                                            disabled={handleDisableItems(items)}
                                        />
                                    </Box>
                                );
                            }

                            return (
                                <Box
                                    py={1}
                                    key={`nav-btn-${index}`}
                                    borderBottom="1px solid #58CC85"
                                >
                                    <ListItem 
                                        button
                                        key={items.text}
                                        onClick={e=>handleNavigate(items.path)}
                                        // the two boolean in scope must be false
                                        disabled={handleDisableItems(items)}
                                    >
                                        <ListItemIcon>
                                            {items.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={items.text}/>
                                    </ListItem>
                                </Box>
                            );
                        })}
                    </List>
                    <List>
                        <ListItem button onClick={()=>setLogout()}>
                            <ListItemIcon>
                                <Logout/>
                            </ListItemIcon>
                            <ListItemText primary={"Logout"} />
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
            <Box
                component="span"
                color="primary"
                className={clsx(classes.drawerButton,{
                    [classes.drawerButtonOpen]:open,
                    [classes.drawerButtonClose]:!open
                })}
                onClick={e=>setOpen(open=>open=!open)}
            >
                <PlayArrowIcon sx={{
                    transform: 'rotateY(180deg)'
                }}/>
            </Box>
             <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default' }}
            >
                <Toolbar/>
                <Outlet/>
            </Box>
        </Box>
    );
});