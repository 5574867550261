import Box from '@mui/material/Box';
import { useStyles } from './styles';
import ErrorIcon from '@mui/icons-material/Error';

const CustomErrorIcon = ({ children, hasError }) => {

    const classes = useStyles();
   
    if(!hasError) return <>{children}</>;
    
    return (
        <Box position="relative" display="flex">
            <ErrorIcon style={{ fontSize: 16, color:'#d32f2f' }}/>
        </Box>
    );
}

export default CustomErrorIcon;
