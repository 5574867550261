import { useState, useEffect } from "react";
import { ValidationSchema } from './utils/validation';
import { usePostRequest } from 'helper/post.api';
import { useNavigate } from "react-router-dom";
import { useCheckPRCRoute } from "hooks/useCheckPRCRoute";

import Box from '@mui/material/Box';
import Button from 'components/Form/Button';

import Typography from '@mui/material/Typography';
import TextField from 'components/Form/Fields/TextField';
import FormikForm from 'components/Form/Formik';
import EmailIcon from '@mui/icons-material/Email';
import CardFormLayout from "components/CardLayoutForm";

export default function RegistrationPage(props) {
    
    const MEMBERS_AV_ROUTE = "/account/verification";
    const PRC_AV_ROUTE = "/prc/account/verification";

    const navigate  = useNavigate();

    const { isPRCRoute } = useCheckPRCRoute();
    const { data, setPOST } = usePostRequest('/account.php');

    const [form] = useState({
        action:"forgot_password",
        email:"",
    });

    // this submit value with POST request from hooks
    const handleSubmit = value => {
        if(isPRCRoute){
            setPOST({
                action:'prc_forgot_password',
                email:value?.email
            });
        } else {
            setPOST(value);
        }
    }

    // this will proceed to verification route
    useEffect(()=>{
        if(data?.status === 200) {
            if(isPRCRoute){
                navigate(PRC_AV_ROUTE);
            } else {
                if(data?.email_duplicated){
                    navigate(`/account/email-checkpoint?param=${data?.params}`);
                } else {
                    navigate(MEMBERS_AV_ROUTE);
                }
            }
        }
    },[data]);

    return (
        <CardFormLayout leftImageType={2}>
            <Box py={2}> 
                <FormikForm 
                    initialValues={{...form}}
                    validationSchema={ValidationSchema}
                    enableReinitialize={true}
                    onSubmit={values=>handleSubmit(values)}
                >
                    <Typography
                        variant="h3"
                        component="div"
                    >
                        <Box 
                            fontSize={30} 
                            fontWeight={700}
                            textAlign="center"
                            sx={{ color:'#757575' }}
                        >
                            Account Recovery
                        </Box>
                    </Typography>
                    <Box my={3}>
                        <span style={{ color:'#757575' }}>
                            Please enter your enrolled email below.
                        </span> 
                        <span style={{ color:'#209D50' }}>
                            We will send a link to reset your password in your email.  
                        </span> 
                    </Box>
                    <TextField
                        required
                        name="email"
                        label="Email Address"
                        placeholder="Ex:  juandelacruz@email.com"
                        icon={<EmailIcon sx={{ color:'primary.main', fontSize:15 }}/>}
                    />
                    <Box 
                        pt={5}
                        display={'flex'}
                        justifyContent={'center'}
                    >
                        <Button 
                            width={198}
                            variant="contained"
                        >
                            Next
                        </Button>
                    </Box>
                </FormikForm>
            </Box>
        </CardFormLayout>
    );
}