import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

export default function CardFormLayout(props) {
    const { 
        children, subheader, header, subtitle, subtitleAlignment="center" 
    } = props;

    return (
        <Card sx={{ minHeight: "90vh", width:"100%" }}>
            <Box
                py={8}
                display="flex" 
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
            >
                <Box 
                    my={2}
                    fontSize={14}
                    color={'#757575'}
                    textAlign={'center'}
                >
                    {subheader}
                </Box>
                <Box 
                    mx={3}
                    fontSize={30}
                    fontWeight={700}
                    sx={{ color:'primary.main' }}
                    textAlign={'center'}
                >
                    {header}
                </Box>
                <Box
                    py={2}
                    display="flex" 
                    alignItems="center"
                    justifyContent="center"
                >
                    <Box
                        mx={3}
                        width="75%"
                        fontSize={14}
                        color="#757575"
                        textAlign={subtitleAlignment}
                    >
                        {subtitle}
                    </Box>
                </Box>
                {children}
            </Box>
        </Card>
    );
}