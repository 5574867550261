import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function BasicSelect({ label, options, value, handleChange, ...props }) {
    const [_value, setValue] = React.useState(value);
    const handleOnChange = (event) => {
        setValue(event.target.value);
        if(handleChange instanceof Function){
            handleChange(event);
        }
    };

    const config = { 
        ...props,
        size:'small',
        value:_value,
        hiddenLabel:true,
        variant:"outlined",
        id:"demo-simple-select",
        onChange:handleOnChange,
        inputProps:{ 'aria-label': 'Without label' },
    }

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <Select {...config}>
                    <MenuItem value="">
                        Select Event
                    </MenuItem>
                    {options?.map((option, index)=>(
                        <MenuItem 
                            value={option?.value}
                            key={`i-${option?.text}-${index}`}
                        >
                            {option?.text}
                        </MenuItem>
                     ))}
                </Select>
            </FormControl>
        </Box>
    );
}
