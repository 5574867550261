import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from 'components/Form/Button';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CardFormLayout from "components/CardLayoutForm";

export default function SuccessRegistrationPage(props) {

    const navigate = useNavigate();

    return (
        <CardFormLayout leftImageType={1}>
            <Box
                component="section"
                gap="20px"
                width="90%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Typography variant="h3" component="div">
                    <Box
                        fontSize={30} 
                        fontWeight={700}
                        textAlign="center"
                        sx={{ color:'#757575' }}
                    >
                        Membership Application Sent
                    </Box>
                </Typography>
                <Box
                    xs={12}
                    display="flex"
                    justifyContent="center"
                >
                    <CheckCircleIcon sx={{ fontSize:60, color:'#209D50' }}/>
                </Box>
                <Box textAlign="center">
                    <span style={{ color:'#209D50' }}>
                        Your membership application has been successfuly sent!&nbsp;
                    </span> 
                    <span style={{ color:'#757575' }}>
                        Our PAA admin will review your application.
                        We will send you an email once your application has been approved.
                    </span> 
                </Box>
                <Box 
                    py={1}
                    textAlign="center"
                    sx={{ color:'#757575' }}
                >
                    To learn more about the status of your application,
                    you may send us an email through<br/>
                    <span style={{ color:'#209D50' }}>
                        paaph.assist@gmail.com.
                    </span>
                </Box>
                <Box 
                    pt={5}
                    display={'flex'}
                    justifyContent={'center'}
                >
                    <Button 
                        width={198}
                        variant="contained" 
                        onClick={() => navigate('/login')}
                    >
                        Done
                    </Button>
                </Box>
            </Box>
        </CardFormLayout>
    );
}