import { useEffect, useState, useCallback } from 'react';
import { usePostRequest } from 'helper/post.api';
import { useFormikContext } from 'formik';
import DropdownSelect from 'components/Form/Fields/Dropdown/Select';

export default function CustomDropdown({ name, prerequisitefieldnames, label, required, ...otherProps }) {

    const { values } = useFormikContext();
    const { loading, data, setPOST } = usePostRequest('/locations.php');
    const [options, setOptions] = useState([]);

    const { region, province, municipality } = prerequisitefieldnames;

    const configDropdownSelect = {
        ...otherProps, name, label, options, required, loading,
        readOnly:true,
        label:'Barangay'
    }

    // call request when the dependency triggered
    const handlePostRequest = useCallback(()=>{
        if(values[municipality]){
            setPOST({
                action:"get_barangay",
                province:values[province],
                region:values[region],
                municipality:values[municipality]
            });
        }
    },[values[municipality]]);
    
    // this will trigger handlePostRequest 
    useEffect(()=>{
        handlePostRequest();
    },[handlePostRequest])

    // set the key value paire format 
    useEffect(()=>{
        const { data:barangays } = data || [];
        setOptions(barangays?.map(items=>({
            text: items.barangay,
            value: items.barangay
        })));
    },[data]);

    if(data.hasOwnProperty('data')) {
        configDropdownSelect.readOnly = false;
    }

    if(otherProps?.readOnly){
        configDropdownSelect.readOnly = true;
    }

    return <DropdownSelect {...configDropdownSelect}/>
}