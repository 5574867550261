import { Fragment } from 'react';
import Box from '@mui/material/Box';
import { useStyles } from './styles';

const CustomInputAdorment = ({ children, errorIcon, clearButtonIcon }) => {
    const classes = useStyles();
    return (
        <Fragment>
             <Box position="relative" display="flex">
                <Box className={classes.root}>
                   {clearButtonIcon}
                   {errorIcon}
                </Box>
                {children}
            </Box>
        </Fragment>
    );
}

export default CustomInputAdorment;
