import { useState } from "react";
import { useAuth } from "hooks/useAuth";

import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import PersonalInfo from "./components/PersonalInfo";
import ProfessionalInfo from "./components/ProfessionalInfo";
import AffillatedChapter from "./components/AffillatedChapter";
import Avatar from "components/Avatar";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box p={3}>
                {children}
            </Box>
        )}
        </div>
    );
}

export default function ProfileTabs() {
    const [value, setValue] = useState(0);
    const { auth } = useAuth();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box
            pb={10}
            component="main" 
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
        > 
            <Box py={5}>
                <Avatar height={65} width={65} logo={auth?.photo}/>
            </Box>
            <Box width="80%">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs 
                        value={value} 
                        variant="fullWidth" 
                        onChange={handleChange} 
                        aria-label="icon tabs example"
                    >
                        <Tab label="Personal Info" aria-label="phone"/>
                        <Tab label="Affiliated Reg. Chapter" aria-label="favorite"/>
                        <Tab label="Professional Info" aria-label="person"/>
                    </Tabs>
                </Box>
            </Box>
            <Box
                width="80%"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <TabPanel value={value} index={0}>
                    {value === 0 && <PersonalInfo/>}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {value === 1 && <AffillatedChapter/>}
                </TabPanel>
                <TabPanel value={value} index={2}>
                    {value === 2 && <ProfessionalInfo/>}
                </TabPanel>
            </Box>
        </Box>
    );
}