export const getFileExtension = (filename) => {
    var r = /.+\.(.+)$/.exec(filename);
    return r ? r[1] : null;
}

export const getBase64 = (file) => {
    return new Promise(function(resolve) {
        var reader = new FileReader();
        reader.onloadend = function() {
          resolve(reader.result)
        }
        reader.readAsDataURL(file);
    })
}