import { ValidationSchema } from './utils/validation';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';

import Age from 'components/Form/Age';
import CardStepLayout from "components/CardStepLayout";
import CivilStatus from 'components/Form/CivilStatus';
import DatePicker from 'components/Form/Fields/DatePicker';
import HomeAddress from 'components/Form/Address/HomeAddress';
import MailingAddress from 'components/Form/Address/MailingAddress';
import PermanentAddress from 'components/Form/Address/PermanentAddress';
import Sex from 'components/Form/Sex';
import TextField from 'components/Form/Fields/TextField';

import FieldLabel from 'components/Form/Fields/FieldLabel';
import FormPage from 'components/Form/FormikFormPage';
import moment from 'moment';

export default function PersonalInformationForm(props) {
    // Payment Type:
    // 0 - Registration
    // 1 - Renewal
    const { type = 0, stepNext, initialValues } = props;

    const SUBHEADER = type === 0 
    ? "Step 1 of 5" 
    : "Step 1 of 6";

    const HEADER = type === 0 
    ? "Personal Information Form" 
    : "Membership Renewal";

    return (
        <FormPage
            formValues={initialValues}
            validationSchema={ValidationSchema}
            step={stepNext} // next step 
            type={type}
        >
            <CardStepLayout
                subheader={SUBHEADER}
                header={HEADER}
                subtitle={
                    <Box component="span">
                        Please fill in the details below. Fields with asterisk
                        (<span style={{ color:"red" }}>*</span>) are mandatory.
                    </Box>
                }
            >
                <Grid container xs={12} sx={{ py:5, px:'10%'}}>
                    <Grid item container xs={12} spacing={2} direction="row">
                        <Grid item xs={12} sm={6} md={4}>
                            <DatePicker
                                maxDate={moment()}
                                label="Birth date"
                                name="bdate"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Age/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Sex
                                required
                                placeholder="Select"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <CivilStatus
                                required
                                name="civil"
                                label="Civil Status"
                                placeholder="Select"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                required
                                name="nationality"
                                label="Nationality"
                                placeholder="Input Nationality"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <HomeAddress
                            label="Current Home Address"
                            initValues={initialValues}
                            fieldNames={{
                                address:"currentaddress",
                                region:"curRegion",
                                province:"curProvince",
                                municipality:"curMunicipality",
                                barangay:"curBarangay"
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box my={1}>
                            <FieldLabel text="Permanent Address" required/>
                        </Box>
                        <Box my={1} ml={2}>
                            <PermanentAddress 
                                label="Click this box if same with Current Home Address"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <HomeAddress
                            label="Permanent Home Address"
                            initValues={initialValues?.perAddress}
                            fieldNames={{
                                address:"permanentaddress",
                                region:"perRegion",
                                province:"perProvince",
                                municipality:"perMunicipality",
                                barangay:"perBarangay"
                            }}
                        />
                    </Grid>
                    <Grid item container spacing={2} direction="row" xs={12} sx={{ py:2 }}>
                        <Grid item xs={12} sm={6} md={4}>
                            <MailingAddress
                                required
                                name="mailing"
                                label="Mailing Address"
                                placeholder="Select"
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}  spacing={2} direction="row">
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                name="tel"
                                label="Telephone Number"
                                placeholder="0212345678"
                                icon={<PhoneIphoneIcon 
                                    sx={{ color:'primary.main', fontSize:15 }}
                                />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                required
                                name="mobile"
                                label="Mobile Number"
                                placeholder="09123456789"
                                icon={<PhoneIphoneIcon 
                                    sx={{ color:'primary.main', fontSize:15 }}
                                />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                name="viber"
                                label="Viber Number"
                                placeholder="09123456789"
                                icon={<PhoneIphoneIcon 
                                    sx={{ color:'primary.main', fontSize:15 }}
                                />}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </CardStepLayout>
        </FormPage>
    );
}