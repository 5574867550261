import React from "react";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles((theme)=>({
    hoverClear: {
        position:'relative',
        '&.MuiIconButton-root': { 
            width:16,
            height:16,
            '&:hover':{
                backgroundColor:theme.palette.primary.main,
                color:'#FFF'
            },
        }
    },
    clearIcon: {
        fontSize: 16
    }
}));

const FieldClearButton = ({ handleClick, ...otherProps }) => {

    const classes = useStyles();

    const handleOnClick = () => {
        if(handleClick instanceof Function){
            handleClick();
        }
    }

    const configFieldClearButton = {
        ...otherProps,
        className:classes.hoverClear,
        onClick:()=>handleOnClick(),
        size:"small",
    }
    
    return (
        <IconButton {...configFieldClearButton}>
            <ClearIcon style={{ fontSize: 16 }}/>
        </IconButton>
    );
}

export default FieldClearButton;