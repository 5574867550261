import Button from "@mui/material/Button";
import { useFormikContext } from 'formik';

const CustomButton = ({ 
    children, 
    variant, 
    fullWidth, 
    width, 
    submitFormTypes,
    handleClick, 
    onClick,
    storeType, // temporary for PRC saving 
    bgColor,
    sx,
    ...otherProps
}) => {
    
    const formik = useFormikContext();
    
    let configButton = {
        ...otherProps,
        disableElevation:true,
        variant:variant,
        onClick,
        sx:{
            ...sx,
            borderRadius: 50,
            textTransform:'none',
            fontSize:16,
            height:35,
            width,
            backgroundColor:bgColor
        },
    }


    if(formik && !onClick){
        const { values, submitForm, setFieldValue } = formik;

        configButton.onClick = () => { 
            setFieldValue('submitFormTypes', submitFormTypes);

            if(handleClick instanceof Function) {
                if(submitFormTypes === 'PARTIAL') {
                    handleClick(values); // this pass values for 'SAVE' on registration and renewals
                }
            } else {
                submitForm();
            }
        }
    }

    if(!otherProps?.hasOwnProperty('fullWidth') && !width){
        configButton.style = { width: 128 }
    }

    return (
        <Button {...configButton}>
            {children}
        </Button>
    );
}

export default CustomButton;