import LoginForm from './index';
import OnlyUnauth from 'components/OnlyUnauth';
import OnlyUnauthPrc from 'components/OnlyUnauthPrc';

const LoginFormRoute = [
    {
        element:<OnlyUnauth/>,
        children:[
            {
                path:'/login',
                element:<LoginForm/>
            },
        ]
    },
    {
        element:<OnlyUnauthPrc/>,
        children:[
            {
                path:'/prc/authentication',
                element:<LoginForm/>
            },
        ]
    },
]

export default LoginFormRoute;