import { useEffect, useState } from 'react';
import DropdownSelect from 'components/Form/Fields/Dropdown/Select';

export default function CustomDropdown({ name="civil", required, ...otherProps }){ 

    const [options, setOptions] = useState([
        { text: "Single", value:"Single" },
        { text: "Married", value:"Married" },
        { text: "Widow", value:"Widow" },
        { text: "Legaly Separated", value:"Legaly Separated" },
    ]);

    const configDropdownSelect = {
        ...otherProps, 
        name,
        options,
        required,
        label:'Civil Status'
    }

    useEffect(()=>{
        setOptions(options?.map(items=>({ 
            text: items.text, 
            value: items.value
        })));
    },[]);
    
    return <DropdownSelect {...configDropdownSelect}/>;
}