import { useLocation, useNavigate } from "react-router-dom";

export const useFormStepNavigation = (type = 0) => {
   
    const NAVIGATE_STEP = type == 0
    ? "/registration/form"
    : "/membership/renewal/form";

    const NAVIGATE_SAVE = type == 0
    ? "/registration/progress/save"
    : "/membership/renewal/progress/save";

    const params = useLocation().search;
    const navigatePage = useNavigate();

    const navigateStep = (step, state = {}) => {
        if(step){
            if(params.includes('?param=')){
                navigatePage(`${NAVIGATE_STEP}/${step}${params}`,{state});
            } 
        }
    }

    const navigateSave = () => {
        navigatePage(NAVIGATE_SAVE);
    }

    return { navigateStep, navigateSave };
}