import { useState, useEffect } from "react";
import { useAuth } from "hooks/useAuth";
import AffiliatedRegionalChapterForm from "components/Form/AffiliatedRegionalChapterForm";

export default function StepThreeForm() {
    const { auth:data } = useAuth();
    const [form, setForm] = useState({
        action:"step3",
        photo:"",
        chapter:""
    });
    
    useEffect(()=>{
        setForm(form=>({ 
            ...form, 
            ...data,
            oldChapter: data?.chapter,
        }));
    },[data]);

    const configProps = {
        type:1,
        initialValues:form,
        stepNext:"step-four"
    }

    return <AffiliatedRegionalChapterForm 
        {...configProps}
    />;
}