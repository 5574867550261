import { useEffect, useState } from 'react';
import DropdownSelect from 'components/Form/Fields/Dropdown/Select';

export default function CustomDropdown({ name="gender", required, ...otherProps }){ 

    const [options, setOptions] = useState([
        { text: "Male", value:"Male" },
        { text: "Female", value:"Female" },
    ]);

    const configDropdownSelect = {
        ...otherProps, 
        name,
        options,
        required,
        label:'Sex'
    }

    useEffect(()=>{
        setOptions(options?.map(items=>({ 
            text: items.text, 
            value: items.value
        })));
    },[]);
    
    return <DropdownSelect {...configDropdownSelect}/>;
}