import { useCallback, useState } from "react";
import { Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNotification } from "../hook";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import ReadIcon from "@mui/icons-material/LabelImportantRounded";
import UnReadIcon from "@mui/icons-material/LabelImportantTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import MarkAsRead from "./NotifcationListCheckBox";
import moment from "moment";

const useStyles = makeStyles((theme)=>({
    header: {
        top: 0,
        zIndex: 1,
        padding: 20,
        fontWeight: "bold",
        position: "sticky",
        backgroundColor: "#f6fff3",
        borderBottom: "1px solid #ccc",
    },
    listView: {
        bottom: 0,  
        height: "100%",
        paddingBottom: 20,
        overflowY: "scroll",
        backgroundColor: "#F6FFF3",
    },
    listItem:{
        cursor:'pointer',
    },
    listItemRead:{
        cursor:'pointer',
        background:'#FFF'
    },
    listItemSelected:{
        cursor:'pointer',
        background:'#cdffc0'
    }
}));

const IconConfig = {
    position:"relative", 
    bottom:4, 
    left:5 
}

const handleDateFormat = (datetime) => {
    return moment(datetime).format("MM/DD/YYYY HH:SS");
}

export default function NotificationList(){
    const { 
        notifList,
        isAllChecked,
        handleSetNotif,
        handleDeleteNotif,
        handleReadNotif,
        handleReadAllNotif
    } = useNotification();
    
    const classes = useStyles();
    const [selected, setSelected] = useState(0);

    const listItemStyle = useCallback((index, is_read) => {
        let itemBg = classes.listItem;
        
        if(index === selected) {
            itemBg = classes.listItemSelected;
        } 
        
        else if(+is_read === 1) {
            itemBg = classes.listItemRead;
        }

        return itemBg;
    },[classes, selected]);

    return (
        <List
            component="nav"
            className={classes.listView}
            subheader={
                <Box
                    component="section"
                    className={classes.header}
                >
                    <Typography variant="h5">
                        Notifications
                    </Typography>
                    <MarkAsRead
                        checked={isAllChecked}
                        disabled={isAllChecked}
                        onChange={e=>{
                            e.stopPropagation();
                            handleReadAllNotif();
                        }}
                    />
                    <Typography variant="caption">
                        Mark all as read
                    </Typography>
                </Box>
            }
        >   
            <Divider/>
            {notifList?.map((notif, index)=>(
                <Box component="section" key={`list-item-${index}`}>
                    <ListItem 
                        disablePadding
                        className={listItemStyle(index, notif?.is_read)}
                        onClick={e=>{
                            setSelected(index);
                            handleSetNotif(index);
                        }}
                    >
                        <ListItemText
                            primary={
                                <Grid container sx={{ py:2, px:2 }}>
                                    <Grid item align="end"  xs={12}>
                                        <Typography 
                                            variant="caption" 
                                            sx={{ color:"#7E7E7E", fontWeight:'bold' }}
                                        >
                                            {handleDateFormat(notif?.trx_datetime)}
                                        </Typography> 
                                    </Grid>
                                    <Grid item container xs={12}>
                                        <Grid item xs={10}>
                                            <Box 
                                                gap={1}
                                                display="flex"
                                                flexDirection="row"
                                            >
                                                {
                                                    parseInt(notif?.is_read) === 1
                                                    ? <ReadIcon sx={{ color:'#FFC24D', ...IconConfig }}/>
                                                    : <UnReadIcon sx={{ color:'#c3c3c3', ...IconConfig }}/>
                                                }
                                                <Typography sx={{ color:"primary.main", fontWeight:'bold' }}>
                                                    {notif?.e_title}
                                                </Typography> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={2} align="end">
                                            <IconButton
                                                size="small"
                                                onClick={e=>{
                                                    e.stopPropagation();
                                                    handleDeleteNotif(notif?.id);
                                                }}
                                            >
                                                <CloseIcon sx={{ fontSize:14, color:'#7E7E7E' }}/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box display="flex" flexDirection="row">
                                            <Box sx={{ position: 'relative', bottom: 8 }}>
                                                <MarkAsRead
                                                    disabled={parseInt(notif?.is_read) === 1}
                                                    checked={parseInt(notif?.is_read) === 1} 
                                                    onChange={e=>{
                                                        e.stopPropagation();
                                                        handleReadNotif(notif?.id);
                                                    }}
                                                />
                                            </Box>
                                            <Typography sx={{ color:"#7E7E7E", fontWeight:'bold', lineHeight:2 }}>
                                                {notif?.e_stitle}
                                                <ListItemText secondary={<Box lineHeight={2}>{notif?.e_desc}</Box>}/>
                                            </Typography> 
                                        </Box>
                                    </Grid>
                                </Grid>
                            }
                        />
                    </ListItem>
                    <Divider/>
                </Box>
            ))}
        </List>
    );
}