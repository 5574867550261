import { useEffect, useState, useCallback } from 'react';
import { usePostRequest } from 'helper/post.api';
import { useFormikContext } from 'formik';
import DropdownSelect from 'components/Form/Fields/Dropdown/Select';

export default function CustomDropdown({ name, prerequisitefieldnames, label, required, ...otherProps }) {

    const { values } = useFormikContext();
    const { loading, data, setPOST } = usePostRequest('/locations.php');
    const [options, setOptions] = useState([]);

    const { region, province } = prerequisitefieldnames;

    const configDropdownSelect = {
        ...otherProps, name, label, options, required, loading,
        readOnly:true,
        label:"City / Municipality"
    }

    // call request when the dependency triggered
    const handlePostRequest = useCallback(()=>{
        if(values[province]){
            setPOST({
                action:"get_municipality",
                province:values[province],
                region:values[region]
            });
        }
    },[values[province]]);
    
    // this will trigger handlePostRequest 
    useEffect(()=>{
        handlePostRequest()
    },[handlePostRequest])

    // set the key value paire format 
    useEffect(()=>{
        const { data:municipalities } = data || [];
        
        setOptions(municipalities?.map(items=>({
            text: items.municipality,
            value: items.municipality
        })));
    },[data]);

    if(data.hasOwnProperty('data')) {
        configDropdownSelect.readOnly = false;
    }

    if(otherProps?.readOnly){
        configDropdownSelect.readOnly = true;
    }

    return <DropdownSelect {...configDropdownSelect}/>
}