import { makeStyles } from "@mui/styles";
import CardBG1 from "assets/svg/tl-card-bg.svg";
import CardBG2 from "assets/svg/br-card-bg.svg";

export const useStyle = makeStyles((theme)=>({
    avatar: {
        display: "flex",
        justifyContent: "center",
        position: "relative",
        top:"2rem",
        zIndex: 1
    },
    card: {
        position: "relative",
        top: -50,
        background: "#FFF",
        height: 600,
        [theme.breakpoints.down("sm")]:{
            width: "95%"
        },
        width: "35rem",
        borderRadius: 15,
        filter: "drop-shadow(-6px 13px 36px rgba(0, 0, 0, 0.23))",
        backgroundImage:`url(${CardBG1}), url(${CardBG2})`,
        backgroundRepeat:"no-repeat",
        backgroundSize: 200,
        backgroundPosition:"top left, bottom right"
    },
    cardTitle: {
        fontSize: "1.5rem",
        color: "#009900",
        textAlign:"center"
    },
    cardContent: {
        height: "inherit",
        color:"#5e5e5e",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
    },
    header: {
        marginTop: 10,
        color:"#FFF",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    content: {
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        flexDirection:"column",
        margin:10
    },
    container: {
        minHeight: "100vh",
        display:"flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    details : {
        width: "70%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
    },
    memberInfo: {
        position: "relative",
        top: "1rem"
    },
    doneBtn: {
        position:"relative",
        top:"2rem",
        display:"flex",
        justifyContent:"center",
        alignSelf:"center",
    },
    invalid : {
        height: "50vh",
        fontSize: "1.5rem",
        textAlign:"center",
        gap:20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
}));