import React, { Fragment } from 'react';
import { useField, useFormikContext } from 'formik';
import { useStyles } from './styles';

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import LinearProgress from '../components/LinearProgress/index';
import InputAdornment from '../components/InputAdornment/index';
import ErrorIcon from '../components/ErrorIcon/index';

import FieldClearButton from '../../FieldClearButton';
import FieldTextHelper from '../../FieldTextHelper';
import FieldLabel from '../../FieldLabel';
import clsx from 'clsx';

const CustomSelectField = React.memo(({ name, label, disabled, readOnly, ...otherProps }) => {

    const VALUE_DISABLED = otherProps?.value && !disabled;
    const classes = useStyles();
    const [, meta] = useField(name);
    const { setFieldValue, errors, values } = useFormikContext();
   
    const configSelectField = {
        ...otherProps,
        disabled,
        value: values[name] ?? null,
        size:"small",
        variant:"outlined",
        fullWidth:true,
        hiddenLabel:true,
        select:true,
        InputProps:{ readOnly },
        onChange:e=>setFieldValue(name, e.target.value),
        className:clsx({
            [classes.root]:!errors[name] && !disabled,
            [classes.root]:true,
            [classes.error]:errors[name] && !disabled,
            [classes.disabled]:disabled
        }),
    }
  
    if(meta && meta.error && meta.touched && !disabled){
        configSelectField.error = true;
    }
    
    return (
        <Fragment>
            <FieldLabel
                text={label}
                required={configSelectField?.required}
            />
            <InputAdornment
                clearButtonIcon={
                    VALUE_DISABLED && 
                    <FieldClearButton
                        handleClick={e=>setFieldValue(name,'')}
                    />
                }
                errorIcon={<ErrorIcon hasError={errors[name] && !disabled}/>}
            >
                <TextField {...configSelectField}>
                    <MenuItem disabled selected value=""> 
                        Select Item
                    </MenuItem>
                    {configSelectField?.options?.map((item, index) =>
                        <MenuItem key={`item-${index}`} value={item.value}>
                            { item.text }
                        </MenuItem>
                    )}
                </TextField>
            </InputAdornment>
            <LinearProgress loading={configSelectField?.loading}/>
			<FieldTextHelper message={meta.error}/>
        </Fragment>
    );
})

export default CustomSelectField;