import { createContext, useState, useEffect, useCallback } from 'react';
import { api } from 'utils/API';
import Base64 from 'base-64';
import moment from 'moment';

export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {

    const token = localStorage.getItem('token');
    const prcToken = localStorage.getItem('prc_token');
    const nowDate = moment().format('YYYY-MM-DD');

    const isAuth = () => token ? true : false;
    const isPrcAuth = () => prcToken  ? true : false;

    const [auth, setAuth] = useState({
        isAuthenticated:false
    });

    const getData = () => {
        if(token){
            const decoded = Base64.decode(localStorage.getItem('token'))
            const parseData = JSON.parse(decoded);
            return parseData;
        }
    }

    const getPrcData = () => {
        if(prcToken){
            return JSON.parse(Base64.decode(prcToken));
        }
    }

    const prcData = getPrcData();

    const setLogout = () => {
        localStorage.removeItem('token');
        window.location.reload();
    }
    
    const handleRedirect = (data) => {
        const { id, is_active, exp_date } = data || {}
        const encodedId = Base64.encode(id);
        const token = Base64.encode(JSON.stringify({ id, encodedId, is_active, exp_date }));
        
        // start ============= TESTING =============
            // const encodedId = Base64.encode(6510);

            // RENEWAL 
            // const token = Base64.encode(JSON.stringify({ id:6510, encodedId, is_active:0, exp_date:'2022-04-26' }));

            // ACTIVATED
            // const token = Base64.encode(JSON.stringify({ id:6510, encodedId, is_active:1, exp_date:'2022-04-26' }));

            // ===== IN ACTIVE =====

            // NEWLY REGISTERED
            // const token = Base64.encode(JSON.stringify({ id:6510, encodedId, is_active:0, exp_date:'' }));

            // ACTIVATED BUT GOT EXPIRED
            // const token = Base64.encode(JSON.stringify({ id:6510, encodedId, is_active:1, exp_date:'022-04-25' }));

            // UNACTIVATED RENEWAL BUT GOT EXPIRED
            // const token = Base64.encode(JSON.stringify({ id:6510, encodedId, is_active:0, exp_date:'022-04-25' }));
        // end ============= TESTING =============

        if(localStorage.getItem('token')){
            localStorage.setItem('token', token);
        }
    }

    // this is using in updating records 
    const handleRefreshProfile =  useCallback(async() => {
        const endpoint = '/member.php';
        const payload = { action:'get_details', id:getData()?.id };
        const response = await api.post(endpoint,payload).then(e=>e.data);
        await handleRedirect(response?.result);
        await setAuth(auth=>({ ...auth, ...response?.result }));
    },[api, setAuth]);

    // const handlecheckIfIsInActive = () => {
    //     const { is_active } = getData() || {};
    //     if(getData()) {
    //         return parseInt(is_active) === 0 ? true : false;
    //     }
    // }

    const handlecheckIfIsInActive = () => {
        const { is_active, exp_date } = getData() || {};

        // to prevent in active after had renew membership
        if(parseInt(is_active) === 0 && !exp_date) {
            return true;
        } 
        
        return false;
    }

    const checkIfIsInActive = handlecheckIfIsInActive();

    
    const handlecheckIfIsDeclined = () => {
        const { is_active } = getData() || {};

        // to prevent declined user from access
        if(parseInt(is_active) === 2) {
            return true;
        } 
        
        return false;
    }

    const checkIfIsDeclined = handlecheckIfIsDeclined();

    const handleCheckIfExpired = () => {
        const { is_active, exp_date } = getData() || {};

        // when membership is activated and got expired
        if(parseInt(is_active) === 1 && nowDate >= exp_date){
            return true;
        }

        // when membership renewal doesn't activated yet and got expired
        else if(parseInt(is_active) === 0 && nowDate >= exp_date){
            return true;
        }

        return false;
    }

    const checkIfExpired = handleCheckIfExpired();

    useEffect(()=>{
        handleRefreshProfile();
        return () => setAuth({})
    },[]);

    const values = { 
        auth, 
        isAuth,
        isPrcAuth,
        setAuth,
        setLogout,
        handleRefreshProfile,
        checkIfIsInActive,
        checkIfExpired,
        checkIfIsDeclined,
        getData,
        prcData
    }

    return (
        <AuthContext.Provider value={values}>
            {children}
        </AuthContext.Provider>
    );
}