import { useStyle } from './styles';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Form/Button';
import moment from 'moment-timezone';
import Box from '@mui/material/Box';
import React from 'react';

export default function Details({ data }) {
	const classes = useStyle();
	const navigate = useNavigate();
	const { fullname, event_name, confirmed_at, pay_ref_no } = data;

	return (
		<Box>
			<Box id="title" className={classes.heading}>
				Your Attendance Has Been Recorded
			</Box>
			<Box id="member-info" className={classes.memberInfo}>
				<Box component="h1">Name : {fullname}</Box>
				<Box component="h1">Event : {event_name}</Box>
				<Box component="h1">
					Time Arrived :{' '}
					{moment.utc(confirmed_at).tz('Asia/Manila').format('hh:mm A')}
				</Box>
				<Box component="h1">
					Date : {moment(confirmed_at).format('MMM DD, YYYY')}
				</Box>
				<Box component="h1">Reference number : {pay_ref_no}</Box>
			</Box>
			<Box className={classes.doneBtn}>
				<Button width={198} variant="contained" onClick={() => navigate('/')}>
					Done
				</Button>
			</Box>
		</Box>
	);
}
