import { useEffect, useState, useCallback } from 'react';
import { usePostRequest } from 'helper/post.api';
import DropdownSelect from 'components/Form/Fields/Dropdown/Select';

export default function CustomDropdown({ name, label, required, ...otherProps }) {

    const { loading, data, setPOST } = usePostRequest('/reg_chapters.php');
    const [options, setOptions] = useState([]);

    const configDropdownSelect = {
        ...otherProps, options, required, loading,
        disabled:true,
        label:"Regional Chapter",
        name:"chapter"
    }

    // call request when the dependency triggered
    const handlePostRequest = useCallback(()=>{
        setPOST({ action:"get" });
    },[]);
    
    // this will trigger handlePostRequest 
    useEffect(()=>handlePostRequest(),[handlePostRequest])

    // set the key value paire format 
    useEffect(()=>{
        const { data:chapters } = data || [];
        setOptions(chapters?.map(items=>({
            text: items.chapter,
            value: items.chapter
        })));
    },[data]);

    if(data.hasOwnProperty('data')) {
        configDropdownSelect.disabled = false;
    }

    if(otherProps?.disabled){
        configDropdownSelect.disabled = true;
    }

    return <DropdownSelect {...configDropdownSelect}/>
}