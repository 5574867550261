import { useUrlParams } from 'hooks/useUrlParams';
import { usePostRequest } from 'helper/post.api';
import { useState, useEffect } from 'react';
import { useStyle } from './styles';
import { api } from 'utils/API.js';

import VectorBackground from 'assets/svg/vector-bg.svg';
import Message from './components/Message';
import Details from './components/Details';
import Logo from 'assets/images/logo.png';
import Login from './components/Login';
import Avatar from 'components/Avatar';
import Box from '@mui/material/Box';

const getVerification = async (setState, setLoaded) => {
	setLoaded(true);

	try {
		const res = await api.post('/VerifyToken.php').then((res) => res.data);
		setState(res?.valid);
		setLoaded(false);
	} catch (err) {}
};

export default function Attendance() {
	const classes = useStyle();
	const token = useUrlParams('token');
	const [hasEventEnded, setRegistrationEnded] = useState(false);
	const [verfiyLoading, setVerifyLoaded] = useState(true);
	const [isValid, setExpired] = useState(true);
	const [loading, setLoaded] = useState(true);
	const [form, setForm] = useState({});

	const { data, setPOST } = usePostRequest('/Attendance.php');
	const { data: notification, setPOST: findNotication } =
		usePostRequest('/notifications.php');

	useEffect(() => {
		getVerification(setExpired, setVerifyLoaded);
		document.body.style.backgroundImage = `url('${VectorBackground}')`;
		document.body.style.backgroundRepeat = 'no-repeat';
		document.body.style.backgroundSize = 'cover';
		document.body.style.minHeight = '100vh';

		if (token) {
			setPOST({
				token: encodeURIComponent(token)
			});
		}
	}, []);

	useEffect(() => {
		if (data.hasOwnProperty('data')) {
			const { event_id, is_valid } = data.data;
			setForm({ ...data.data });
			findNotication({
				action: 'find_event',
				id: event_id
			});

			if (!is_valid) setLoaded(false);
		}
	}, [data]);

	useEffect(() => {
		const { result } = notification;
		if (result) {
			const hasEventEnded = result.is_ended;
			setRegistrationEnded(false);

			if (hasEventEnded) setRegistrationEnded(true);
			setLoaded(false);
		}
	}, [notification]);

	useEffect(() => {
		if (!isValid) {
			localStorage.removeItem('aat');
		}
	}, [isValid]);

	return (
		<Box component="main">
			<Box id="container" component="section" className={classes.container}>
				<Box id="header" component="section" className={classes.header}>
					<Box id="logo" className={classes.logo}>
						<img src={Logo} alt="paa-logo" width="100px" />
					</Box>
				</Box>
				<Box id="content" component="section" className={classes.content}>
					<Box id="avatar" className={classes.avatar}>
						<Avatar
							width={150}
							height={150}
							alt="member avatar"
							logo={
								isValid && !loading && !hasEventEnded && form?.is_valid
									? form?.photo
									: ''
							}
						/>
					</Box>
					<Box id="card" className={classes.card}>
						<Box id="card-content" className={classes.cardContent}>
							{!isValid ? (
								<Login data={form} />
							) : verfiyLoading || loading ? (
								<Message text="Loading..." />
							) : (
								<Box className={classes.details}>
									{form?.is_valid && !hasEventEnded ? (
										<Details data={form} />
									) : (
										// <Login data={form} />
										<Message
											backButton
											text="Sorry! unfortunately the provided attendance is invalid!"
										/>
									)}
								</Box>
							)}
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
