import { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";

import { ValidationSchema } from "./utils/validation";
import { ReactComponent as GreenRectangle } from "../../../assets/svg/GreenRectangle.svg";
import { ReactComponent as RedRectangle } from "../../../assets/svg/RedRectangle.svg";
import { ReactComponent as UhOh } from "../../../assets/svg/UhOh.svg";
import { ReactComponent as InActive } from "../../../assets/svg/InActive.svg";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import TextField from "components/Form/Fields/TextField";
import Button from "components/Form/Button";
import FormikForm from "components/Form/Formik";
import DatePicker from "components/Form/Fields/DatePicker";
import moment from "moment";

export default function MembershipPage(props) {

    const navigate = useNavigate();

    const { auth, getData, checkIfExpired, checkIfIsInActive, checkIfIsDeclined } = useAuth();
    const { encodedId } = getData();
    
    const [noCopy] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [form, setForm] = useState({
        mail_recipient:'',
        chapter:'',
        reg_date:'',
        exp_date:'',
    });

    useEffect(()=>{
        setForm({
            mail_recipient:`${auth?.fname} ${auth?.lname}`,
            chapter:auth?.chapter,
            reg_date:auth?.reg_date,
            exp_date:auth?.exp_date,
        })
    },[auth])

    useEffect(()=>{
        if(checkIfIsDeclined === false){
            if(checkIfIsInActive === false){
                if(checkIfExpired === false) {
                    setShowDetails(true);
                }
            } 
        } 
    },[checkIfExpired, checkIfIsInActive, checkIfIsDeclined]);

    const handleGoToRenewalPage = () => {
        navigate({
            pathname:'/membership/renewal/form/step-one',
            search:`?param=${encodedId}`
        })
    }

    const newLocal = () => {
        if (auth?.has_prc === 0)
            return 'Associate Member';
        if (auth?.has_prc === 1)
            return 'Regular Member';
    };

    const getStatus = newLocal();

    return (
        <FormikForm 
            initialValues={{...form}}
            validationSchema={ValidationSchema} 
            enableReinitialize={true}
        >
            <Grid
                container 
                display="flex" 
                alignItems="center"
                justify="center"
                sx={{ pt:4, pb:12, px:'5%'}}
                xs={12}
            >   
                {!showDetails &&
                <Grid item xs={12}>
                    <Box 
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        height="60vh"
                    >
                        <Box my={5}>
                            {checkIfExpired && <UhOh/>}
                            {checkIfIsInActive && <InActive/>}
                            {checkIfIsDeclined && <UhOh/>}
                        </Box>

                        {checkIfExpired && <>
                            <Box color="#F97070" fontSize={30}>
                                Uh-oh, your membership has already expired. 
                            </Box>
                            <Box component="p" color="#7E7E7E" fontSize={15}>
                                To continue, please settle your payment of ₱ 1,500.00* for Regular Membership Renewal.
                            </Box>
                            <Box component="p" color="#7E7E7E" fontSize={15}>
                                *Convenience fee and Shipping fee not yet included
                            </Box>
                        </>}
                        
                        {checkIfIsInActive && <>
                            <Box color="#209D50" fontSize={30}>
                                Your account is pending for PAA Admin approval.
                            </Box>
                            <Box component="p" color="#7E7E7E" fontSize={15}>
                                Our PAA Admin is reviewing your application. We will send you an email once your application has been approved.
                            </Box>
                            <Box component="p" color="#7E7E7E" fontSize={15}>
                                To learn more about the status of your application, you may send us an email through paaph.assist@gmail.com.
                            </Box>
                        </>}

                        {checkIfIsDeclined && <>
                            <Box color="#F97070" fontSize={30}>
                                Your account is declined by PAA Admin.
                            </Box>
                            <Box component="p" color="#7E7E7E" fontSize={15}>
                                Our PAA Admin is declined your account, due to some issue encountered.
                            </Box>
                            <Box component="p" color="#7E7E7E" fontSize={15}>
                                To learn more about the status of your account, you may send us an email through paaph.assist@gmail.com.
                            </Box>
                        </>}

                        {checkIfExpired && <Box pt={6}>
                            <Button
                                width={198}
                                variant="contained"
                                onClick={()=>setShowDetails(true)}
                            >
                                Renew Membership
                            </Button>
                        </Box>}
                    </Box>
                </Grid>}

                {showDetails &&
                <Fragment>
                    <Grid item xs={6}>
                        <Box mt={2}>
                            {!checkIfIsInActive && checkIfExpired ? <RedRectangle/> : <GreenRectangle/>}
                            <Typography 
                                variant="h5" 
                                component="span"
                                sx={{ color: !checkIfIsInActive && checkIfExpired ? '#7E7E7E': '#7DDA66' }}
                            >
                            {!checkIfIsInActive && checkIfExpired ? 'Inactive' : getStatus}
                            </Typography>
                        </Box>
                        <Box pl={3} color="#7E7E7E">
                            Your membership is up to date!
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex">
                            <Typography 
                                variant="body1" 
                                component="span" 
                                color="#7E7E7E"
                            >
                                Member since:
                                <Box 
                                    mx={1}
                                    component="span"
                                    color={!checkIfIsInActive && checkIfExpired? '#F97070' : '#7DDA66'}
                                >
                                    { !auth?.member_since ? 'Not yet available' : moment(auth?.member_since).format('MM / DD / YYYY') }
                                </Box>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ pl:3, pt: 5 }}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <TextField
                                            readOnly
                                            name="mail_recipient"
                                            disabled={noCopy}
                                            label="Name of the Recipient"
                                            placeholder="Juan Dela Cruz"
                                        />
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            readOnly
                                            name="chapter"
                                            disabled={noCopy}
                                            label="Regional Chapter"
                                            placeholder="Member Regional Chapter"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <DatePicker
                                            readOnly
                                            name="reg_date"
                                            label="Date of Membership Registration"
                                        />
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={3}>
                                        <DatePicker
                                            readOnly
                                            name="exp_date"
                                            label="Date of Membership Expiration"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={6} color="#7E7E7E">
                            <p>Your next payment of ₱ 1,500.00* for Regular Membership Renewal is scheduled for 
                                &nbsp;{moment(form?.exp_date).subtract(7,'days').format('MM/DD/YYYY')}
                            <br/>
                            *Convenience fee and Shipping fee not yet included</p>
                        </Box>
                    </Grid>
                    <Grid item align="end" pt={5} xs={8}>
                        {!checkIfIsInActive&&
                        <Button
                            width={198}
                            variant="contained"
                            onClick={()=>handleGoToRenewalPage()}
                        >
                            Renew Membership
                        </Button>
                        }
                    </Grid>
                </Fragment>}
            </Grid>
        </FormikForm>
    );
}