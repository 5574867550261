import { useState } from 'react';
import { api } from 'utils/API.js';
import { useSnackBarMessage, STATUS } from 'hooks/useSnackbar';
export const usePostRequest = (endpoint, exception = false) => {

    const { setSnackBarMessage } = useSnackBarMessage();
    const [state, setState] = useState({
        initial: true,
        loading:false,
        success:false,
        data:{},
        errors:{
            message:""
        }
    });

    const [_endpoint] = useState(endpoint);

    const setPOST = async (payload) => {

        setState(state=>({ ...state, loading:true }));

        try {
            const res = await api.post(_endpoint, payload).then(res=>res.data);
            
            if(res?.status !== 200) {
                setState(state=>({ 
                    ...state, initial:false, loading:false, success:false
                }));

               if(!exception) throw res.message;
            } else {
                setState(state=>({
                    ...state, initial:false, loading:false, success:true, data:res
                }));
            }
        } catch (err) {
            setSnackBarMessage(err, STATUS.ERROR);
        }
    }

    return { ...state, setPOST };
}