import { useState, useEffect } from "react";
import { ValidationSchema } from './utils/validation';
import { usePostRequest } from 'helper/post.api';
import { useNavigate } from "react-router-dom";
import { useParamBase64 } from "hooks/useParamBase64";

import Box from '@mui/material/Box';
import Button from 'components/Form/Button';

import Typography from '@mui/material/Typography';
import TextField from 'components/Form/Fields/TextField';
import Grid from '@mui/material/Grid';
import FormikForm from 'components/Form/Formik';
import EmailIcon from '@mui/icons-material/Email';
import CardFormLayout from "components/CardLayoutForm";

export default function EmailCheckpoint(props) {
    
    const param = useParamBase64();
    const navigate  = useNavigate();
    
    const { data, setPOST } = usePostRequest('/email_checkpoint.php');
    const [form] = useState({
        fname:"",
        lname:"",
        mname:"",
        suffix:"",
        email:param
    });

    // this submit value with POST request from hooks
    const handleSubmit = value => {
        setPOST(value);
    }

    // this will proceed to verification route
    useEffect(()=>{
        if(data?.status === 200) {
            navigate(`/account/email-checkpoint/reset?token=${data?.token}`)
        }
    },[data]);

    return (
        <CardFormLayout leftImageType={2}>
            <FormikForm 
                initialValues={{...form}}
                validationSchema={ValidationSchema} 
                enableReinitialize={true}
                onSubmit={values=>handleSubmit(values)}
            >
                <Typography variant="h3" component="div">
                    <Box 
                        fontSize={30} 
                        fontWeight={700}
                        sx={{ color:'#757575' }}
                    >
                        Email Checkpoint
                    </Box>
                </Typography>
                <Box my={1}>
                    <span style={{ color:'#209D50' }}>
                        Oops! We have noticed that your email is already registered to duplicate accounts.
                    </span> 
                    <span style={{ color:'#757575' }}>
                        To proceed, we need to ask for the following information:  
                    </span> 
                </Box>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            name="fname"
                            label="First Name"
                            placeholder="Ex: Juan"
                            icon={<EmailIcon sx={{ color:'primary.main', fontSize:15 }}/>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            name="lname"
                            label="Surname"
                            placeholder="Ex:  Dela Cruz"
                            icon={<EmailIcon sx={{ color:'primary.main', fontSize:15 }}/>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            name="mname"
                            label="Middle Name"
                            placeholder="Ex:  Reyes"
                            icon={<EmailIcon sx={{ color:'primary.main', fontSize:15 }}/>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            name="suffix"
                            label="Suffix"
                            placeholder="Ex:  Jr., Sr., Etc"
                            icon={<EmailIcon sx={{ color:'primary.main', fontSize:15 }}/>}
                        />
                    </Grid>
                </Grid>
                <Box 
                    pt={3}
                    display={'flex'}
                    justifyContent={'center'}
                >
                    <Button 
                        width={198}
                        variant="contained"
                    >
                        Next
                    </Button>
                </Box>
            </FormikForm>
        </CardFormLayout>
    );
}