import { useDocument } from './hooks/useDocument';

import Events from 'components/Form/Events';
import Button from 'components/Form/Button';
import Box from '@mui/material/Box';

export default function Documents() {
	const {
		eventObj,
		documents,
		setEventObj,
		hasCopView,
		handleDocuments,
		hasViewIdAccess,
		viewDocument,
		handleEventDocuments
	} = useDocument();

	return (
		<Box
			id="content"
			display="grid"
			justifyContent="center"
			minHeight="calc(100vh - 64px)"
			gridTemplateColumns="repeat(auto-fit, 480px)"
		>
			{documents.map((item, index) => (
				<Box
					key={`flex-item-${index}`}
					id="flex-item"
					rowGap="10px"
					display="flex"
					flexBasis="45%"
					alignItems="center"
					flexDirection="column"
					boxSizing={'border-box'}
					padding="50px 50px"
				>
					<Box
						py={3}
						id="image"
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							border: '2px solid green',
							borderRadius: 50,
							height: 100,
							width: 100
						}}
					>
						{item.image}
					</Box>
					<Box pt={4} fontWeight="bold" textAlign="center" color="primary.main">
						{item?.title}
					</Box>
					<Box xs={12}>
						<Box fontSize={16} textAlign="center">
							{item?.description}
						</Box>
					</Box>
					<Box xs={12}>
						<Box width="300px">
							{item?.isCustom && (
								<Events handleChange={(value) => setEventObj(value)} />
							)}
						</Box>
					</Box>
					<Box display="flex" justifyContent="center" gap="15px">
						{!item?.isCustom && (
							<Button
								variant="contained"
								disabled={item?.isCustom && !eventObj.is_ended}
								onClick={() => handleDocuments(item?.endpoint)}
							>
								View
							</Button>
						)}
						{item?.isCustom && (
							<Button
								variant="contained"
								disabled={item?.isCustom && !eventObj.is_ended}
								onClick={viewDocument}
							>
								View Cert
							</Button>
						)}
						{item?.isCustom && (
							<Button
								variant="contained"
								disabled={hasViewIdAccess(eventObj)}
								onClick={() => handleEventDocuments('event_idbadge.php')}
							>
								View ID
							</Button>
						)}
						{item?.isCustom && (
							<Button
								variant="contained"
								disabled={hasCopView(eventObj)}
								onClick={() =>
									handleEventDocuments('certificate_of_participation.php')
								}
							>
								View COP
							</Button>
						)}
					</Box>
				</Box>
			))}
		</Box>
	);
}
