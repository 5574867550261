import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "components/Form/Button";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CardFormLayout from "components/CardLayoutForm";

export default function ProgressSavePage(props) {

    const navigate  = useNavigate();

    return (
        <CardFormLayout leftImageType={1}>
            <Box
                component="section"
                gap="20px"
                width="90%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Typography variant="h3" component="div">
                    <Box
                        fontSize={30} 
                        fontWeight={700}
                        textAlign="center"
                        sx={{ color:'#757575' }}
                    >
                        Registration Progress Saved
                    </Box>
                </Typography>
                <Box 
                    item
                    xs={12}
                    display="flex"
                    direction="row"
                    justifyContent="center"
                >
                    <CheckCircleIcon sx={{ fontSize:60, color:'#209D50' }}/>
                </Box>
                <Box xs={12}>
                    <Box textAlign="center">
                        <span style={{ color:'#209D50' }}>
                            Your membership application progress has been successfuly saved!&nbsp; 
                        </span> 
                        <span style={{ color:'#757575' }}>
                            To continue with your registration process,
                            you may just click the PAA email verification link.
                        </span> 
                    </Box>
                </Box>
                <Box xs={12}>
                    <Box 
                        py={1}
                        textAlign="center"
                        sx={{ color:'#757575' }}
                    >
                        If you need assistance with your registration, 
                        you may send us an email through<br/>
                        <span style={{ color:'#209D50' }}>
                            paaph.assist@gmail.com.
                        </span>
                    </Box>
                </Box>
                
                <Box xs={12}>
                    <Box 
                        pt={5}
                        display={'flex'}
                        justifyContent={'center'}
                    >
                        <Button
                            width={198}
                            variant="contained"
                            onClick={() =>navigate('/login')}
                        >
                            Done
                        </Button>
                    </Box>
                </Box>
            </Box>
        </CardFormLayout>
    );
}