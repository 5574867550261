import { useSnackBarMessage, STATUS } from 'hooks/useSnackbar';
import { Fragment, useState } from 'react';
import { useStyles } from './styles';
import { api } from 'utils/API';

import PasswordField from 'components/Form/Fields/PasswordField';
import Textfield from 'components/Form/Fields/TextField';
import ValidationSchema from './utils/validation';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import FormikForm from 'components/Form/Formik';
import Button from 'components/Form/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const getVerification = async (payload, setSnackBarMessage) => {
	try {
		const { email, password } = payload;
		const res = await api
			.post('/account.php', { action: 'attendance_login', email, password })
			.then((res) => res.data);
		localStorage.setItem('aat', res?.token);
		window.location.reload();
	} catch (err) {
		setSnackBarMessage(err.response?.data?.message, STATUS.ERROR);
	}
};

export default function LoginPage() {
	const classes = useStyles();

	const { setSnackBarMessage } = useSnackBarMessage();
	const [form] = useState({
		action: 'login',
		email: '',
		password: ''
	});

	return (
		<Fragment>
			<Grid
				container
				className={classes.container}
				sx={{ maxWidth: '370px', padding: 3 }}
			>
				<FormikForm
					validationSchema={ValidationSchema}
					initialValues={{ ...form }}
					enableReinitialize={true}
					onSubmit={(value) => {
						getVerification(
							{ action: 'attendance_login', ...value },
							setSnackBarMessage
						);
					}}
				>
					<Grid item xs={12}>
						<Typography gutterBottom variant="h6" component="div">
							<Box fontSize={18} sx={{ color: '#7E7E7E', fontWeight: 700 }}>
								Login to continue
							</Box>
						</Typography>
					</Grid>
					<Grid item xs={12} sx={{ py: 1 }}>
						<Textfield
							label="Email Address"
							name="email"
							placeholder="Ex:  juandelacruz@email.com"
							icon={<EmailIcon sx={{ color: 'primary.main', fontSize: 15 }} />}
						/>
					</Grid>
					<Grid item xs={12} sx={{ py: 1 }}>
						<PasswordField
							label="Password"
							name="password"
							placeholder="Ex: Juan2345"
						/>
					</Grid>
					<Grid item align="space-between" xs={12} sx={{ py: 1 }}>
						<Box my={1} className={classes.doneBtn}>
							<Button fullWidth variant="contained">
								Login
							</Button>
						</Box>
					</Grid>
				</FormikForm>
			</Grid>
		</Fragment>
	);
}
