import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";

const FieldTextHelper = ({ message }) =>{
    if(message) {
        return (
            <Box fontWeight={500}>
                <FormHelperText style={{ color: '#d32f2f' }}>
                    {message}
                </FormHelperText>
            </Box>
        );
    }
    return <></>;
}

export default FieldTextHelper;