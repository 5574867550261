import { Navigate } from 'react-router-dom';
import Login from './Login/router';
import Registration from './Registration/router';
import AccountRecovery from './AccountRecovery/router';
import Main from './Main/router';
import Authenticity from 'modules/Authenticity';
import RequirePrcAuth from 'components/RequirePrcAuth';
import PRC from './PRC/router';
import Attendance from './Attendance';
import UrlParamGuard from "components/Router/UrlParamGuard";

export const routes = [
    ...PRC,
    ...Login,
    ...Registration,
    ...AccountRecovery,
    ...Main,
    {
        path:'*', // when no active route found
        element:<Navigate to="/login"/>
    },
    {
        path: "attendance",
        element:<UrlParamGuard param="token"/>,
        children:[
            {   
                index:true,
                element:<Attendance/>,
            }
        ]
    },
    {
        element:<RequirePrcAuth/>,
        children:[
            {
                path:'/prc/authenticity',
                element:<Authenticity/>
            },
            {
                path:'*', // when no active route found
                element:<Navigate to="/prc/authenticity"/>
            },
        ]
    },
];
