import { Hidden } from '@mui/material';
import { makeStyles } from '@mui/styles';

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
    brandLogoClose:{
        // display:'none',
        
        visibility: 'hidden',
        opacity: 0,
    },
    brandLogoOpen:{
        // display:'block',
        // transition:theme.transitions.create('display', {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.leavingScreen,
        // }),
        display: 'block',
        opacity: 1,
        transition: 'display 2s, opacity 0.2s linear'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerButton:{
        position:'fixed',
        backgroundColor:'#209D50',
        top:140,
        zIndex:1500,
        width: 25,
        height: 25,
        color: '#FFF',
        borderRadius: '50%',
        cursor:'pointer'
    },

    drawerButtonClose:{
        left:70,
        transition: theme.transitions.create('left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    drawerButtonOpen:{
        left:225,
        transition: theme.transitions.create('left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerContainer:{
        overflow:'hidden',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        height:300,
        gap:20
    },
    drawerOpen: {
        overflowX: 'hidden',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        '& .MuiDrawer-paper': {
            overflowX: 'hidden',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }
    },
    drawerClose: {
        width: drawerWidth,
        flexShrink: 0,
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up('sm')]: {
            width:85,
        },
        '& .MuiDrawer-paper': {
            overflowX: 'hidden',
            width: 85,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        }
    },
    hideBrandLogo: {
        opacity:'0'
    },
    menuButton: {
        marginRight: 36,
    },
    navLinks:{
        overflowX: 'hidden',
        overflowY: 'auto',
        position: 'relative',
        backgroundColor:theme.palette.primary.light,
        height:'100%'
    },
    navButton:{ 
        color:"#0057AD", 
        float:'left'
    },  
    root: {
        display: 'flex',
    },
    showBrandLogo:{ 
        opacity:'1'
    },
}));