import { Fragment } from 'react';
import { makeStyles } from '@mui/styles';
import { useAuth } from 'hooks/useAuth';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from 'components/Avatar';

import HomeIcon from '@mui/icons-material/Home';
import PhoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import IdIcon from '@mui/icons-material/AssignmentInd';
import RectangleIcon from '@mui/icons-material/Rectangle';

const useStyles = makeStyles((theme)=>({
    profileContainer: {
        height: '100vh',
        width: 'inherit',
        backgroundColor:'#F8FFF2',
        position:'fixed'
    },
    detailsContainer:{
        padding:10,
        backgroundColor:'#F8FFF2',
        minHeight: 'inherit'
    },
    fullName:{
        fontSize:26,
        color:'#5F5F5F',
        textAlign:'center'
    },
    contactDatails:{
        fontSize:16,
        color:'#7E7E7E',
        textAlign:'justify',
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        gap:12,
        padding:6
    },
    avatar:{
        borderRadius:50,
        border:'2px solid gray'
    },
    regularMember:{
        color:theme.palette.primary.light
    },
    textCDPPoints:{
        color:theme.palette.primary.light
    },
    referenceId:{
        textAlign:'center',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        padding:4,
        gap:3
    },
}))

export default function SummaryProfile(){

    const classes = useStyles();
    const { auth } = useAuth();

    const getCompleteAddress = () => {
        return `
        ${auth?.res_address ?? ""}
        ${auth?.res_barangay ?? ""}
        ${auth?.res_city ?? ""}
        ${auth?.res_province ?? ""}
        ${auth?.res_region ?? ""}`
    }

    const newLocal = () => {
        if (auth?.has_prc == 0)
            return 'Associate Member';
        if (auth?.has_prc == 1)
            return 'Regular Member';
    };
    
    const getStatus = newLocal();

    return (
        <Box 
            container 
            align="center" 
            className={classes.detailsContainer}
        >
            <Box
                xs={6}
                id="header"
                display="flex"
                justifyContent="space-around"
            >
                <Box
                    mt={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <RectangleIcon sx={{ height:15, color: 'primary.light' }}/>
                    <Typography 
                        variant="body1" 
                        component="span" 
                        className={classes.regularMember}
                    >
                        {getStatus}
                    </Typography>
                </Box>
                <Box mt={2}>
                    <Typography
                        variant="body1" 
                        component="span" 
                        color="#7E7E7E"
                    >
                        CPD Points:
                    </Typography>
                    <Typography
                        variant="body1"
                        component="span"
                        className={classes.textCDPPoints}
                    >
                        &nbsp;{auth?.cpd_points}
                    </Typography>
                </Box>
            </Box>
            <Box xs={12}>
                <Box my={3} mt={8}>
                    <Avatar width={100} height={100} logo={auth?.photo}/>
                    <Box className={classes.referenceId}>
                        <IdIcon sx={{ color:'#7E7E7E', fontSize:15, marginTop: -0.5}}/>
                        <Typography
                            variant="body1" 
                            component="span" 
                            color="#7E7E7E"
                            className={classes.id}
                        >
                            {auth?.member_id}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box xs={12}>
                <Box className={classes.fullName}>
                    { `${auth?.fname ?? ''} ${auth?.lname ?? ''}` }
                </Box>
            </Box>
            <Box container sx={{ pl:3, pr:3, pt: 3}} xs={12}>
                <Box xs={12}>
                    <Box className={classes.contactDatails}>
                        <PhoneIcon sx={{ color:'primary.main', fontSize:15 }}/>
                        <Typography 
                            variant="body1" 
                            component="span" 
                            color="#7E7E7E"
                        >
                            {auth?.viber ?? 'N/A'}
                        </Typography>
                    </Box>
                </Box>
                <Box xs={12}>
                    <Box className={classes.contactDatails}>
                        <PhoneIcon sx={{ color:'primary.main', fontSize:15 }}/>
                        <Typography 
                            variant="body1" 
                            component="span" 
                            color="#7E7E7E"
                        >
                            {auth?.phone ?? 'N/A'}
                        </Typography>
                    </Box>
                </Box>
                <Box xs={12}>
                    <Box className={classes.contactDatails}>
                        <EmailIcon sx={{ color:'primary.main', fontSize:15 }}/>
                        <Typography 
                            variant="body1" 
                            component="span" 
                            color="#7E7E7E"
                        >
                            {auth?.email ?? 'N/A'}
                        </Typography>
                    </Box>
                </Box>
                <Box xs={12}>
                    <Box className={classes.contactDatails}>
                        <HomeIcon sx={{ color:'primary.main', fontSize:15, alignSelf: 'start'}}/>
                        <Typography 
                            variant="body1" 
                            component="span" 
                            color="#7E7E7E"
                        >
                            { getCompleteAddress() ?? 'N/A' }
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}