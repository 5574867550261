import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles((theme)=>({
    doneBtn: {
        position:"relative",
        top:"2rem",
        display:"flex",
        justifyContent:"center",
        alignSelf:"center",
    }
}));