import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useCheckPRCRoute = () => {
    
    const location = useLocation();
    const [isPRCRoute, setPRCRoute] = useState(false);
    const mainResource = location.pathname?.split('/')?.filter(d=>d)[0];

    useEffect(()=>{
        if(mainResource === 'prc'){
            setPRCRoute(true);
        } else {
            setPRCRoute(false);
        }
    },[mainResource]);

    return { isPRCRoute }
}