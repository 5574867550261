import { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { usePostRequest } from "helper/post.api";
import { ValidationSchema } from "./utils/validation";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";

import Button from "components/Form/Button";
import FormikForm from "components/Form/Formik";
import TextField from "components/Form/Fields/TextField";
import PasswordField from "components/Form/Fields/PasswordField";
import CardFormLayout from "components/CardLayoutForm";
import TCDP from "components/TCDP";

export default function SignUpForm() {

    const VERIFICATION_ROUTE = "/sign-up/verification";
    const navigate  = useNavigate();

    const { errors, success, setPOST } = usePostRequest("/account.php");
    const [openState, setOpenState] = useState(false);
    const [form] = useState({
        action:"register",
        fname:"",
        mname:"",
        lname:"",
        suffix:"",
        email:"",
        password:"",
    });

    const CustomLink = ({ label, handleClick }) => {
        return (
            <Link
                mx={1}
                color="primary.main"
                sx={{ cursor:'pointer' }}
                onClick={()=>handleClick()}
            >
                {label}
            </Link>
        );
    }

    // this submit value with POST request from hooks
    const handleSubmit = (value) => setPOST(value);

    // this will proceed to verification route
    useEffect(()=>{
        if(success) navigate(VERIFICATION_ROUTE);
    },[success])
    
    return (
        <Fragment>
            <TCDP 
                openDialog={openState}
                handleDialogClose={()=>{
                    setOpenState(false);
                }}
            />
            <CardFormLayout leftImageType={2}>
                <Box py={3}>
                    <FormikForm 
                        initialValues={{...form}}
                        validationSchema={ValidationSchema} 
                        enableReinitialize={true}
                        setErrors={errors}
                        onSubmit={value=>handleSubmit(value)}
                    >
                        <Grid
                            container
                            spacing={1}
                            sm={12}
                        >
                            <Grid item xs={12}>
                                <Typography variant="h3" component="div">
                                    <Box 
                                        fontSize={30} 
                                        fontWeight={700}
                                        sx={{ color:'#757575' }}
                                    >
                                        Register
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    ucFirst
                                    name="fname"
                                    label="First Name"
                                    placeholder="Ex:  Juan"
                                    icon={<PersonIcon sx={{ color:'primary.main', fontSize:15 }}/>}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    ucFirst
                                    name="lname"
                                    label="Surname"
                                    placeholder="Ex: Dela Cruz"
                                    icon={<EmailIcon sx={{ color:'primary.main', fontSize:15 }}/>}
                                />
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <TextField
                                    required
                                    ucFirst
                                    name="mname"
                                    label="Middle Name"
                                    placeholder="Ex: Reyes"
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <TextField
                                    name="suffix"
                                    label="Suffix"
                                    placeholder="Jr., Sr., Etc."
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    name="email"
                                    label="Personal Email Address"
                                    placeholder="Ex: juandelacruz@email.com"
                                    icon={<EmailIcon sx={{ color:'primary.main', fontSize:15 }}/>}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <PasswordField
                                    required
                                    label="Password"
                                    name="password"
                                    placeholder="Ex: Juan2345"
                                />
                            </Grid>
                            <Grid item xs={12} style={{ textAlign:"justify" }}>
                                By signing up, you are agreeing with the 
                                <CustomLink 
                                    label="terms and conditions of the use of the PAA webapp" 
                                    handleClick={()=>setOpenState(true)}
                                />
                                including the
                                <CustomLink
                                    label="data privacy."
                                    handleClick={()=>setOpenState(true)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    my={3}
                                    display={'flex'}
                                    justifyContent={'center'}
                                >
                                    <Button variant="contained" width={198}>
                                        Create Account
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </FormikForm>
                </Box>
            </CardFormLayout>
        </Fragment>
    );
}