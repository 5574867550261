import AccountRecovery from './components/AccountRecovery';
import Verification from './components/Verification';
import ResetForm from './components/ResetForm';
import SuccessPage from './components/SuccessPage';
import EmailCheckpoint from './components/EmailCheckpoint';
import EmailCheckpointReset from './components/EmailCheckpointReset';

import ParamRouteGuard from 'components/Router/ParamRouteGuard';
import OnlyUnauth from 'components/OnlyUnauth';
import OnlyUnauthPrc from 'components/OnlyUnauthPrc';
import { Navigate } from 'react-router-dom';

const routes = [
    {
        path:'account/recovery',
        element:<AccountRecovery/>
    },
    {
        path:'account/verification',
        element:<Verification/>,
    },
    {
        element:<ParamRouteGuard route="/login"/>,
        children:[
            {
                path:'account/email-checkpoint',
                element:<EmailCheckpoint/>
            },
            {
                path:'account/email-checkpoint/reset',
                element:<EmailCheckpointReset/>
            },
        ]
    },
    {
        path:'account/reset-password/success',
        element:<SuccessPage/>
    },
];

const AccountRecoverRoutes = [
    {
        element:<OnlyUnauth/>,
        children:[
            ...routes,
            {
                path:'account',
                element:<ParamRouteGuard route="/login"/>,
                children:[
                    {
                        path:'reset-password',
                        element:<ResetForm/>
                    }, 
                ]
            },
        ]
    },
    {
        path:'/prc',
        element:<OnlyUnauthPrc/>,
        children:[
            ...routes,
            {
                path:'account',
                element:<ParamRouteGuard route="/prc/authentication"/>,
                children:[
                    {
                        path:'reset-password',
                        element:<ResetForm/>
                    }, 
                ]
            },
            {
                path:'*',
                element:<Navigate to="/prc/authentication"/>
            }
        ]
    },
]

export default AccountRecoverRoutes;