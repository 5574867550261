import { Routes } from 'router';
import { SnackbarProvider } from 'notistack';
import SnackbarCloseButton from 'components/SnackBar/SnackbarCloseButton';

export default function App() {
    return (
		<SnackbarProvider 
			maxSnack={3} 
			action={key => <SnackbarCloseButton key={key}/>}
		>
			<Routes/>
		</SnackbarProvider>
    );
}