import { useStyles } from '../styles/AppBar';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import brandLogo from 'assets/images/logo.png';

export default function CustomAppBar({ title, children }){
    
    const classes  = useStyles();
   
    return (
        <AppBar 
            position="fixed"
            className={classes.appBar}
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
            <Toolbar className={classes.toolBar}>
                <img src={brandLogo} width={60}/>
                <Box>
                    <Typography 
                        variant="body2" 
                        component="p" 
                        color="#7E7E7E"
                    >   
                        Contact us at
                    </Typography>
                    <Typography 
                        variant="body2" 
                        component="p" 
                        color="primary"
                    >   
                        support@paaph.org
                    </Typography>
                </Box>
            </Toolbar>
        </AppBar>
    );
}