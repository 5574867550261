import { useNavigate } from 'react-router-dom';
import { ValidationSchema } from './utils/validation';
import { useEventRegistration } from './hooks/useEventRegistration';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import AffiliatedRegionalChapter from 'components/Form/AffiliatedRegionalChapter';
import Button from 'components/Form/Button';
import DatePicker from 'components/Form/Fields/DatePicker';
import FormikForm from 'components/Form/Formik';
import TextField from 'components/Form/Fields/TextField';
import logo from 'assets/images/logo.png';

export default function EventRegistration() {
	const navigate = useNavigate();
	const {
		form,
		isAuth,
		mobile,
		setPOST,
		errorObj,
		cardWidth,
		eventTitle,
		displayForm
	} = useEventRegistration();

	const authMember = isAuth();

	if (displayForm) {
		return (
			<Box
				display="flex"
				component="main"
				alignItems="center"
				justifyContent="center"
				minHeight="100vh"
				sx={{
					background:
						'radial-gradient(circle, rgba(97,255,22,1) 4%, rgba(133,247,117,1) 17%, rgba(154,210,110,1) 96%)'
				}}
			>
				<Box id="form" py={mobile ? 0 : 6} width={cardWidth} component="div">
					<FormikForm
						initialValues={form}
						enableReinitialize={true}
						validationSchema={ValidationSchema}
						onSubmit={(values) => {
							setPOST({ ...values });
						}}
					>
						<Card sx={{ width: '100%', minHeight: mobile ? '100vh' : '' }}>
							<Grid
								container
								display="flex"
								justify="center"
								alignItems="center"
								sx={{ pt: 8, pb: 12, px: '10%' }}
							>
								<Grid item container align="center" sx={{ py: 5 }}>
									<Grid item align="center" xs={12}>
										<img
											alt="company-logo"
											style={{ height: '6vh' }}
											src={logo}
										/>
									</Grid>
									<Grid item align="center" xs={12} sx={{ py: 1 }}>
										Event Registration : {eventTitle}
									</Grid>
								</Grid>
								{errorObj.error ? (
									<Grid item align="center" xs={12}>
										<Box fontSize={30} color="#585858de">
											{errorObj.message}
										</Box>
									</Grid>
								) : (
									<Grid item xs={12}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<TextField
													required
													name="fname"
													label="First Name"
													readOnly={authMember}
													placeholder="Input first name"
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													required
													name="mname"
													label="Middle Name"
													readOnly={authMember}
													placeholder="Input middle name"
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													required
													name="lname"
													label="Last Name"
													readOnly={authMember}
													placeholder="Input last name"
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													required
													name="designation"
													label="Profession"
													readOnly={authMember}
													placeholder="Input your profession"
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													name="prc_id"
													label="PRC ID Number"
													readOnly={authMember}
													placeholder="Input PRC ID Number"
												/>
											</Grid>
											<Grid item xs={12}>
												<DatePicker
													label="PRC ID Expiration"
													name="prc_exp"
													readOnly={authMember}
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													required
													name="contact"
													readOnly={authMember}
													label="Mobile Phone Number"
													placeholder="ex. 09123456789"
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													required
													name="email"
													label="Email adress"
													readOnly={authMember}
													placeholder="Input your email address"
												/>
											</Grid>

											<Grid item xs={12}>
												<TextField
													required
													name="affiliation"
													label="Company Name"
													readOnly={authMember}
													placeholder="Input company name"
												/>
											</Grid>
											<Grid item xs={12}>
												<AffiliatedRegionalChapter readOnly={authMember} />
											</Grid>
										</Grid>
									</Grid>
								)}
								<Grid item align="end" xs={12} mt={5}>
									{errorObj.error ? (
										<Button
											onClick={() => {
												isAuth() ? navigate('/notifications') : navigate('/');
											}}
											variant="contained"
											width={180}
										>
											Back to {isAuth() ? 'main' : 'login'} page
										</Button>
									) : (
										<Button variant="contained" width={180}>
											Proceed to Payment
										</Button>
									)}
								</Grid>
							</Grid>
						</Card>
					</FormikForm>
				</Box>
			</Box>
		);
	}
}
