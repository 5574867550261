import { useEffect, useState } from "react";
import { useAuth } from "hooks/useAuth";
import PersonalInformationForm from "components/Form/PersonalInformationForm";

export default function StepOneForm() {
    const { auth:data } = useAuth();
    const [form, setForm] = useState({
        action:"step1",
        bdate:null,
        nationality:'Filipino',
        hasPermanentAddress:false
    });

    useEffect(()=>{
        const personalDetails = {
            id:data?.id,
            bdate:data?.dob,
            age:data?.age,
            gender:data?.gender,
            civil:data?.civil_status,
            nationality:data?.nationality,
        };

        const currAddress = {
            currentaddress:data?.address,
            curRegion:data?.region,
            curProvince:data?.province,
            curMunicipality:data?.city,
            curBarangay:data?.barangay,
        };

        const perAddress = {
            permanentaddress:data?.res_address,
            perRegion:data?.res_region,
            perProvince:data?.res_province,
            perMunicipality:data?.res_city,
            perBarangay:data?.res_barangay,
        };

        const contactDetails = {
            mailing:data?.mail_option,
            gender:data?.gender,
            viber:data?.viber,
            mobile:data?.phone,
            tel:data?.landline,
        };

        setForm(prevForm => ({
            ...prevForm,
            ...personalDetails,
            ...currAddress,
            ...perAddress,
            ...contactDetails
        }));
    },[data]);

    const configProps = {
        type:1,
        initialValues:form,
        stepNext:"step-two"
    };

    return <PersonalInformationForm {...configProps}/>;
}