import { Navigate, Outlet, useLocation } from "react-router-dom";

export default function UrlParamGuard({ param='param' }) {

    const location = useLocation();
    const params = useLocation().search;

    // when the member is not active yet
    // he or she may not able to bypass form
    // check if theres parameter in the url
    if(params.includes(`?${param}=`)){
        return <Outlet/>;
    }
  
    return <Navigate
        replace
        to="/" 
        state={{ from: location }}
    />;
}