import { useState, useEffect } from "react";
import { ValidationSchema } from './utils/validation';
import { usePostRequest } from 'helper/post.api';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Button from 'components/Form/Button';

import Typography from '@mui/material/Typography';
import TextField from 'components/Form/Fields/TextField';
import Grid from '@mui/material/Grid';
import FormikForm from 'components/Form/Formik';
import EmailIcon from '@mui/icons-material/Email';

import CardFormLayout from "components/CardLayoutForm";

export default function EmailCheckpointReset(props) {
    
    const MEMBERS_AV_ROUTE = "/account/verification";
    const navigate = useNavigate();

    const { data, setPOST } = usePostRequest('/email_reset.php');
    const [form] = useState({
        action:"forgot_password",
        email:"",
        email2:"",
    });

    // this submit value with POST request from hooks
    const handleSubmit = value => {
        setPOST(value);
    }

    // this will proceed to verification route
    useEffect(()=>{
        if(data?.status === 200) {
            navigate(MEMBERS_AV_ROUTE);
        }
    },[data]);

    return (
        <CardFormLayout leftImageType={2}>
            <FormikForm 
                initialValues={{...form}}
                enableReinitialize={true}
                validationSchema={ValidationSchema} 
                onSubmit={values=>handleSubmit(values)}
            >
                <Typography variant="h4" component="div">
                    <Box
                        my={2}
                        fontSize={30} 
                        fontWeight={700}
                        sx={{ color:'#757575' }}
                    >
                        Email Checkpoint
                    </Box>
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            name="email"
                            label="Enter New Personal Email Address"
                            placeholder="Ex: juandelacruz@email.com"
                            icon={<EmailIcon sx={{ color:'primary.main', fontSize:15 }}/>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            name="email2"
                            label="Re-enter New Personal Email Address"
                            placeholder="Ex: juandelacruz@email.com"
                            icon={<EmailIcon sx={{ color:'primary.main', fontSize:15 }}/>}
                        />
                    </Grid>
                </Grid>
                <Box 
                    pt={5}
                    display={'flex'}
                    justifyContent={'center'}
                >
                    <Button 
                        width={198}
                        variant="contained"
                    >
                        Save
                    </Button>
                </Box>
            </FormikForm>
        </CardFormLayout>
    );
}