import IconButton from '@mui/material/IconButton';
import { Close as IconClose } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import * as React from 'react';

function SnackbarCloseButton({ key }) {
   const { closeSnackbar } = useSnackbar();

    return (
        <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <IconClose sx={{ fontSize:14, color:'#FFF' }}/>
        </IconButton>
    );
}

export default SnackbarCloseButton;