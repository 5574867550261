import { useEffect, useState, useCallback } from 'react';
import { usePostRequest } from 'helper/post.api';
import DropdownSelect from 'components/Form/Fields/Dropdown/Select';

export default function CustomDropdown({ required, ...otherProps }) {

    const { loading, data, setPOST } = usePostRequest('/degree.php');
    const [options, setOptions] = useState([]);

    const configDropdownSelect = {
        ...otherProps, options, required, loading,
        disabled:true,
        name:'degree',
        label:'Degree'
    }

    // call request when the dependency triggered
    const handlePostRequest = useCallback(()=>{
        setPOST({ action:"get" });
    },[]);
    
    // this will trigger handlePostRequest 
    useEffect(()=>{
        handlePostRequest();
    },[handlePostRequest])

    // set the key value paire format 
    useEffect(()=>{
        const { data:degrees } = data || [];
        setOptions(degrees?.map(items=>({
            text: items.degree,
            value: items.degree
        })));
    },[data]);

    if(data.hasOwnProperty('data')) {
        configDropdownSelect.disabled = false;
    }

    if(otherProps?.disabled){
        configDropdownSelect.disabled = true;
    }

    return <DropdownSelect {...configDropdownSelect}/>
}