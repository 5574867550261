import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme)=>({
    root:{
        "&.MuiFormHelperText-root.Mui-error" :{
            color: '#d32f2f!important',
         },
        '& .MuiOutlinedInput-root': {
            borderRadius:0,
            '& fieldset': {
                borderRadius:0,
            },
            '&.Mui-focused fieldset': {
                borderColor:theme.palette.primary.main,
            },
        },
    },
    disabled:{
        '& .MuiOutlinedInput-root': {
            borderRadius:0,
            backgroundColor:'#E0E0E0',
            '& fieldset': {
                borderColor:'#E0E0E0',
            },
            '&.Mui-focused fieldset': {
                border: "none",
                borderColor: '#E0E0E0',
                transition:'0.2s ease-in-out',
            },
        },
    },
    error:{ 
        '& .MuiOutlinedInput-root': {
            backgroundColor:'#ffeeeb',
            borderRadius:0,
            '& fieldset': {
                borderColor: '#d32f2f',
            }, 
            '&:hover fieldset': {
                borderColor: '#d32f2f',
            },
            '&:focused ': {
                background:'#ffeeeb',
            },
        },
    },
}));

const helperTextStyles = makeStyles((theme) => ({
    root: {
        margin: 4,
        color: "black"
    },
    error: {
        "&.MuiFormHelperText-root.Mui-error": {
            color: "#d32f2f"
        }
    }
}));

export { useStyles, helperTextStyles };