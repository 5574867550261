import {  useEffect } from 'react';
import { useFormikContext } from 'formik';
import CheckBox from 'components/Form/CheckBox';

export default function PermanentAddress({ readOnly  }){

    const { values, setValues } = useFormikContext();
    const { 
        currentaddress,
        permanentaddress,
        curRegion,
        perRegion,
        curProvince,
        perProvince,
        curMunicipality,
        perMunicipality,
        curBarangay,
        perBarangay,
        hasPermanentAddress
    } = values;

    useEffect(()=>{
        if(hasPermanentAddress) {
            setValues({
                ...values,
                permanentaddress:currentaddress,
                perRegion:curRegion,
                perProvince:curProvince,
                perMunicipality:curMunicipality,
                perBarangay:curBarangay,
            })
        }
    },[hasPermanentAddress]);

    const hasPermanent = () => {
        if(
            currentaddress === permanentaddress
            && curRegion === perRegion
            && curProvince === perProvince
            && curMunicipality === perMunicipality
            && curBarangay === perBarangay 
        ){
            if(
                permanentaddress?.length > 0
                && perRegion?.length > 0
                && perProvince?.length > 0
                && perMunicipality?.length > 0
                && perBarangay?.length > 0
            ) {
                return true;
            }
        }

        return false;
    }

    const isPermanent = hasPermanent();

    return (
        <CheckBox
            name="hasPermanentAddress"
            label="Click this box if same with Current Home Address"
            value={isPermanent}
            disabled={!currentaddress || !curBarangay || readOnly}
        />
    );
}
