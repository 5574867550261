import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FieldLabel from 'components/Form/Fields/FieldLabel';

const CustomCheckbox = ({ label, labelColor = "textSecondary" , size=14, ...otherProps }) => {

    let configCheckBox = {
        ...otherProps,
        color:"primary",
        size:"small",
    };

    return (
        <Box display="flex" alignItems="center">
            <FormControlLabel
                control={<Checkbox { ...configCheckBox}/>}
                label={
                    <Box 
                        color={labelColor}
                        fontSize={size}
                    >
                        {label}
                    </Box>
                }
            />
        </Box>
    );
};

export default function Agriculturist({ 
    handleChange,
    handleDisableFields,
    emailName = 'emai',
    label="Are you a professional licensed agriculturist ?",
    ...props
}) {

    const { values, setFieldValue, setValues } = useFormikContext();

    const [state, setState] = useState({
        yes:false,
        no:false,
    });

    const handleAnswer = (e) => {
        if(e.target.name === 'yes') {
            setState({ yes:true, no:false });
        } 
        
        else if(e.target.name === 'no') {
            setState({ yes:false, no:true });
            setValues(values=>({
                ...values,
                photo:"",
                prcno:"",
                datereg:null,
                dateexp:null,

                prc_regdate:null,
                prc_expdate:null,
                // email:"",
                cv:"",
            }));
        } 
        
        else {
            setState({ yes:true, no:false });
        }
        
        if(handleChange instanceof Function){
            handleChange(parseInt(e.target.value));
        }
           
        if(handleDisableFields instanceof Function){
            handleDisableFields(e.target.value !== 1 ? true : false);
        }
    };

    useEffect(()=>{
        if(values['check'] === 0) {
            setState({ yes:false, no:true });
        }
            
        else if(values['check'] === 1) {
            setState({ yes:true, no:false });
        }
    },[values['check']]);

    useEffect(()=>{
        if(values['prcno'] === "") {
            setState({ yes:false, no:true });
        }
        else if(values['prcno']) {
            setState({ yes:true, no:false });
        }
    },[values['prcno']]);
    
    return (
        <Grid container>
            <Grid item xs={12}>
                <FieldLabel
                    required={props?.required}
                    text={label}
                />
            </Grid>
            <Grid item xs={3}>
                <CustomCheckbox 
                    label="Yes"
                    name="yes"
                    value={1}
                    checked={state.yes}
                    onChange={e=>{
                        handleAnswer(e);
                        setFieldValue('check', 1);
                    }}
                    {...props}
                />
            </Grid>
            <Grid item xs={3}>
                <CustomCheckbox
                    label="No"
                    name="no"
                    value={0} 
                    checked={state.no}
                    onChange={e=>{
                        handleAnswer(e);
                        setFieldValue('check', 0);
                    }}
                    {...props}
                />
            </Grid>
        </Grid>
    );
}