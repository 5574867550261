import { makeStyles } from '@mui/styles';
import backgroundImg from '../../../assets/images/bg.jpg';

export const useStyles = makeStyles((theme)=>({
    container:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'flex-end',
        minHeight: '100vh',
        backgroundImage:`url(${backgroundImg})`,
        backgroundSize:'cover',
        backgroundRepeat:'no-repeat',
        flexWrap:'wrap',
        paddingTop: 40,
        paddingBottom: 40,
        [theme.breakpoints.down("md")]:{
            padding:0
        },
    },
    cardContainer:{
        padding: "10px 50px 10px 50px",
        [theme.breakpoints.down("sm")]:{  // to small screens ex. cellphone, tablet
            padding: "10px 20px 10px 20px",
        },
        [theme.breakpoints.down("sm")]:{  // to small screens ex. cellphone, tablet
            padding: "10px 20px 10px 20px",
        },
    },
    card:{
        width: "30%",
        minHeight:'100%',
        flexBasis:500,
        marginRight: 60,
        [theme.breakpoints.down("md")]:{
            width: "100%",
            minHeight:'100vh',
            marginRight:0,
        }
    },
    background:{
        backgroundImage:`url(${backgroundImg})`,
        backgroundSize:'cover',
        backgroundRepeat:'no-repeat',
        width:'100%',
        height:'100vh'
    },
    loginBackground: {
        width:500,
        height:600,
    },
    loginContainer: {
        overflowY: 'scroll',

        position:'relative',
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-end',
        alignItems:'right',
        top:80,
        right:80,
    },
    textLink:{
        color:theme.palette.primary.main,
        borderBottom:'1px solid', 
        cursor:'pointer',
        fontWeight:700,
    }
}))