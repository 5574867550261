import * as Yup from "yup";

export const ValidationSchema = Yup.object().shape({
    age:Yup.string().required("Age is Required")
    .test(
      "age",
      "Please choose a valid birth date",
      (age) => age >= 18
    ).nullable(),

    gender:Yup.string()
    .required('Sex is required!')
    .nullable(),

    civil:Yup.string()
    .required('Civil Status is required!')
    .nullable(),

    nationality:Yup.string()
    .required('Nationality is required!')
    .nullable(),
    
    currentaddress:Yup.string()
    .required('Address is required!')
    .nullable(),

    curRegion:Yup.string()
    .required('Region is required!')
    .nullable(),

    curProvince: Yup.string()
    .required('Province is required!')
    .nullable(),

    curMunicipality: Yup.string()
    .required('City / Municipality is required!')
    .nullable(),

    curBarangay: Yup.string()
    .required('Barangay is required!')
    .nullable(),

    permanentaddress:Yup.string()
    .when('hasPermanentAddress',{
        is:false,
        then:Yup.string()
        .required('Permanent Address is required!')
        .nullable(),
        otherwise:Yup.string(),
    }),

    perRegion:Yup.string().when('hasPermanentAddress',{
        is:false,
        then:Yup.string()
        .required('Region is required!')
        .nullable(),
        otherwise:Yup.string(),
    }),

    perProvince:Yup.string().when('hasPermanentAddress',{
        is:false,
        then:Yup.string()
        .required('Province is required!')
        .nullable(),
        otherwise:Yup.string(),
    }),

    perMunicipality:Yup.string().when('hasPermanentAddress',{
        is:false,
        then:Yup.string()
        .required('City / Municipality is required!')
        .nullable(),
        otherwise:Yup.string(),
    }),
    
    perBarangay:Yup.string().when('hasPermanentAddress',{
        is:false,
        then:Yup.string()
        .required('Barangay is required!')
        .nullable(),
        otherwise:Yup.string(),
    }),
    
    mailing: Yup.string()
    .required('Mailing Address is required!')
    .nullable(),

    tel:Yup.string()
    .matches(/^\d{8,10}$/,'Telephone Number must be valid!')
    .nullable(),

    mobile: Yup.string()
    .matches(/^(09)\d{9}$/,'Mobile Number must be valid!')
    .required('Mobile Number is required!')
    .nullable(),

    viber: Yup.string()
    .matches(/^(09)\d{9}$/,'Viber Number must be valid!')
    .nullable(),
});