import { useEffect, useState, useCallback } from 'react';
import { usePostRequest } from 'helper/post.api';
import { useFormikContext } from 'formik';
import DropdownSelect from 'components/Form/Fields/Dropdown/Select';

export default function CustomDropdown({ name="province", regionName, label, required, ...otherProps }) { 
    
    const { values } = useFormikContext();
    const { loading, data, setPOST } = usePostRequest('/locations.php');
    const [options, setOptions] = useState([]);

    const configDropdownSelect = {
        ...otherProps, name, label, options, required, loading,
        readOnly:true,
        label:'Province'
    }

    // call request when the dependency triggered
    const handlePostRequest = useCallback(()=>{
        if(values[regionName]){
            setPOST({
                action:"get_province",
                region:values[regionName]
            });
        }
    },[values[regionName]]);
    
    // this will trigger handlePostRequest 
    useEffect(()=>{
        handlePostRequest()
    },[handlePostRequest])

    // set the key value paire format 
    useEffect(()=>{
        const { data:provinces } = data || [];
        setOptions(provinces?.map(items=>({
            text: items.province,
            value: items.province
        })));
    },[data]);

    if(data.hasOwnProperty('data')) {
        configDropdownSelect.readOnly = false;
    }

    if(otherProps?.readOnly){
        configDropdownSelect.readOnly = true;
    }

    return <DropdownSelect {...configDropdownSelect}/>
}