import { useFormikContext } from "formik";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import HomeIcon from '@mui/icons-material/Home';

import Region from 'components/Form/Address/Region';
import Province from 'components/Form/Address/Province';
import Municipality from 'components/Form/Address/Municipality';
import TextField from 'components/Form/Fields/TextField';
import FieldLabel from 'components/Form/Fields/FieldLabel';
import Barangay from 'components/Form/Address/Barangay';
import ZipCode from 'components/Form/Address/Zipcode';


export default function HomeAddress({ label, disabled, readOnly, hasZipCode, fieldNames = {}}){

    const { values } = useFormikContext();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box my={1}>
                    <FieldLabel required text={label} fontSize={16}/>
                </Box>
                <TextField
                    readOnly={
                        values['hasPermanentAddress'] 
                        && fieldNames?.address === 'permanentaddress'
                        ||readOnly
                    }
                    disabled={disabled}
                    name={fieldNames?.address}
                    label="Address Line 1"
                    placeholder="Unit No./Flr./House No./Blk/Lt, Street/Purok/Sitio"
                    icon={<HomeIcon sx={{ color:'primary.main', fontSize:15 }}/>}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Region
                    readOnly={
                        values['hasPermanentAddress'] 
                        && fieldNames?.region === 'perRegion'
                        ||readOnly
                    }
                    name={fieldNames?.region}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Province
                    readOnly={
                        values['hasPermanentAddress'] 
                        && fieldNames?.province === 'perProvince'
                        ||readOnly
                    }
                    regionName={fieldNames?.region}
                    prerequisitefieldnames={fieldNames}
                    name={fieldNames?.province}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Municipality
                    readOnly={
                        values['hasPermanentAddress'] 
                        && fieldNames?.municipality === 'perMunicipality'
                        ||readOnly
                    }
                    name={fieldNames?.municipality}
                    prerequisitefieldnames={fieldNames}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Barangay
                    readOnly={
                        values['hasPermanentAddress'] 
                        && fieldNames?.barangay === 'perBarangay'
                        ||readOnly
                    }
                    name={fieldNames?.barangay}
                    prerequisitefieldnames={fieldNames}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                { hasZipCode && <ZipCode
                    readOnly={readOnly}
                    disabled={disabled}
                    placeholder="Zip Code"
                    name={fieldNames?.zipcode}
                    prerequisitefieldnames={fieldNames}
                    icon={<HomeIcon sx={{ color:'primary.main', fontSize:15 }}/>}
                />}
            </Grid>
        </Grid>
    );
}