import { Fragment, useEffect } from "react";
import { useFormikContext } from "formik";
import { useDropzone } from "react-dropzone";
import { getBase64, getFileExtension } from './utils';
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ImageViewer from "./components/ImageViewer/index";

export default function ImageUploader ({ name, disabled, readOnly, title = 'Upload Image', type = "AVATAR", ...otherProps }){
  
    const {
        acceptedFiles, getRootProps, getInputProps 
    } = useDropzone({ 
        accept: 'image/jpg, image/jpeg, image/png', 
        disabled
    });

    const mobile = useMediaQuery('(max-width:600px)');
    const formik = useFormikContext();
    const configInput = { ...getInputProps(), disabled, readOnly }
    const configProps = { name, ...otherProps }

    useEffect(()=>{
        if(acceptedFiles.length > 0){
            let file = acceptedFiles[0];
            const { setFieldValue, errors } = formik;

            getBase64(file).then(base64=>{
                configProps.value = base64;
                if(formik && name && !configProps?.onChange){
                    setFieldValue(name,base64);
                } else 
                
                if(configProps?.onChange){
                    configProps?.onChange({
                        base64,
                        contentType:file.type,
                        extension:getFileExtension(file.name)
                    });
                }
            });

            if(errors[name]) configProps.error = true;
        }
    },[acceptedFiles]);

    if(formik && name && !configProps?.onChange){
        const { errors, values } = formik;

        if(errors[name]) configProps.error = true;
        if(values[name]){
            // when the value is object with key value pair of base64
            // else, the value is a link of image 
            if(values[name]?.hasOwnProperty("base64")){
                configProps.value = values[name]?.base64;
            } else {
                configProps.value = values[name];
            }
        }
    }

    if(otherProps?.error) configProps.error = true;

    return (
        <Fragment>
            <input {...configInput}/>
            <Grid container display="flex" justify="center" direction={mobile ? "column" : "row"}>
                <Grid>
                    <Box mr={3}>
                        <ImageViewer 
                            imageType={type}
                            imagSrc={configProps?.value} // base64
                            error={configProps?.error} // boolean
                        />
                    </Box>
                </Grid>
                <Grid display="flex" flexDirection="column" justifyContent="center">
                    <Box my={2} {...getRootProps({ className: 'dropzone' })}>
                        <Button 
                            disabled={disabled}
                            variant="contained"
                            sx={{ 
                                borderRadius: 0,
                                textTransform:'none',
                                fontSize:16,
                                height:35,
                                width:mobile? '100%': 150,
                            }}
                        >
                            Choose Photo
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    );
}