import React from 'react'
import { useStyle } from "./styles";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "components/Form/Button";

export default function MessageDetail({ text, backButton }){
    const classes = useStyle();
    const navigate = useNavigate();

    return (
        <Box>
            <Box component="h3" textAlign="center">
                {text}
            </Box>
            {!!backButton && 
                <Box className={classes.doneBtn}>
                    <Button  
                        width={198}
                        variant="contained"
                        onClick={()=>navigate("/")}
                    >
                        Exit
                    </Button>
                </Box>
            }
        </Box>
    )
}
