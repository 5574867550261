import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles(()=>({
    photoStyle:{
        width: 'auto',
        height: 96,
        background: '#fcdc1338',
        justifyContent: 'center',
        flexDirection:'row',
        alignItems:'center',
        display:'flex',
    },
    photoContainer:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent: 'center'
    },
    errorStyle:{ 
        border:'3px solid #f44336'
    },
    avatar:{
        width:100,
        pixel:113,
    },
    prc:{
        width:185,
        pixel:113,
    }
}));