import { makeStyles } from '@mui/styles';
import { ValidationSchema } from './utils/validation';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AffiliatedRegionalChapter from 'components/Form/AffiliatedRegionalChapter';
import ImageUploader from 'components/Form/FileUploader/Image';
import FormPage from 'components/Form/FormikFormPage';
import CardStepLayout from "components/CardStepLayout";

const useStyles = makeStyles((theme)=>({
    textLink:{
        color:theme.palette.primary.main,
        fontWeight:700,
    }
}));

export default function AffiliatedRegionalChapterForm(props) {

    const { type = 0, stepNext, initialValues } = props;
    const classes = useStyles();

    const IS_RENEWAL = type === 1
    const SUBHEADER = type === 0 
    ? "Step 2 of 5" 
    : "Step 3 of 6";

    const HEADER = type === 0 
    ? "Affiliated Regional Chapter" 
    : "Membership Renewal";

    return (
        <FormPage 
            formValues={initialValues}
            validationSchema={ValidationSchema}
            step={stepNext} // next step 
            type={type}
        >
            <CardStepLayout
                subheader={SUBHEADER}
                header={HEADER}
                subtitleAlignment="justify"
                subtitle={
                    `Please select the region you would like to be part of based on the region of your workplace or home address.
                    The regional chapter officers may contact and inform you about the region's activities. 
                    They can also provide assistance to you on matters related to PAA.`
                }
            >
                <Grid container xs={12} sx={{ pt:3, pb:3, px:'10%'}}>
                    <Grid xs={12} md={6}>
                        <AffiliatedRegionalChapter disabled={IS_RENEWAL}/>
                    </Grid>
                    <Grid item container xs={7}>
                        <Grid item xs={12}>
                            <Box pt={5}>
                                <span>Upload a&nbsp;</span>
                                <span className={classes.textLink}>
                                    1 x 1photo with white background
                                </span>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box pt={5}>
                                <ImageUploader name="photo"/>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </CardStepLayout>
        </FormPage>
    );
}