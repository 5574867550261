import * as Yup from "yup";

export const ValidationSchema = Yup.object().shape({
    name:Yup.string().required('Name is required!'),

    name:Yup.string().when('is_check',{
        is:0,
        then:Yup.string().required('Name is required!'),
        otherwise:Yup.string()
    }),

    address:Yup.string().when('is_check',{
        is:0,
        then:Yup.string().required('Address is required!'),
        otherwise:Yup.string()
    }),

    region:Yup.string().when('is_check',{
        is:0,
        then:Yup.string().required('Region is required!'),
        otherwise:Yup.string()
    }),

    province:Yup.string().when('is_check',{
        is:0,
        then:Yup.string().required('Province is required!'),
        otherwise:Yup.string()
    }),

    municipality:Yup.string().when('is_check',{
        is:0,
        then:Yup.string().required('City / Municipality is required!'),
        otherwise:Yup.string()
    }),
    
    barangay:Yup.string().when('is_check',{
        is:0,
        then: Yup.string().required('Barangay is required!'),
        otherwise:Yup.string()
    }),

    zip:Yup.string().when('is_check',{
        is:0,
        then:  Yup.string().required('Zipcode is required!'),
        otherwise:Yup.string()
    }),

    mobile:Yup.string().when('is_check',{
        is:0,
        then: Yup.string()
        .matches(/^(09)\d{9}$/,'Mobile Number must be valid!')
        .required('Mobile Number is required!'),
        otherwise:Yup.string()
    }),
});