import React from "react";
import { useStyle } from "./style";
import clsx from 'clsx';
import Avatar from '../../assets/avatar.png';
import IDPhoto from '../../assets/IDPhoto.png';

export default function ImageUploader ({ imagSrc, imageType, error }){

    const classes = useStyle();

    const handleImagePlaceHolder = () => {
        let image = imageType === 'PRC' ? IDPhoto : Avatar; 

        if(imagSrc) image = imagSrc;
        
        return image
    }
    
    return <img 
        className={clsx({
            [classes.photoStyle]:true,
            [classes.errorStyle]:error,
            [classes.avatar]:imageType === 'AVATAR',
            [classes.prc]: imageType === 'PRC'
        })} 
        src={handleImagePlaceHolder()}
    />
}