import { useState, useEffect } from 'react';
import { FormHelperText } from '@mui/material';
import zxcvbn from 'zxcvbn';

export default function PassworMeter({ value }) {
  const [strengthObj, setPasswordStrength] = useState({
    stength: 'Very Weak',
    color: 'C92A2A',
  });

  /*
   * To set the the password strenght score
   */
  useEffect(() => {
    /*
     * To determine the score description
     * ...base on the password inputted by the user
     */
    const passWordStrengthCase = (score) => {
      let strength = {
        stength: 'Weak',
        color: '#C92A2A',
      };
      switch (score) {
        case 1:
          strength = {
            stength: 'Weak',
            color: '#C92A2A',
          };
          break;
        case 2:
          strength = {
            stength: 'Medium',
            color: '#FFCA00',
          };
          break;

        case 3:
          strength = {
            stength: 'Strong',
            color: '#16C949',
          };
          break;

        case 4:
          strength = {
            stength: 'Very Strong',
            color: '#16C949',
          };
          break;

        default:
          return strength;
      }
      return strength;
    };

    /*
     * To set the strength description
     */
    if (value !== null) {
      const { score } = zxcvbn(value || '');
      const _passwordStrength = passWordStrengthCase(score);
      setPasswordStrength(_passwordStrength);
    }
  }, [value]);

  if (value) {
    return (
      <FormHelperText id='component-error-text'>
        <span style={{ fontWeight: 'bold' }}>
          {`Password Strength: `}
          <span style={{ color: strengthObj.color }}>{strengthObj.stength}</span>
        </span>
      </FormHelperText>
    );
  }

  return <></>;
}
