import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
	// PERSONAL DETAILS
	fname: Yup.string()
		.matches(
			/^(?=.{1,50}$)[a-zÀ-ž.]+(?:['-_\.\s][a-zÀ-ž]+)*$/i,
			'First Name must be minimum of 2 letters and contain only letters'
		)
		.nullable(),

	mname: Yup.string()
		.when('hasMiddleName', {
			is: true,
			then: Yup.string().nullable(),
			otherwise: Yup.string()
				.matches(/^[A-Za-zÀ-ž\s]*$/gi, 'Middle Initial must be valid')
				.nullable()
		})
		.nullable(),

	lname: Yup.string()
		.matches(
			/^(?=.{1,50}$)[a-zÀ-ž.]+(?:['-_\.\s][a-zÀ-ž]+)*$/i,
			'Last Name must be minimum of 2 letters and contain only letters'
		)
		.nullable(),

	suffix: Yup.string()
		.matches(/^[A-Za-z]{0,4}$/gi, 'Suffix must be valid!')
		.nullable(),

	age: Yup.string()
		.test('age', 'Please choose a valid birth date', (age) => age >= 18)
		.nullable(),

	gender: Yup.string().nullable(),

	civil: Yup.string().nullable(),

	nationality: Yup.string().nullable(),

	email: Yup.string()
		.matches(
			/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
			'Email Address must be valid!'
		)
		.nullable(),

	currentaddress: Yup.string().nullable(),

	curRegion: Yup.string().nullable(),

	curProvince: Yup.string().nullable(),

	curMunicipality: Yup.string().nullable(),

	curBarangay: Yup.string().nullable(),

	permanentaddress: Yup.string()
		.when('hasPermanentAddress', {
			is: true,
			then: Yup.string().required('Permanent Address is required!').nullable(),
			otherwise: Yup.string().nullable()
		})
		.nullable(),

	perRegion: Yup.string()
		.when('hasPermanentAddress', {
			is: true,
			then: Yup.string().required('Region is required!').nullable(),
			otherwise: Yup.string().nullable()
		})
		.nullable(),

	perProvince: Yup.string()
		.when('hasPermanentAddress', {
			is: true,
			then: Yup.string().required('Province is required!').nullable(),
			otherwise: Yup.string().nullable()
		})
		.nullable(),

	perMunicipality: Yup.string()
		.when('hasPermanentAddress', {
			is: true,
			then: Yup.string()
				.required('City / Municipality is required!')
				.nullable(),
			otherwise: Yup.string().nullable()
		})
		.nullable(),

	perBarangay: Yup.string()
		.when('hasPermanentAddress', {
			is: true,
			then: Yup.string().required('Barangay is required!').nullable(),
			otherwise: Yup.string().nullable()
		})
		.nullable(),

	mailing: Yup.string().nullable(),

	tel: Yup.string()
		.matches(/^\d{8,10}$/, 'Telephone Number must be valid!')
		.nullable(),

	mobile: Yup.string()
		.matches(/^(09)\d{9}$/, 'Mobile Number must be valid!')
		.nullable(),

	viber: Yup.string()
		.matches(/^(09)\d{9}$/, 'Viber Number must be valid!')
		.nullable(),

	// EMPLOYMENT DETAILS
	org: Yup.string()
		.when('employcheck', {
			is: 1,
			then: Yup.string().required('Organization/Institution is required!'),
			otherwise: Yup.string()
		})
		.nullable(),

	pos: Yup.string()
		.when('employcheck', {
			is: 1,
			then: Yup.string().required('Position / Designation is required!'),
			otherwise: Yup.string().nullable()
		})
		.nullable(),

	ofcaddress: Yup.string()
		.when('employcheck', {
			is: 1,
			then: Yup.string().required('Office Address is required!').nullable(),
			otherwise: Yup.string().nullable()
		})
		.nullable(),

	ofctelno: Yup.string()
		.when('employcheck', {
			is: 1,
			then: Yup.string()
				.matches(/^\d{8,10}$/, 'Telephone Number must be valid!')
				.nullable(),
			otherwise: Yup.string().nullable()
		})
		.nullable(),

	ofcmobile: Yup.string()
		.when('employcheck', {
			is: 1,
			then: Yup.string()
				.matches(/^(09)\d{9}$/, 'Mobile Number must be valid!')
				.required('Mobile Number is required!'),
			otherwise: Yup.string().nullable()
		})
		.nullable(),

	ofcemail: Yup.string()
		.when('employcheck', {
			is: 1,
			then: Yup.string()
				.required('Email Address is required')
				.matches(
					/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
					'Email Address must be valid!'
				)
				.nullable(),
			otherwise: Yup.string().nullable()
		})
		.nullable(),

	ofcfax: Yup.string()
		.when('employcheck', {
			is: 1,
			then: Yup.string()
				.matches(/^\d{8,11}/, 'Fax Number must be valid!')
				.nullable()
		})
		.nullable(),

	degree: Yup.string()
		.when('employcheck', {
			is: 1,
			then: Yup.string().required('Degree is required!').nullable(),
			otherwise: Yup.string().nullable()
		})
		.nullable(),

	fos: Yup.string()
		.when('employcheck', {
			is: 1,
			then: Yup.string()
				.required('Field of Specialization is required!')
				.nullable(),
			otherwise: Yup.string().nullable()
		})
		.nullable(),

	school: Yup.string()
		.when('employcheck', {
			is: 1,
			then: Yup.string()
				.required('School / College / University is required!')
				.nullable()
		})
		.nullable(),

	graduate: Yup.string()
		.when('employcheck', {
			is: 1,
			then: Yup.string().required('Year Graduated is required!').nullable()
		})
		.nullable()
});
