import * as Yup from "yup";

export const ValidationSchema = Yup.object().shape({
    email: Yup.string()
    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,'Email Address must be valid!')
    .required("Email is required!"),
    
    email2: Yup.string()
    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,'Re Enter Email Address must be valid!')
    .required("Re Enter Email is required!"),
});